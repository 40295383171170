import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { API_BASE_URL } from './components/config';
import { useLocation } from 'react-router-dom';
import './LockableContent.css';

function LockableContent({ children, initialLearningStatus, userToken, onUnlock }) {
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isContentUnlocked, setIsContentUnlocked] = useState(initialLearningStatus);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkoutRequestID, setCheckoutRequestID] = useState(null);
  const [phoneError, setPhoneError] = useState('');
  const location = useLocation();

  useEffect(() => {
    const unlocked = sessionStorage.getItem('contentUnlocked') === 'true';
    setIsContentUnlocked(unlocked || initialLearningStatus);
  }, [initialLearningStatus]);

  useEffect(() => {
    let intervalId;
    if (checkoutRequestID) {
      intervalId = setInterval(checkTransactionStatus, 5000); // Check every 5 seconds
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutRequestID]);

  const checkTransactionStatus = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/training/transaction-status/${checkoutRequestID}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response.data.status === 'completed') {
        setPaymentStatus('Payment successful! Unlocking content...');
        setIsContentUnlocked(true);
        sessionStorage.setItem('contentUnlocked', 'true');
        if (onUnlock) onUnlock();
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      } else if (response.data.status === 'timeout' || response.data.status === 'failed') {
        setPaymentStatus('Payment failed or timed out. Please try again.');
      }
    } catch (error) {
      console.error('Error checking transaction status:', error);
      setPaymentStatus('Error checking payment status. Please try again.');
    }
  };

  const handleUnlockClick = () => {
    setShowModal(true);
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    // Allow only digits and limit to 10 characters
    const sanitizedInput = input.replace(/\D/g, '').slice(0, 10);
    setPhoneNumber(sanitizedInput);
    setPhoneError('');
  };

  const validateAndFormatPhoneNumber = (number) => {
    if (number.length !== 10) {
      setPhoneError('Phone number must be 10 digits long');
      return null;
    }

    if (!['07', '01'].includes(number.slice(0, 2))) {
      setPhoneError('Phone number must start with 07 or 01');
      return null;
    }

    // Format the number for backend
    return `254${number.slice(1)}`;
  };

  const getLearningPath = () => {
    if (location.pathname.startsWith('/learning')) {
      return 'ACADEMIC WRITING';
    } else if (location.pathname.startsWith('/java-learning')) {
      return 'JAVA PROGRAMMING';
    } else if (location.pathname.startsWith('/blogging')) {
      return 'BLOGGING';
    }
    return null;
  };

  const handlePhoneNumberSubmit = async () => {
    const formattedNumber = validateAndFormatPhoneNumber(phoneNumber);
    if (!formattedNumber) {
      return; // Don't proceed if validation fails
    }

    const learningPath = getLearningPath();

    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/training/pay`, {
        phoneNumber: formattedNumber,
        // learningPath: learningPath,
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response.status === 200) {
        setCheckoutRequestID(response.data.checkoutRequestID);
        setPaymentStatus(`Payment initiated for ${learningPath}. Please complete the payment on your phone.`);
      } else {
        setPaymentStatus('Payment initiation failed. Please try again.');
      }
    } catch (error) {
      console.error('Payment failed:', error);
      setPaymentStatus('Payment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPaymentStatus(null);
    setPhoneNumber('');
    setPhoneError('');
    setLoading(false);
    setCheckoutRequestID(null);
  };

  return (
    <div className="lockable-content-container">
      {!isContentUnlocked && (
        <div className="locked-overlay">
          <div className="blurred-content">
            {children}
          </div>
          <div className="unlock-button-container">
            <Button className="unlock-button" onClick={handleUnlockClick}>
              Unlock Content
            </Button>
          </div>
        </div>
      )}

      {isContentUnlocked && (
        <div className="unlocked-content">
          {children}
        </div>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Unlock Content for {getLearningPath()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentStatus && <Alert variant="info">{paymentStatus}</Alert>}
          {!paymentStatus && (
            <Form>
              <Form.Group controlId="formPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number (e.g., 0712345689)"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  disabled={loading}
                />
                {phoneError && <Form.Text className="text-danger">{phoneError}</Form.Text>}
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} disabled={loading}>
            Close
          </Button>
          {!paymentStatus && (
            <Button variant="primary" onClick={handlePhoneNumberSubmit} disabled={loading || !!phoneError}>
              {loading ? 'Processing...' : 'Submit'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LockableContent;