// src/pages/UnitTestingTDD.js
import React from 'react';
import CodeBlock from '../components/CodeBlock';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';


const UnitTestingTDD = () => {
    const calculatorTestCode = `
        import org.junit.jupiter.api.BeforeEach;
        import org.junit.jupiter.api.Test;
        
        import static org.junit.jupiter.api.Assertions.assertEquals;
        
        public class CalculatorTest {
        
            private Calculator calculator;
        
            @BeforeEach
            void setUp() {
                calculator = new Calculator();
            }
        
            @Test
            void testAddition() {
                int result = calculator.add(2, 3);
                assertEquals(5, result);
            }
        
            @Test
            void testSubtraction() {
                int result = calculator.subtract(5, 3);
                assertEquals(2, result);
            }
        }
    `;

    const calculatorCode = `
        public class Calculator {

            public int add(int a, int b) {
                return a + b;
            }

            public int subtract(int a, int b) {
                return a - b;
            }

            public int multiply(int a, int b) {
                return a * b;
            }

            public int divide(int a, int b) {
                if (b == 0) {
                    throw new ArithmeticException("Cannot divide by zero");
                }
                return a / b;
            }
        }
    `;

    const serviceTestCode = `
        import org.junit.jupiter.api.BeforeEach;
        import org.junit.jupiter.api.Test;
        import org.mockito.Mockito;

        import static org.junit.jupiter.api.Assertions.assertEquals;
        import static org.mockito.Mockito.when;

        public class ServiceTest {

            private Service service;
            private Dependency dependencyMock;

            @BeforeEach
            void setUp() {
                dependencyMock = Mockito.mock(Dependency.class);
                service = new Service(dependencyMock);
            }

            @Test
            void testServiceMethod() {
                // Arrange
                when(dependencyMock.getData()).thenReturn("Mocked Data");

                // Act
                String result = service.processData();

                // Assert
                assertEquals("Processed: Mocked Data", result);
            }
        }
    `;

    const tddExampleCode = `
        @Test
        void testIsEven() {
            // Arrange
            int number = 4;

            // Act
            boolean result = calculator.isEven(number);

            // Assert
            assertTrue(result);
        }

        public boolean isEven(int number) {
            return number % 2 == 0;
        }
    `;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Unit Testing and Test-Driven Development (TDD) in Java: A Comprehensive Guide</h1>
                <p>
                    Unit testing is a fundamental practice in software development that ensures individual components or "units" of an application work as intended. Test-Driven Development (TDD) takes this concept a step further by promoting the writing of tests before the actual implementation code. Java provides robust support for unit testing through frameworks like JUnit, which is widely used by developers to write, organize, and execute tests. This guide will cover the basics of JUnit, how to write unit tests for Java code, and best practices for TDD.
                </p>

                <hr className="section-divider" />

                <h2>1. JUnit Basics</h2>

                <h3>1.1. Overview</h3>
                <p>
                    JUnit is a popular open-source framework used for writing and running tests in Java. It is part of the larger family of xUnit frameworks and is widely adopted due to its simplicity and flexibility.
                </p>

                <ul>
                    <li><strong>Annotations:</strong> JUnit provides a set of annotations that help define and organize test methods.</li>
                    <li><strong>Assertions:</strong> JUnit offers various assertions to verify expected outcomes.</li>
                    <li><strong>Test Runners:</strong> JUnit allows tests to be run individually or as part of a test suite.</li>
                </ul>

                <h3>1.2. Annotations in JUnit</h3>
                <p>JUnit uses annotations to mark methods as test methods and to configure the test lifecycle.</p>

                <ul>
                    <li><code>@Test</code>: Marks a method as a test method.</li>
                    <li><code>@BeforeEach</code>: Executes a method before each test (used for setup).</li>
                    <li><code>@AfterEach</code>: Executes a method after each test (used for cleanup).</li>
                    <li><code>@BeforeAll</code>: Executes a method once before all tests in the class (used for one-time setup).</li>
                    <li><code>@AfterAll</code>: Executes a method once after all tests in the class (used for one-time cleanup).</li>
                </ul>

                <CodeBlock code={calculatorTestCode} />

                <h4>Explanation:</h4>
                <p><code>@BeforeEach</code>: The setUp() method is executed before each test method to initialize the Calculator object.</p>
                <p><code>@Test</code>: The testAddition() and testSubtraction() methods are marked as test methods. These methods use assertions to verify that the Calculator class functions correctly.</p>

                <h3>1.3. Assertions in JUnit</h3>
                <p>Assertions are used to check the expected results of test cases. If an assertion fails, the test is marked as failed.</p>

                <ul>
                    <li><code>assertEquals(expected, actual)</code>: Checks that two values are equal.</li>
                    <li><code>assertTrue(condition)</code>: Checks that a condition is true.</li>
                    <li><code>assertFalse(condition)</code>: Checks that a condition is false.</li>
                    <li><code>assertNull(object)</code>: Checks that an object is null.</li>
                    <li><code>assertNotNull(object)</code>: Checks that an object is not null.</li>
                    <li><code>assertThrows(expectedType, executable)</code>: Checks that a specific exception is thrown.</li>
                </ul>

                <h3>1.4. Running Tests</h3>
                <p>JUnit tests can be run from an IDE (e.g., IntelliJ IDEA, Eclipse) or from the command line using build tools like Maven or Gradle.</p>

                <ul>
                    <li><strong>Running Tests in IntelliJ IDEA:</strong> Right-click on the test class or method and select "Run 'CalculatorTest'". The test results will be displayed in the run window, showing which tests passed or failed.</li>
                </ul>

                <hr className="section-divider" />

                <h2>2. Writing Unit Tests for Java Code</h2>

                <h3>2.1. Overview</h3>
                <p>Unit tests are small, isolated tests that verify the behavior of a specific method or class. The goal of unit testing is to ensure that each part of the software works as expected.</p>

                <h3>2.2. Writing Effective Unit Tests</h3>
                <p><strong>Isolate the Unit of Work:</strong> Each test should focus on a single method or function. Dependencies should be mocked or stubbed to isolate the unit being tested.</p>
                <p><strong>Use Descriptive Test Names:</strong> Test method names should clearly describe the behavior being tested. This makes it easier to understand the purpose of the test at a glance.</p>
                <p><strong>Follow the AAA Pattern:</strong> The Arrange-Act-Assert pattern is a common structure for writing unit tests:</p>

                <ul>
                    <li><strong>Arrange:</strong> Set up the conditions for the test.</li>
                    <li><strong>Act:</strong> Execute the method under test.</li>
                    <li><strong>Assert:</strong> Verify that the outcome is as expected.</li>
                </ul>

                <CodeBlock code={calculatorCode} />

                <CodeBlock code={calculatorTestCode} />

                <h4>Explanation:</h4>
                <p><strong>Test Structure:</strong> Each test follows the Arrange-Act-Assert pattern, ensuring that tests are clear and focused.</p>
                <p><strong>Coverage:</strong> Tests cover both normal and edge cases (e.g., division by zero).</p>

                <h3>2.3. Mocking Dependencies</h3>
                <p>In cases where the class under test has dependencies (e.g., interacting with a database or an external service), mocking frameworks like Mockito can be used to simulate these dependencies.</p>

                <CodeBlock code={serviceTestCode} />

                <h4>Explanation:</h4>
                <p><strong>Mocking:</strong> The Dependency class is mocked using Mockito, allowing the Service class to be tested in isolation.</p>
                <p><strong>Stubbing:</strong> The behavior of the mock is defined using when().thenReturn(), controlling the output of the dependency.</p>

                <h3>2.4. Real-World Application</h3>
                <p>Unit tests are essential in any software project, ensuring that individual components work as intended. They are particularly valuable in large, complex systems where changes to one part of the codebase can have unintended consequences in other parts. Regularly running unit tests helps catch bugs early in the development process, improving software quality and reducing the cost of fixing defects.</p>

                <hr className="section-divider" />

                <h2>3. Test-Driven Development (TDD) Practices</h2>

                <h3>3.1. Overview</h3>
                <p>Test-Driven Development (TDD) is a software development process where developers write tests before writing the actual implementation code. This approach ensures that code is written with a clear understanding of the requirements and is designed to be testable from the outset.</p>

                <p><strong>TDD Cycle:</strong></p>

                <ul>
                    <li><strong>Red:</strong> Write a failing test based on the requirements.</li>
                    <li><strong>Green:</strong> Write the minimum amount of code necessary to make the test pass.</li>
                    <li><strong>Refactor:</strong> Improve the code by removing duplication, improving readability, or optimizing performance while ensuring the tests still pass.</li>
                </ul>

                <h3>3.2. Benefits of TDD</h3>
                <ul>
                    <li><strong>Improved Code Quality:</strong> Writing tests first ensures that the code is designed with testing in mind, leading to better-structured, more maintainable code.</li>
                    <li><strong>Faster Debugging:</strong> Since tests are written before the code, any issues are detected immediately, making debugging easier.</li>
                    <li><strong>Reduced Fear of Change:</strong> With a comprehensive suite of tests, developers can refactor or extend code with confidence, knowing that the tests will catch any regressions.</li>
                </ul>

                <h3>3.3. Implementing TDD</h3>

                <p><strong>Step 1: Write a Failing Test (Red):</strong></p>

                <CodeBlock code={tddExampleCode} />

                <p><strong>Explanation:</strong> Initially, the isEven() method doesn't exist, so this test will fail.</p>

                <p><strong>Step 2: Write Just Enough Code to Pass the Test (Green):</strong></p>

                <CodeBlock code={tddExampleCode} />

                <p><strong>Explanation:</strong> The isEven() method is implemented to make the test pass.</p>

                <p><strong>Step 3: Refactor the Code (Refactor):</strong></p>

                <CodeBlock code={tddExampleCode} />

                <p><strong>Explanation:</strong> In more complex cases, this step might involve improving the implementation without changing its behavior.</p>

                <h3>3.4. Real-World Application</h3>
                <p>TDD is especially beneficial in complex, large-scale projects where ensuring that new features or changes do not introduce regressions is critical. It is commonly used in agile development environments, where the codebase is continually evolving and where the ability to rapidly and confidently iterate on the software is essential.</p>

                <hr className="section-divider" />

                <h2>Conclusion</h2>
                <p>
                    Unit testing and Test-Driven Development (TDD) are essential practices for building high-quality, reliable software in Java. By mastering JUnit, you can write effective unit tests that ensure your code behaves as expected. Adopting TDD helps you to write code that is well-designed, thoroughly tested, and easy to maintain.
                </p>

                <ul>
                    <li><strong>JUnit Basics:</strong> Understanding annotations, assertions, and how to run tests is fundamental for writing unit tests in Java.</li>
                    <li><strong>Writing Unit Tests:</strong> Effective unit tests isolate the unit of work, use descriptive test names, and follow the Arrange-Act-Assert pattern. Mocking is essential for isolating dependencies.</li>
                    <li><strong>Test-Driven Development (TDD):</strong> TDD emphasizes writing tests before code, leading to better design, faster debugging, and more maintainable code.</li>
                </ul>

                <p>
                    By incorporating these practices into your development process, you can ensure that your Java applications are robust, reliable, and ready to meet the demands of modern software development. Whether you’re working on small projects or large, complex systems, unit testing and TDD will help you deliver high-quality code with confidence.
                </p>
                <NextButton nextPage="/building-java-applications" />
            </div>
        </div>
    );
};

export default UnitTestingTDD;
