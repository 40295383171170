// src/pages/AdvancedOOP.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';

const AdvancedOOP = () => {
    const inheritanceCode = `
class Vehicle {
    String brand = "Ford";

    public void honk() {
        System.out.println("Beep! Beep!");
    }
}

class Car extends Vehicle {
    String model = "Mustang";

    public void displayDetails() {
        System.out.println("Brand: " + brand); // Inherited field
        System.out.println("Model: " + model);
    }
}

public class InheritanceExample {
    public static void main(String[] args) {
        Car car = new Car();
        car.displayDetails(); // Accessing inherited method
        car.honk(); // Accessing method from the parent class
    }
}
`;

    const polymorphismCode = `
class Animal {
    public void sound() {
        System.out.println("The animal makes a sound");
    }
}

class Dog extends Animal {
    @Override
    public void sound() {
        System.out.println("The dog barks");
    }
}

class Cat extends Animal {
    @Override
    public void sound() {
        System.out.println("The cat meows");
    }
}

public class PolymorphismExample {
    public static void main(String[] args) {
        Animal myAnimal = new Animal(); // Parent class reference
        Animal myDog = new Dog(); // Polymorphic behavior
        Animal myCat = new Cat(); // Polymorphic behavior

        myAnimal.sound(); // Output: The animal makes a sound
        myDog.sound(); // Output: The dog barks
        myCat.sound(); // Output: The cat meows
    }
}
`;

    const abstractClassCode = `
abstract class Animal {
    abstract void sound(); // Abstract method

    public void eat() {
        System.out.println("This animal eats food");
    }
}

class Dog extends Animal {
    @Override
    public void sound() {
        System.out.println("The dog barks");
    }
}

public class AbstractClassExample {
    public static void main(String[] args) {
        Dog dog = new Dog();
        dog.sound(); // Output: The dog barks
        dog.eat(); // Output: This animal eats food
    }
}
`;

    const interfaceCode = `
interface Animal {
    void sound(); // Abstract method

    default void sleep() {
        System.out.println("This animal sleeps");
    }
}

class Dog implements Animal {
    @Override
    public void sound() {
        System.out.println("The dog barks");
    }
}

public class InterfaceExample {
    public static void main(String[] args) {
        Dog dog = new Dog();
        dog.sound(); // Output: The dog barks
        dog.sleep(); // Output: This animal sleeps
    }
}
`;

    const overloadingCode = `
class Calculator {
    // Method to add two integers
    int add(int a, int b) {
        return a + b;
    }

    // Overloaded method to add three integers
    int add(int a, int b, int c) {
        return a + b + c;
    }

    // Overloaded method to add two doubles
    double add(double a, double b) {
        return a + b;
    }
}

public class MethodOverloadingExample {
    public static void main(String[] args) {
        Calculator calc = new Calculator();

        System.out.println("Sum of 2 and 3: " + calc.add(2, 3)); // Output: 5
        System.out.println("Sum of 2, 3, and 4: " + calc.add(2, 3, 4)); // Output: 9
        System.out.println("Sum of 2.5 and 3.5: " + calc.add(2.5, 3.5)); // Output: 6.0
    }
}
`;

    const overridingCode = `
class Animal {
    public void sound() {
        System.out.println("The animal makes a sound");
    }
}

class Dog extends Animal {
    @Override
    public void sound() {
        System.out.println("The dog barks");
    }
}

public class MethodOverridingExample {
    public static void main(String[] args) {
        Animal myAnimal = new Animal();
        Animal myDog = new Dog();

        myAnimal.sound(); // Output: The animal makes a sound
        myDog.sound(); // Output: The dog barks
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Advanced OOP Concepts in Java: A Comprehensive Guide</h1>
                <p>
                    Object-Oriented Programming (OOP) is a paradigm that provides a structured approach to software development, emphasizing the use of objects and classes. As you delve deeper into OOP, advanced concepts such as inheritance, polymorphism, abstract classes, interfaces, method overloading, and method overriding become essential. Understanding these concepts allows you to write more modular, reusable, and flexible code. This guide will explore these advanced OOP concepts in detail, providing explanations, code examples, and real-world applications.
                </p>

                <hr className="section-divider" />

                <h2>1. Inheritance and Polymorphism</h2>

                <h3>Inheritance</h3>
                <p>
                    Inheritance is a mechanism that allows one class (subclass or child class) to inherit fields and methods from another class (superclass or parent class). This promotes code reuse and establishes a natural hierarchy between classes.
                </p>

                <h4>Syntax:</h4>
                <CodeBlock code={`class ParentClass {\n// Fields and methods of the parent class\n}\n\nclass ChildClass extends ParentClass {\n// Additional fields and methods of the child class\n}`} />

                <h3>Example Code:</h3>
                <CodeBlock code={inheritanceCode} />

                <h4>Output:</h4>
                <pre>
                    Brand: Ford{'\n'}
                    Model: Mustang{'\n'}
                    Beep! Beep!
                </pre>

                <p><strong>Explanation:</strong> The <em>Car</em> class inherits the <em>brand</em> field and <em>honk()</em> method from the <em>Vehicle</em> class. The Car class can use these inherited members as if they were its own.</p>

                <h3>Polymorphism</h3>
                <p>
                    Polymorphism allows objects of different classes to be treated as objects of a common superclass. The most common use of polymorphism in OOP is when a parent class reference is used to refer to a child class object.
                </p>

                <h4>Types of Polymorphism:</h4>
                <ul>
                    <li><strong>Compile-time Polymorphism (Method Overloading):</strong> This occurs when multiple methods in the same class have the same name but different parameters.</li>
                    <li><strong>Run-time Polymorphism (Method Overriding):</strong> This occurs when a subclass provides a specific implementation of a method that is already defined in its superclass.</li>
                </ul>

                <h3>Example Code (Run-time Polymorphism):</h3>
                <CodeBlock code={polymorphismCode} />

                <h4>Output:</h4>
                <pre>
                    The animal makes a sound{'\n'}
                    The dog barks{'\n'}
                    The cat meows
                </pre>

                <p><strong>Explanation:</strong> The <em>sound()</em> method behaves differently based on the object it is calling. This allows flexibility in the code as the same method name can exhibit different behaviors.</p>

                <p><strong>Real-World Application:</strong> Inheritance and polymorphism are used in many applications, such as graphic design tools. For example, a base class <em>Shape</em> could have derived classes like <em>Circle</em>, <em>Rectangle</em>, and <em>Triangle</em>. Each shape class could override a method like <em>draw()</em> to render itself on the screen. The application could use polymorphism to treat all shapes uniformly, while still calling the specific drawing methods of each shape.</p>

                <hr className="section-divider" />

                <h2>2. Abstract Classes and Interfaces</h2>

                <h3>Abstract Classes</h3>
                <p>
                    An abstract class is a class that cannot be instantiated on its own and may contain abstract methods (methods without a body). It serves as a blueprint for other classes.
                </p>

                <h4>When to Use Abstract Classes:</h4>
                <ul>
                    <li>When you want to provide a common base with some shared code and enforce the implementation of certain methods in derived classes.</li>
                </ul>

                <h4>Syntax:</h4>
                <CodeBlock code={`abstract class ParentClass {\n\nabstract void abstractMethod(); // Abstract method\n\nvoid regularMethod() {\n// Implementation\n}\n}`} />

                <h3>Example Code:</h3>
                <CodeBlock code={abstractClassCode} />

                <h4>Output:</h4>
                <pre>
                    The dog barks{'\n'}
                    This animal eats food
                </pre>

                <p><strong>Explanation:</strong> The <em>sound()</em> method is abstract in the <em>Animal</em> class, meaning it must be implemented in the subclass <em>Dog</em>.</p>

                <h3>Interfaces</h3>
                <p>
                    An interface in Java is a reference type, similar to a class, that can contain only constants, method signatures, default methods, static methods, and nested types. Interfaces provide a way to achieve full abstraction in Java.
                </p>

                <h4>When to Use Interfaces:</h4>
                <ul>
                    <li>When you want to define a contract that multiple classes can implement, ensuring that they follow a certain set of behaviors.</li>
                </ul>

                <h4>Syntax:</h4>
                <CodeBlock code={`interface MyInterface {\nvoid method1(); // Abstract method\n\ndefault void method2() {\n// Default method implementation\n}\n}`} />

                <h3>Example Code:</h3>
                <CodeBlock code={interfaceCode} />

                <h4>Output:</h4>
                <pre>
                    The dog barks{'\n'}
                    This animal sleeps
                </pre>

                <p><strong>Explanation:</strong> The <em>Dog</em> class implements the <em>Animal</em> interface, providing the implementation for the <em>sound()</em> method.</p>

                <h4>Difference Between Abstract Classes and Interfaces:</h4>
                <ul>
                    <li><strong>Abstract Class:</strong>
                        <ul>
                            <li>Can have both abstract and non-abstract methods.</li>
                            <li>Can have constructors, fields, and methods with implementations.</li>
                            <li>A class can extend only one abstract class.</li>
                        </ul>
                    </li>
                    <li><strong>Interface:</strong>
                        <ul>
                            <li>Only abstract methods (until Java 8, when default and static methods were introduced).</li>
                            <li>Cannot have constructors or fields (except static final constants).</li>
                            <li>A class can implement multiple interfaces.</li>
                        </ul>
                    </li>
                </ul>

                <p><strong>Real-World Application:</strong> Abstract classes and interfaces are widely used in frameworks like Spring, where interfaces define the behavior of various components (e.g., <em>List</em>, <em>Map</em> in the Java Collections Framework), and abstract classes provide partial implementations of these behaviors. This allows developers to build upon existing structures while ensuring consistency across implementations.</p>

                <hr className="section-divider" />

                <h2>3. Method Overloading and Overriding</h2>

                <h3>Method Overloading</h3>
                <p>
                    Method overloading is a feature that allows a class to have more than one method with the same name, provided their parameter lists are different (either in the number of parameters or their types).
                </p>

                <h4>Syntax:</h4>
                <CodeBlock code={`class MyClass {\n\nvoid method(int a) {\n// Method with one int parameter\n}\n\nvoid method(int a, int b) {\n// Overloaded method with two int parameters\n}\n\nvoid method(double a) {\n// Overloaded method with one double parameter\n}\n}`} />

                <h3>Example Code:</h3>
                <CodeBlock code={overloadingCode} />

                <h4>Output:</h4>
                <pre>
                    Sum of 2 and 3: 5{'\n'}
                    Sum of 2, 3, and 4: 9{'\n'}
                    Sum of 2.5 and 3.5: 6.0
                </pre>

                <p><strong>Explanation:</strong> The <em>add</em> method is overloaded to handle different types and numbers of arguments.</p>

                <h3>Method Overriding</h3>
                <p>
                    Method overriding occurs when a subclass provides a specific implementation of a method that is already defined in its superclass. This is used to achieve runtime polymorphism.
                </p>

                <h4>Syntax:</h4>
                <CodeBlock code={`class ParentClass {\n\nvoid display() {\nSystem.out.println("Display method in ParentClass");\n}\n}\n\nclass ChildClass extends ParentClass {\n@Override\nvoid display() {\nSystem.out.println("Display method in ChildClass");\n}\n}`} />

                <h3>Example Code:</h3>
                <CodeBlock code={overridingCode} />

                <h4>Output:</h4>
                <pre>
                    The animal makes a sound{'\n'}
                    The dog barks
                </pre>

                <p><strong>Explanation:</strong> The <em>sound()</em> method is overridden in the <em>Dog</em> class to provide a specific implementation for dogs.</p>

                <p><strong>Real-World Application:</strong> Method overloading and overriding are fundamental in creating flexible and scalable software systems. In a payment processing system, for example, a base class <em>Payment</em> might have an <em>authorize()</em> method. Subclasses such as <em>CreditCardPayment</em>, <em>PayPalPayment</em>, and <em>BankTransferPayment</em> can override the <em>authorize()</em> method to implement payment-specific authorization logic.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Advanced OOP concepts such as inheritance, polymorphism, abstract classes, interfaces, method overloading, and method overriding are essential tools in a Java developer's toolkit. These concepts allow for the creation of flexible, reusable, and maintainable code. Inheritance and polymorphism enable code reuse and dynamic behavior, while abstract classes and interfaces provide a blueprint for building consistent and extendable systems. Method overloading and overriding offer flexibility in how methods are used and redefined.</p>

                <p>By mastering these advanced OOP concepts, you'll be well-equipped to design and implement complex software systems that are both efficient and scalable. These principles are fundamental to developing high-quality, object-oriented applications in Java, whether you’re building small utility programs or large-scale enterprise systems.






                </p>

                <NextButton nextPage="/advanced-data-structures" />
            </div>
        </div>
    );
};

export default AdvancedOOP;
