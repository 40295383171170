import React, { useEffect } from 'react';
import './ChicagoStyle.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function ChicagoStyle() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/harvard-style');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="chicago-paper-example">
            <header className="header">
                <h1><span className="v">Mastering the Chicago Manual of Style: A Comprehensive Guide to Academic Writing</span></h1>
                <p className="author"><span className="v">Jane Smith</span></p>
                <p className="affiliation"><span className="v">Department of History, University of Learning</span></p>
                <p className="course"><span className="v">HIS 301: Advanced Historical Research</span></p>
                <p className="instructor"><span className="v">Prof. John Doe</span></p>
                <p className="date"><span className="v">5 April 2024</span></p>
            </header>

            <div className="cover-page-explanation">
                <p>Explanation: The title is centered and capitalized. The author's name, course details, instructor's name, and date are centered and double-spaced below the title.</p>
            </div>

            <section className="body-text">
                <h2><span className="v">Introduction to Chicago Style</span></h2>
                <p><span className="v">The Chicago Manual of Style is a widely used format for writing and citation in the humanities.</span> Understanding this style is essential for maintaining academic integrity and ensuring your work meets the highest standards of scholarly communication. This essay will cover the key components of Chicago style, including formatting, in-text citations, and reference lists.</p>
                <p className="explanation">Explanation: The introduction provides an overview of the Chicago Manual of Style and its importance in academic writing.</p>

                <h2><span className="v">General Formatting Guidelines</span></h2>
                <ul>
                    <li>Use 1-inch margins on all sides.</li>
                    <li>Choose a readable font, like 12-point Times New Roman.</li>
                    <li>Double-space the entire document, including block quotes and footnotes.</li>
                    <li>Include a title page with the title of your paper, your name, course information, and date.</li>
                </ul>

                <h2><span className="v">In-Text Citations: Footnotes and Endnotes</span></h2>
                <p><span className="v">Chicago style primarily uses footnotes or endnotes for in-text citations.</span> When citing a source, place a superscript number at the end of the sentence that refers to a note at the bottom of the page (footnote) or the end of the paper (endnote). For example: "Social media has a profound impact on modern communication."<sup>1</sup></p>
                <p className="explanation">Explanation: Footnotes or endnotes provide a way to reference sources without interrupting the flow of the text.</p>

                <h2><span className="v">Formatting Footnotes</span></h2>
                <p>Footnotes should be single-spaced with a blank line between each note. The first line of each note should be indented. For example:</p>
                <p className="footnote-example">1. John Doe, <em>The History of Social Media</em> (New York: Academic Press, 2020), 45.</p>
                <p className="explanation">Explanation: The footnote includes the author's name, the title of the work in italics, publication details, and the page number referenced.</p>

                <h2><span className="v">Bibliography</span></h2>
                <p><span className="v">A bibliography lists all sources cited in the paper, formatted in alphabetical order by the author's last name.</span> Each entry should include the author's name, title of the work, publication information, and the page numbers of the material cited. For example:</p>
                <p className="bibliography-example">Doe, John. <em>The History of Social Media</em>. New York: Academic Press, 2020.</p>
                <p className="explanation">Explanation: The bibliography provides a full citation for each source, making it easy for readers to locate the original materials.</p>

                <h2><span className="v">Citing Different Types of Sources</span></h2>
                <p>When citing books, include the author's name, title in italics, publisher, and year of publication. For example: <span className="v">Doe, John. <em>Understanding Historical Research</em>. Chicago: University of Chicago Press, 2019.</span></p>
                <p>For journal articles, include the author's name, article title in quotation marks, journal name in italics, volume and issue number, year of publication, and page range. Example: <span className="v">Smith, Jane. "Social Media and Historical Trends." <em>Journal of Modern History</em> 32, no. 2 (2020): 100-120.</span></p>
                <p>For websites, include the author's name (if available), the title of the webpage, website name, publication date, and the URL. Example: <span className="v">Brown, Lisa. "Chicago Style Citation Guide." Writing Resources, 2022. www.writingresources.com/chicago.</span></p>

                <h2><span className="v">Common Mistakes to Avoid</span></h2>
                <p>Some common mistakes in Chicago style include inconsistent formatting, missing information, and incorrect use of italics. Always double-check your footnotes, endnotes, and bibliography for accuracy and completeness to ensure that your work meets academic standards.</p>

                <h2><span className="v">Practical Examples and Resources</span></h2>
                <p>Reviewing practical examples of Chicago style citations can help solidify your understanding. Utilize resources such as the Chicago Manual of Style, online citation generators, and writing centers at your institution to ensure accuracy in your citations.</p>

                <h2><span className="v">Conclusion</span></h2>
                <p>Mastering the Chicago Manual of Style is essential for academic success in the humanities. By following the guidelines for formatting, citations, and bibliography, you can ensure that your work is both credible and professional.</p>
            </section>

            <footer className="references">
                <h3><span className="v">References</span></h3>
                <p className="explanation">Explanation: The references list or bibliography begins on a new page, with entries in alphabetical order by the author's last name.</p>

                <ul>
                    <li><span className="v">Brown, Lisa. "Chicago Style Citation Guide." Writing Resources, 2022. www.writingresources.com/chicago.</span></li>
                    <p className="explanation">Explanation: Online sources are cited with full details, including the access date if required.</p>

                    <li><span className="v">Doe, John. <em>The History of Social Media</em>. New York: Academic Press, 2020.</span></li>

                    <li><span className="v">Smith, Jane. "Social Media and Historical Trends." <em>Journal of Modern History</em> 32, no. 2 (2020): 100-120.</span></li>
                </ul>
            </footer>

            <div className="download-button-container">
                <h3><span className="highlig">Download this pdf to get a more detailed CHICAGO sample</span></h3>
                <div className="download-button-container">
                    <a href="/Chicago_Style.pdf" download className="dowutton">
                        <img src="/pdf.jpg" alt="Download PDF" width="60" />
                    </a>
                </div>
            </div>
            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default ChicagoStyle;
