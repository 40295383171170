import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = sessionStorage.getItem('adminToken');
        if (!token) {
          throw new Error('No admin token found');
        }

        const response = await fetch(`${API_BASE_URL}/api/admin/tasks`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setOrders(data);
      } catch (e) {
        console.error('Failed to fetch orders:', e);
        setError(e.message);
      }
    };

    fetchOrders();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const calculateTimeRemaining = (deadline) => {
    const now = new Date();
    const endTime = new Date(deadline);
    const timeDiff = endTime - now;

    if (timeDiff <= 0) {
      return 'Deadline passed';
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60)) / (1000 * 60));

    return `${days}d ${hours}h ${minutes}m`;
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="lg:w-64 bg-teal-500 text-gray-100 flex flex-col lg:fixed lg:left-0 lg:top-0 lg:h-screen">
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4">Writers Admin</h1>
          <nav>
            <Link to="/admin/dashboard" className="block py-2 hover:bg-gray-700">Dashboard</Link>
            <Link to="/admin/tasks" className="block py-2 hover:bg-gray-700">Manage Tasks</Link>
            <Link to="/admin/writers" className="block py-2 hover:bg-gray-700">My Writers</Link>
            <Link to="/admin/orders" className="block py-2 hover:bg-gray-700">Orders</Link>
            <Link to="/admin/bids" className="block py-2 hover:bg-gray-700">Bids</Link>
            <Link to="/admin/transactions" className="block py-2 hover:bg-gray-700">Transactions</Link>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col lg:ml-64 overflow-hidden">
        {/* Top Bar */}
        <header className="bg-teal-300 shadow-sm z-10">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h2 className="font-semibold text-xl text-gray-600">Orders</h2>
          </div>
        </header>

        {/* Orders Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-600">
          <div className="container mx-auto px-4 py-8">
            <div className="bg-white shadow-md rounded-lg">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Deadline</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {orders.map(order => (
                      <tr key={order.id}>
                        <td className="px-4 py-4 text-sm font-medium text-gray-900">{order.title}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{order.description}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">KSh {order.price}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{calculateTimeRemaining(order.deadline)}</td>
                        <td className={`px-4 py-4 text-sm ${getStatusColor(order.status)}`}>
                          {order.status}
                        </td>
                        <td className="px-4 py-4 text-sm">
                          <Link to={`/admin/orders/${order.id}`} className="text-blue-600 hover:text-blue-800">View</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'open':
      return 'text-green-600';
    case 'in_progress':
      return 'text-yellow-600';
    case 'completed':
      return 'text-blue-600';
    case 'disputed':
      return 'text-red-600';
    case 'cancelled':
      return 'text-gray-600';
    default:
      return '';
  }
};

export default AdminOrders;
