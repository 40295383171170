import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const TaskManagement = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ title: '', description: '', price: '', deadline: '', category: '' });
  const [editTask, setEditTask] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState('');
  const [documentFiles, setDocumentFiles] = useState(null);
  const [editDocumentFile, setEditDocumentFiles] = useState(null);

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line
  }, []);

  const fetchTasks = async () => {
    const token = sessionStorage.getItem('adminToken');
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/tasks`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (response.ok) {
        const data = await response.json();
        setTasks(data);
      } else {
        setMessage('Failed to fetch tasks');
      }
    } catch (error) {
      setMessage('An error occurred while fetching tasks');
    }
  };

  const handleAddTask = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem('adminToken');
    const formData = new FormData();
    formData.append('title', newTask.title);
    formData.append('description', newTask.description);
    formData.append('price', newTask.price);
    formData.append('deadline', newTask.deadline);
    formData.append('category', newTask.category);
    
    // Append multiple files
    if (documentFiles) {
      for (let i = 0; i < documentFiles.length; i++) {
        formData.append('documents', documentFiles[i]);
      }
    }
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/create-task`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });
      const result = await response.json();
      alert(result.message || 'Task created successfully');
      setNewTask({ title: '', description: '', price: '', deadline: '', category: '' });
      setDocumentFiles(null);
      fetchTasks();
    } catch (error) {
      alert('An error occurred while creating the task');
      console.error('Error creating task:', error);
    }
  };

  const handleEditTask = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem('adminToken');

    const formData = new FormData();
    formData.append('title', editTask.title);
    formData.append('description', editTask.description);
    formData.append('price', editTask.price);
    formData.append('deadline', editTask.deadline);
    formData.append('category', editTask.category);
    if (editDocumentFile) {
      formData.append('document', editDocumentFile);
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/edit-task/${editTask.id}`, {
        method: 'PUT',
        headers: { 
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });

      const result = await response.json();
      setMessage(result.message || 'Task updated successfully');
      setEditTask(null);
      setEditDocumentFiles(null);

      fetchTasks();
    } catch (error) {
      setMessage('An error occurred while editing the task');
    }
  };

  const handleDeleteTask = async (taskId) => {
    const token = sessionStorage.getItem('adminToken');
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/delete-task/${taskId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const result = await response.json();
      alert(result.message || 'Task deleted successfully');

      fetchTasks();
    } catch (error) {
      alert('An error occurred while deleting the task');
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="lg:w-64 bg-teal-500 text-gray-100 flex flex-col">
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4">Writers Admin</h1>
          <nav>
            <Link to="/admin/dashboard" className="block py-2 hover:bg-gray-700">Dashboard</Link>
            <Link to="/admin/tasks" className="block py-2 hover:bg-gray-700">Manage Tasks</Link>
            <Link to="/admin/writers" className="block py-2 hover:bg-gray-700">My Writers</Link>
            <Link to="/admin/orders" className="block py-2 hover:bg-gray-700">Orders</Link>
            <Link to="/admin/bids" className="block py-2 hover:bg-gray-700">Bids</Link>
            <Link to="/admin/transactions" className="block py-2 hover:bg-gray-700">Transactions</Link>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Bar */}
        <header className="bg-teal-300 shadow-sm z-10">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h2 className="font-semibold text-xl text-gray-600">Task Management</h2>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-1 overflow-x-auto bg-gray-600">
          <div className="container mx-auto px-4 py-8">
            <div className="bg-white p-6 rounded-lg shadow-md mb-8">
              <h2 className="text-2xl font-bold mb-6 text-gray-800">Create New Task</h2>

              {/* Task Creation Form */}
              <form onSubmit={handleAddTask} className="space-y-4" encType="multipart/form-data">
                <input
                  type="text"
                  placeholder="Task Title"
                  value={newTask.title}
                  onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                  className="w-full p-3 border rounded bg-gray-100 text-black"
                  required
                />
                <textarea
                  placeholder="Task Description"
                  value={newTask.description}
                  onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                  className="w-full p-3 border rounded bg-gray-100 text-black"
                  required
                />
                <input
                  type="number"
                  placeholder="Price"
                  value={newTask.price}
                  onChange={(e) => setNewTask({ ...newTask, price: e.target.value })}
                  className="w-full p-3 border rounded bg-gray-100 text-black"
                  required
                />
                <input
                  type="datetime-local"
                  placeholder="Deadline"
                  value={newTask.deadline}
                  onChange={(e) => setNewTask({ ...newTask, deadline: e.target.value })}
                  className="w-full p-3 border rounded bg-gray-100 text-black"
                  required
                />
                <input
                  type="text"
                  placeholder="Category"
                  value={newTask.category}
                  onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
                  className="w-full p-3 border rounded bg-gray-100 text-black"
                  required
                />
                <input
                  type="file"
                  multiple
                  onChange={(e) => setDocumentFiles(e.target.files)}
                  className="w-full p-3 border rounded bg-gray-100 text-black"
                />
                                <button
                  type="submit"
                  className="w-full bg-teal-500 text-gray-100 font-bold py-3 px-6 rounded-full hover:bg-teal-600 transition duration-300"
                >
                  Add Task
                </button>
              </form>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-6 text-gray-800">Tasks List</h2>

              {/* Task List */}
              <ul className="space-y-4">
                {tasks.map(task => (
                  <li key={task.id} className="bg-gray-100 p-4 rounded-lg shadow">
                    <h3 className="text-xl font-semibold text-gray-800">{task.title}</h3>
                    <p className="text-gray-600">{task.description}</p>
                    <p className="text-gray-600">Price: Ksh {task.price}</p>
                    <p className="text-gray-600">Deadline: {new Date(task.deadline).toLocaleString()}</p>
                    <p className="text-gray-600">Category: {task.category}</p>
                    <p className="text-gray-600">Status: {task.status}</p>
                    <p className="text-gray-600">Revisions: {task.revisions.length}</p>

                    <button
                      onClick={() => setEditTask(task)}
                      className="bg-teal-500 text-gray-100 px-4 py-2 rounded hover:bg-teal-600 transition duration-300 mt-2"
                    >
                      Edit Task
                    </button>
                    <button
                      onClick={() => handleDeleteTask(task.id)}
                      className="bg-red-500 text-gray-100 px-4 py-2 rounded hover:bg-red-600 transition duration-300 ml-2 mt-2"
                    >
                      Delete Task
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Edit Task Form */}
            {editTask && (
              <div className="bg-white p-6 rounded-lg shadow-md mt-8">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">Edit Task</h2>

                <form onSubmit={handleEditTask} className="space-y-4" encType="multipart/form-data">
                  <input
                    type="text"
                    placeholder="Task Title"
                    value={editTask.title}
                    onChange={(e) => setEditTask({ ...editTask, title: e.target.value })}
                    className="w-full p-3 border rounded bg-gray-100"
                    required
                  />
                  <textarea
                    placeholder="Task Description"
                    value={editTask.description}
                    onChange={(e) => setEditTask({ ...editTask, description: e.target.value })}
                    className="w-full p-3 border rounded bg-gray-100"
                    required
                  />
                  <input
                    type="number"
                    placeholder="Price"
                    value={editTask.price}
                    onChange={(e) => setEditTask({ ...editTask, price: e.target.value })}
                    className="w-full p-3 border rounded bg-gray-100"
                    required
                  />
                  <input
                    type="datetime-local"
                    placeholder="Deadline"
                    value={new Date(editTask.deadline).toISOString().substring(0, 16)}
                    onChange={(e) => setEditTask({ ...editTask, deadline: e.target.value })}
                    className="w-full p-3 border rounded bg-gray-100"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Category"
                    value={editTask.category}
                    onChange={(e) => setEditTask({ ...editTask, category: e.target.value })}
                    className="w-full p-3 border rounded bg-gray-100"
                    required
                  />
                  <input
                    type="file"
                    multiple
                    onChange={(e) => setEditDocumentFiles(e.target.files)}
                    className="w-full p-3 border rounded bg-gray-100"
                  />
                  <button
                    type="submit"
                    className="w-full bg-teal-500 text-gray-100 font-bold py-3 px-6 rounded-full hover:bg-teal-600 transition duration-300"
                  >
                    Update Task
                  </button>
                </form>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default TaskManagement;
