import React, { useEffect } from 'react';
import './APAPaper.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function APA7() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/mla-sample-paper');
    };
    return (
        <div className="apa-paper-example">
            <header className="header">
                <h1><span className="highlight">The Impact of Social Media on Mental Health</span></h1>
                <p className="author"><span className="highlight">Jane Doe</span></p>
                <p className="affiliation"><span className="highlight">Department of Psychology, University of Learning</span></p>
                <p className="course"><span className="highlight">PSY 101: Introduction to Psychology</span></p>
                <p className="instructor"><span className="highlight">Dr. John Smith</span></p>
                <p className="date"><span className="highlight">March 15, 2024</span></p>
            </header>

            <div className="cover-page-explanation">
                <p>Explanation: The title is centered and bolded, using title case. The author's name, affiliation, course, instructor's name, and date are all centered and double-spaced below the title.</p>
            </div>

            <section className="body-text">
                <h2><span className="highlight">Abstract</span></h2>
                <p><span className="highlight">The influence of social media on mental health has become a critical area of research.</span> This paper reviews recent studies on the connection between social media use and mental health, particularly among adolescents. The findings suggest that excessive social media use can lead to increased feelings of anxiety, depression, and loneliness. Strategies for mitigating these effects are also discussed.</p>
                <p className="explanation">Explanation: The abstract is a concise summary of the paper's content, typically 150-250 words, placed at the beginning of the paper.</p>

                <h2><span className="highlight">The Impact of Social Media on Mental Health</span></h2>
                <p><span className="highlight">Social media platforms like Facebook, Instagram, and Twitter have become integral parts of modern life.</span> While these platforms offer opportunities for social connection, they also pose significant risks to mental health, especially among young people. According to <span className="highlight">Smith et al. (2022)</span>, excessive use of social media has been linked to increased rates of anxiety and depression among adolescents <span className="highlight">(p. 12)</span>.</p>
                <p className="explanation">Explanation: In-text citations include the author's last name, publication year, and page number for direct quotes.</p>

                <p><span className="highlight">One of the primary concerns is the comparison factor.</span> Many users engage in social comparison, which can lead to feelings of inadequacy and low self-esteem. As <span className="highlight">Jones and Miller (2021)</span> point out, "the curated nature of social media content often presents an idealized version of reality, which can exacerbate feelings of inadequacy among users" <span className="highlight">(p. 45)</span>.</p>
                <p className="explanation">Explanation: Direct quotes are enclosed in quotation marks, with in-text citations immediately following the quote.</p>

                <p><span className="highlight">Another significant issue is cyberbullying.</span> Studies have shown that victims of online harassment are more likely to experience severe mental health issues, including depression and suicidal thoughts. A study by <span className="highlight">Brown and Lee (2020)</span> found that "adolescents who experienced cyberbullying were twice as likely to report suicidal ideation compared to those who were not bullied" <span className="highlight">(p. 98)</span>.</p>

                <p><span className="highlight">Moderate use, coupled with positive online interactions, can contribute to better mental health outcomes.</span> As <span className="highlight">Davis (2020)</span> suggests, "social media can provide valuable support networks and opportunities for positive social interactions when used responsibly" <span className="highlight">(p. 23)</span>.</p>

                <h2><span className="highlight">Effects on Adolescent Mental Health</span></h2>
                <p><span className="highlight">Adolescents are particularly vulnerable to the effects of social media on mental health due to their developmental stage.</span> The constant exposure to idealized images and lifestyles can lead to unrealistic expectations and a distorted sense of self-worth. According to a study by <span className="highlight">Garcia et al. (2021)</span>, "adolescents who spend more than three hours per day on social media are more likely to suffer from depression and anxiety compared to their peers who spend less time online" <span className="highlight">(p. 34)</span>.</p>
                <p className="explanation">Explanation: In-text citations for works with three or more authors are shortened to the first author's last name followed by "et al." and the publication year.</p>

                <p><span className="highlight">The fear of missing out (FOMO) is another phenomenon closely associated with social media use among adolescents.</span> FOMO can exacerbate feelings of loneliness and anxiety, as individuals constantly compare their experiences to those of others. <span className="highlight">Turner and Lopez (2020)</span> found that "FOMO is a significant predictor of social anxiety among adolescents, particularly those with a predisposition to anxiety disorders" <span className="highlight">(p. 67)</span>.</p>
            </section>

            <footer className="references">
                <h3><span className="highlight">References</span></h3>
                <p className="explanation">Explanation: The references list begins on a new page, with the title centered and bolded.</p>

                <ul>
                    <li><span className="highlight">Brown, T., & Lee, K. (2020). The impact of cyberbullying on adolescent mental health. Journal of Adolescent Health, 45(3), 90-102.</span></li>
                    <p className="explanation">Explanation: Reference entries are formatted with a hanging indent, and the journal title is italicized.</p>

                    <li><span className="highlight">Davis, L. (2020). Positive aspects of social media use. Psychological Research, 58(2), 20-30.</span></li>

                    <li><span className="highlight">Garcia, M., Smith, L., & Taylor, A. (2021). Social media usage and mental health in adolescents. Journal of Child Psychology, 78(2), 30-40.</span></li>

                    <li><span className="highlight">Jones, A., & Miller, S. (2021). Social comparison on social media platforms. Psychology Today, 36(4), 40-50.</span></li>

                    <li><span className="highlight">Smith, J., Doe, J., & Taylor, R. (2022). Social media use and mental health. Journal of Mental Health, 67(1), 10-15.</span></li>

                    <li><span className="highlight">Turner, R., & Lopez, G. (2020). The fear of missing out and its impact on social anxiety. Journal of Adolescent Psychology, 55(1), 60-70.</span></li>
                    <p className="explanation">Explanation: Entries should be listed alphabetically by the last name of the first author.</p>
                </ul>
            </footer>
            <div className="download-button-container">
                <h3><span className="highulight">Download this PDF to get a more detailed APA sample</span></h3>
                <a href="/Sample_APA_Paper.pdf" download className="downuload-button">
                    <img src="/pdf.jpg" alt="Download PDF" width="60" />

                </a>
            </div>
            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default APA7;
