// src/components/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <ul>
                <li>
                    <NavLink to="/java-learning/introduction" activeClassName="active">Introduction to Programming Concepts</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/oop-basics" activeClassName="active">Object-Oriented Programming (OOP) Basics</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/arrays-collections" activeClassName="active">Arrays and Collections</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/exception-handling" activeClassName="active">Exception Handling</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/basic-io" activeClassName="active">Basic I/O Operations</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/java-apis" activeClassName="active">Introduction to Java APIs</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/advanced-oop" activeClassName="active">Advanced OOP Concepts</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/advanced-data-structures" activeClassName="active">Advanced Data Structures</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/multithreading" activeClassName="active">Multithreading</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/java-io-serialization" activeClassName="active">Java I/O and Serialization</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/exception-handling-advanced" activeClassName="active">Exception Handling (Advanced)</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/java-memory-management" activeClassName="active">Java Memory Management</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/design-patterns" activeClassName="active">Design Patterns</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/java-networking" activeClassName="active">Java Networking</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/advanced-multithreading" activeClassName="active">Advanced Multithreading and Concurrency</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/java-8-features" activeClassName="active">Java 8+ Features</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/unit-testing-tdd" activeClassName="active">Unit Testing and TDD</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/building-java-applications" activeClassName="active">Building Java Applications</NavLink>
                </li>
                <li>
                    <NavLink to="/java-learning/integration-deployment" activeClassName="active">Integration and Deployment</NavLink>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
