import React, { useEffect } from 'react';
import './GoogleScholar.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function GoogleScholar() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/tools-resources');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="google-scholar-page">
            <header className="header">
                <h1>Mastering Google Scholar</h1>
            </header>

            <section className="contient">
                <div className="section-block">
                    <h2>Introduction to Google Scholar</h2>
                    <p>
                        Google Scholar is a powerful tool for academic research. It provides access to a vast database of scholarly articles, theses, books, and conference papers. Understanding its features can greatly enhance your research efficiency and effectiveness. This guide will explore practical strategies for mastering Google Scholar.
                    </p>
                    <a href="https://scholar.google.com/" target="_blank" rel="noopener noreferrer" className="link">Visit Google Scholar</a>
                </div>

                <div className="section-block">
                    <h2>Understanding Search Features</h2>
                    <p>
                        Google Scholar offers various search features that can optimize your results. Use keywords, phrases, and Boolean operators to refine your search. Understanding how to utilize these features can help you find the most relevant academic materials quickly.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Setting Up Alerts</h2>
                    <p>
                        By setting up Google Scholar Alerts, you can stay updated on new publications in your field. This feature allows you to receive notifications about relevant articles, ensuring you never miss important research developments. It's a proactive way to enhance your literature review.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Utilizing Advanced Search</h2>
                    <p>
                        The Advanced Search option in Google Scholar allows for more precise queries. You can filter by author, publication date, and specific journals. This feature is essential for narrowing down results to the most pertinent studies for your research.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Citing Sources Effectively</h2>
                    <p>
                        Google Scholar provides tools for citing sources in various formats. This feature simplifies the process of compiling bibliographies and ensures proper attribution. Learning how to use these citation tools can save time and enhance the credibility of your work.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Exploring Related Articles</h2>
                    <p>
                        After finding a relevant article, Google Scholar offers a feature to explore related articles. This can lead to discovering additional research that may be beneficial to your study. It's a great way to expand your research network.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Using Metrics for Evaluation</h2>
                    <p>
                        Google Scholar provides various metrics such as citation counts and h-index scores. These metrics can help evaluate the impact of an article or author. Understanding these indicators is crucial for assessing the quality of research.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Finding Full Texts</h2>
                    <p>
                        While Google Scholar indexes many articles, not all are available in full text. Utilize the 'All versions' link to find freely accessible copies. This strategy can help you access valuable research without institutional barriers.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Collaborating with Researchers</h2>
                    <p>
                        Google Scholar can facilitate collaboration with other researchers. Use the platform to identify potential collaborators by exploring their publications and citation networks. Building these connections can enhance your research projects and opportunities.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Leveraging Library Resources</h2>
                    <p>
                        Many academic libraries provide access to Google Scholar through their databases. Leveraging these resources can enhance your research capabilities, allowing you to access a wider range of materials and support services offered by the library.
                    </p>
                </div>
            </section>
            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default GoogleScholar;
