import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Home = () => {
  const testimonials = [
    {
      name: "Sarah J.",
      role: "Academic Writer",
      text: "FitFreelance has been a game-changer for my writing career. The expert guidance and diverse opportunities have helped me grow professionally."
    },
    {
      name: "Michael T.",
      role: "Freelance Blogger",
      text: "I've found amazing clients and honed my skills thanks to FitFreelance. The platform is user-friendly and the community is supportive."
    },
    {
      name: "Emily R.",
      role: "Data Entry Specialist",
      text: "The data entry projects on FitFreelance are well-organized and fairly compensated. It's been a reliable source of income for me."
    }
  ];

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col items-center justify-center text-center px-4 mb-16">
          <div className="max-w-3xl">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 text-purple-300">
              Learn and Earn with
              <span className="block text-teal-400 mt-2">FitFreelance</span>
            </h1>
            <p className="text-xl mb-10 text-gray-300">
              Your one-stop platform for professional academic writing, blog writing, freelance work, data entry, and transcription services.
            </p>
            <Link to="/register">
              <button className="bg-teal-500 text-gray-900 font-bold py-4 px-8 rounded-full hover:bg-teal-400 transition duration-300 transform hover:scale-105">
                SIGN UP NOW
              </button>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          <FeatureCard
            icon="📚"
            title="Academic Writing"
            description="Learn from experienced academic writers and researchers."
          />
          <FeatureCard
            icon="🖋️"
            title="Blog Writing & Article Writing"
            description="Master the art of academic writing and get to work with Fitfreelance."
          />
          <FeatureCard
            icon="💼"
            title="Data Entry & Transcription"
            description="Boost your productivity with our precise data entry and transcription services."
          />
          <FeatureCard
            icon="🏆"
            title="Professional Growth"
            description="Elevate your academic and professional writing skills to the next level."
          />
        </div>
        <div className="mb-16">
        <motion.h2 
          className="text-3xl font-bold mb-8 text-center text-purple-300"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What Our Users Say
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} index={index} />
          ))}
        </div>
      </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-2xl transition duration-300 transform hover:scale-105">
      <div className="text-5xl mb-4">{icon}</div>
      <h3 className="text-2xl font-semibold mb-3 text-purple-300">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
  );
};

const TestimonialCard = ({ name, role, text, index }) => {
  return (
    <motion.div 
      className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-2xl transition duration-300"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      whileHover={{ scale: 1.05 }}
    >
      <motion.p 
        className="text-gray-300 mb-4 italic"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: index * 0.2 + 0.3 }}
      >
        "{text}"
      </motion.p>
      <motion.div 
        className="flex items-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: index * 0.2 + 0.6 }}
      >
        <div className="w-10 h-10 bg-teal-500 rounded-full flex items-center justify-center mr-3">
          <span className="text-gray-900 font-bold">{name[0]}</span>
        </div>
        <div>
          <h4 className="text-purple-300 font-semibold">{name}</h4>
          <p className="text-gray-400 text-sm">{role}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Home;