import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TaskSubmission from './TaskSubmission';
import RevisionSubmission from './RevisionSubmission';
import { ClipLoader } from 'react-spinners';
import { API_BASE_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const TaskProfileDisplay = ({ userToken, taskType }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [submissionType, setSubmissionType] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/api/users/${taskType}`, {
          headers: { Authorization: `Bearer ${userToken}` }
        });
        setTasks(response.data);
        setLoading(false);
      } catch (error) {
        console.error(`Error fetching ${taskType}:`, error);
        setError(`Failed to fetch ${taskType}`);
        setLoading(false);
      }
    };
    fetchTasks();
  }, [taskType, userToken]);

  const getPageTitle = () => {
    switch(taskType) {
      case 'bids': return 'Bidded Tasks';
      case 'accepted-tasks': return 'Accepted Tasks';
      case 'completed-tasks': return 'Completed Tasks';
      case 'revision-tasks': return 'Revision Tasks';
      default: return 'Tasks';
    }
  };

  const handleTaskClick = (task) => {
    if (taskType === 'accepted-tasks' || taskType === 'revision-tasks') {
      setSelectedTask(task);
      setSubmissionType(taskType);
    }
  };

  if (selectedTask) {
    return (
      submissionType === 'revision-tasks' ? (
        <RevisionSubmission task={selectedTask} userToken={userToken} onBack={() => setSelectedTask(null)} />
      ) : (
        <TaskSubmission task={selectedTask} userToken={userToken} onBack={() => setSelectedTask(null)} />
      )
    );
  }

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen p-8">
      <button
        onClick={() => navigate('/profile')}
        className="flex items-center text-teal-400 hover:text-teal-300 mb-6 transition duration-300"
      >
        <FaArrowLeft className="mr-2" />
        Back to Profile
      </button>

      <h2 className="text-3xl font-bold mb-6 text-purple-300">{getPageTitle()}</h2>

      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <ClipLoader color="#38bdf8" loading={loading} size={150} />
        </div>
      ) : error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        tasks.length === 0 ? (
          <p>No tasks found.</p>
        ) : (
          <ul className="space-y-4">
            {tasks.map(task => (
              <li
                key={task.id}
                className={`bg-gray-800 p-4 rounded cursor-pointer ${taskType === 'accepted-tasks' || taskType === 'revision-tasks' ? 'hover:bg-gray-700' : ''}`}
                onClick={() => handleTaskClick(task)}
              >
                <h3 className="text-xl font-semibold mb-2 text-teal-400">{task.title}</h3>
                <p className="text-gray-300">
                  {task.description.length > 200
                    ? `${task.description.substring(0, 200)}...`
                    : task.description}
                </p>
                <p className="text-lg font-semibold mt-3 mb-2">
                  Status: <span className="text-yellow-400">{task.status}</span>
                </p>
                {taskType === 'accepted-tasks' && task.deadline && (
                  <p className="text-lg font-semibold text-red-400">
                    Deadline: {new Date(task.deadline).toLocaleString()}
                  </p>
                )}
                {taskType === 'completed-tasks' && task.completedAt && (
                  <p className="text-gray-400">
                    Completed on: {new Date(task.completedAt).toLocaleDateString()}
                  </p>
                )}
              </li>
            ))}
          </ul>
        )
      )}
    </div>
  );
};

export default TaskProfileDisplay;