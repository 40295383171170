import React, { useEffect } from 'react';
import './HarvardStyleEssay.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function HarvardStyleEssay() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/plagiarism-checker');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="harvard-paper-example">
            <header className="header">
                <h1><span className="highlightI">Mastering the Harvard Referencing Style: A Comprehensive Guide to Academic Citations</span></h1>
                <p className="author"><span className="highlightI">John Doe</span></p>
                <p className="affiliation"><span className="highlightI">Department of English, University of Learning</span></p>
                <p className="course"><span className="highlightI">ENG 305: Advanced Research Writing</span></p>
                <p className="instructor"><span className="highlightI">Prof. Jane Smith</span></p>
                <p className="date"><span className="highlightI">10 October 2023</span></p>
            </header>

            <div className="cover-page-explanation">
                <p>Explanation: The title is centered and capitalized. The author's name, course details, instructor's name, and date are centered and double-spaced below the title.</p>
            </div>

            <section className="body-text">
                <h2><span className="highlightI">Introduction to Harvard Referencing</span></h2>
                <p><span className="highlightI">The Harvard Referencing Style is an author-date system that provides a clear and concise way to cite sources.</span> Understanding this style is crucial for maintaining academic integrity and providing proper credit to sources. It allows readers to easily locate the original works used in your research. This style is favored for its simplicity and effectiveness in academic writing.</p>
                <p className="explanation">Explanation: The introduction provides a brief overview of the Harvard Referencing Style and its importance in academic writing.</p>

                <h2><span className="highlightI">The Importance of Referencing</span></h2>
                <p><span className="highlightI">Referencing is essential in academic writing for several reasons: it helps to avoid plagiarism, provides credibility to your work, and allows readers to verify your sources.</span> Proper referencing also enhances the overall quality of your research.</p>
                <p className="explanation">Explanation: This section highlightIs the key reasons why referencing is important in academic work.</p>

                <h2><span className="highlightI">Basic Format for Books</span></h2>
                <p>When referencing a book in Harvard style, the basic format includes the author’s last name, initials, year of publication, title in italics, and publisher. For example: <span className="highlightI">Smith, J. (2020). Understanding Referencing. Academic Press.</span></p>
                <p className="explanation">Explanation: The basic format for citing a book includes all the necessary details for readers to locate the source.</p>

                <h2><span className="highlightI">Citing Journal Articles</span></h2>
                <p>For journal articles, the format includes the author’s last name, initials, year, title of the article, title of the journal in italics, volume number, and page range. Example: <span className="highlightI">Doe, A. (2019). 'Harvard Style Explained'. Journal of Academic Writing, 15(2), 45-58.</span></p>
                <p className="explanation">Explanation: Citing journal articles in Harvard style requires specific details, including the journal's volume and issue number.</p>

                <h2><span className="highlightI">Referencing Websites</span></h2>
                <p>When citing websites, include the author’s name (if available), year, title of the webpage, and the URL. Example: <span className="highlightI">Brown, L. (2021). 'Citing Online Sources'. Available at: www.example.com. Accessed: 10 October 2023.</span></p>
                <p className="explanation">Explanation: Websites are cited by including the author (if available), title, publication date, and the access date.</p>

                <h2><span className="highlightI">In-Text Citations</span></h2>
                <p>In-text citations in Harvard style typically include the author’s last name and year of publication in parentheses. For example: <span className="highlightI">(Smith, 2020).</span> If quoting directly, include the page number: <span className="highlightI">(Smith, 2020, p. 15).</span></p>
                <p className="explanation">Explanation: In-text citations provide a quick reference to the source within the text, linking to the full citation in the reference list.</p>

                <h2><span className="highlightI">Reference List Essentials</span></h2>
                <p>A reference list is crucial at the end of your document. It should be titled 'References' and formatted alphabetically by the author’s last name. Ensure all cited works are included, providing complete information for readers to locate the sources.</p>
                <p className="explanation">Explanation: The reference list is a comprehensive record of all sources cited in the paper, organized alphabetically.</p>

                <h2><span className="highlightI">Common Mistakes to Avoid</span></h2>
                <p>Avoid common pitfalls such as inconsistent formatting, missing information, and incorrect punctuation. Always double-check your citations for accuracy and completeness. These mistakes can undermine the credibility of your academic work.</p>

                <h2><span className="highlightI">Practical Examples</span></h2>
                <p>Let’s review some practical examples of Harvard referencing in action. By analyzing different types of sources, we can better understand how to apply the rules effectively. This will enhance your confidence in using this referencing style.</p>

                <h2><span className="highlightI">Conclusion</span></h2>
                <p>Mastering the Harvard Referencing Style is essential for academic success. By understanding its components, practicing citations, and avoiding common mistakes, you can enhance the credibility and quality of your research.</p>
            </section>

            <footer className="references">
                <h3><span className="highlightI">References</span></h3>
                <p className="explanation">Explanation: The references list begins on a new page, with entries in alphabetical order by the author's last name.</p>

                <ul>
                    <li><span className="highlightI">Brown, L. (2021). 'Citing Online Sources'. Available at: www.example.com. Accessed: 10 October 2023.</span></li>
                    <p className="explanation">Explanation: Reference entries should include all necessary details, such as author, publication year, title, and access date for online sources.</p>

                    <li><span className="highlightI">Doe, A. (2019). 'Harvard Style Explained'. Journal of Academic Writing, 15(2), 45-58.</span></li>

                    <li><span className="highlightI">Smith, J. (2020). Understanding Referencing. Academic Press.</span></li>
                </ul>
            </footer>

            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default HarvardStyleEssay;
