import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col items-center justify-center p-8">
      <div className="max-w-3xl w-full px-6 py-8 bg-gray-800 rounded-lg shadow-md overflow-y-auto max-h-[80vh]">
        <h1 className="text-3xl font-bold mb-6 text-teal-400 text-center">Comprehensive Terms of Use for FitFreelance</h1>
        <div className="space-y-6 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold text-teal-400">1. Introduction and Definitions</h2>
            <p>These Terms of Use (hereinafter referred to as "ToU", "Terms", or "Agreement") govern your access to and use of the FitFreelance website (the "Website") and all services provided therein. By accessing or using our Website, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Website or use our services.</p>
            <p>For the purposes of these Terms, key definitions include "Account", "User", "FitFreelance", "Website", "Services", "Content", "Customer", and "Intellectual Property Rights".</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">2. Acceptance of Terms</h2>
            <p>By accessing the FitFreelance Website, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use. We reserve the right to make changes to these Terms at any time. Your continued use of the Website following the posting of any changes constitutes acceptance of those changes.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">3. Registration and Account Security</h2>
            <p>To access certain features of the Website, you may be required to register for an Account. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account or password.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">4. Payment for Training</h2>
            <p><strong>Non-Refundable Fee:</strong> Upon logging into the FitFreelance Website, you are required to pay a NON-REFUNDABLE fee of Ksh 300 for access to our training materials. This fee covers comprehensive training in various areas including article and blog writing, transcription, and academic writing.</p>
            <p>Payment will be processed after 18 days from the completion of your training and successful passing of the associated test.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">5. Training and Testing</h2>
            <p>After successful payment, you will be granted immediate access to our comprehensive training materials. Following the completion of your training, you will be issued a test that must be completed within 24 hours. Upon successful completion of the test, you will receive Ksh 300 as compensation.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">6. Job Listings and Bidding</h2>
            <p>After passing the test, you will gain access to job listings on our Website. You may bid on available jobs that match your skills and interests. All communication with Customers should be conducted through the FitFreelance platform.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">7. Submission of Work</h2>
            <p>All tasks assigned to you must be submitted before the specified deadline. Work submitted must meet the quality standards outlined in the task description and adhere to FitFreelance's general quality guidelines.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">8. Community Guidelines</h2>
            <p>You are expected to maintain a professional and respectful demeanor in all interactions on the FitFreelance platform. FitFreelance provides a Slack channel as a forum for freelancers to connect, share knowledge, and seek advice.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">9. Intellectual Property</h2>
            <p>FitFreelance and our licensors retain all right, title, and interest in and to all Intellectual Property Rights related to the Website, its content, and the services provided. You retain ownership of any intellectual property rights that you hold in content you submit to FitFreelance.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">10. Privacy and Data Protection</h2>
            <p>FitFreelance collects and processes personal data as described in our Privacy Policy. We implement suitable security measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">11. Payment Terms</h2>
            <p>FitFreelance operates on a bi-weekly payment schedule. We will process payments for completed and approved work after two weeks from the job completion date. All payments will be made in Kenyan Shillings (KSH) unless otherwise specified.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">12. Termination and Suspension of Use</h2>
            <p>You may terminate your account at any time by contacting our support team. FitFreelance reserves the right to terminate or suspend your account at any time, without prior notice or liability, for any reason whatsoever.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">13. Limitation of Liability</h2>
            <p>The Website and its services are provided on an "AS IS" and "AS AVAILABLE" basis, without any warranties of any kind. FitFreelance does not guarantee any level of income or jobs for freelancers.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">14. Indemnification</h2>
            <p>You agree to defend, indemnify and hold harmless FitFreelance and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">15. Governing Law</h2>
            <p>These Terms shall be governed and construed in accordance with the laws of Kenya, without regard to its conflict of law provisions.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">16. Changes to Terms</h2>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide at least 30 days' notice prior to any new terms taking effect.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">17. Contact Information</h2>
            <p>If you have any questions about these Terms, please contact us at admin@fitfreelance.co.ke.</p>
          </section>
        </div>
        <button
          onClick={() => navigate(-1)}
          className="mt-6 w-full p-3 bg-teal-500 text-gray-900 rounded hover:bg-teal-400 transition duration-300"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default TermsAndConditions;