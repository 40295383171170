import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Register from './components/user/Register';
import Login from './components/user/Login';
import TaskList from './components/user/TaskList';
import Home from './components/user/Home';
import UserNavbar from './components/user/UserNavbar';
import AdminRegister from './components/admin/AdminRegister';
import AdminLogin from './components/admin/AdminLogin';
import TaskManagement from './components/admin/TaskManagement';
import AdminNavbar from './components/admin/AdminNavbar';
import AdminDashboard from './components/admin/AdminDashboard';
import AdminWriters from './components/admin/AdminWriters';
import AdminOrders from './components/admin/AdminOrders';
import AdminBids from './components/admin/AdminBids';
import AdminTransactions from './components/admin/AdminTransactions';
import FAQ from './components/user/FAQ';
import UserProfile from './components/user/UserProfile';
import TaskDetails from './components/user/TaskDetails';
import TaskProfileDisplay from './components/user/TaskProfileDisplay';
import TransactionWallet from './components/user/TransactionWallet';
import TermsAndConditions from './components/user/TermsAndConditions';
import AdminTaskDetails from './components/admin/AdminTaskDetails';
import EmailVerification from './components/user/EmailVerification';
import LearningModule from './LearningModule';
import JavaLearningModule from './JavaLearningModule';
import BloggingModule from './BlogingModule';
import EssayInstructions from './components/user/EssayInstructions';
import { API_BASE_URL } from './components/config';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userToken, setUserToken] = useState(sessionStorage.getItem('userToken'));
  const [learningStatus, setLearningStatus] = useState({ completedLearning: false });
  const [avatarUrl, setAvatarUrl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const adminToken = sessionStorage.getItem('adminToken');
    const userToken = sessionStorage.getItem('userToken');
   
    if (adminToken) {
      setIsLoggedIn(true);
      setIsAdmin(true);
    } else if (userToken) {
      setIsLoggedIn(true);
      setIsAdmin(false);
      setUserToken(userToken);
    }
  }, []);

  
  useEffect(() => {
    // Check if the current page is not an admin page
    if (!location.pathname.startsWith('/admin')) {
      // Initialize Chatwoot
      (function(d,t) {
        var BASE_URL="https://app.chatwoot.com";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: 'pxvgsaraKrcex7dRTFbeoCuA',
            baseUrl: BASE_URL
          });
  
          // Set user information if logged in
          if (isLoggedIn && userToken) {
            fetch(`${API_BASE_URL}/api/users/profile`, {
              method: 'GET',
              headers: { 'Authorization': `Bearer ${userToken}` },
            })
            .then(response => response.json())
            .then(userData => {
              window.chatwootSDK.setUser(userData.id, {
                name: userData.fullName,
                email: userData.email
              });
            })
            .catch(error => console.error('Error fetching user data:', error));
          }
        }
      })(document,"script");
    }
  }, [location, isLoggedIn, userToken]);


  const handleLoginSuccess = (token, learningStatus = null, isAdmin = false, avatar) => {
    if (isAdmin) {
      sessionStorage.setItem('adminToken', token);
      setIsAdmin(true);
      setIsLoggedIn(true);
      navigate('/admin/dashboard');
    } else {
      sessionStorage.setItem('userToken', token);
      setUserToken(token);
      setLearningStatus(learningStatus);
      setIsLoggedIn(true);
      setAvatarUrl(avatar);
      navigate('/tasks');
    }
  };  

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    setUserToken(null);
    setAvatarUrl(null);
    if (isAdmin) {
      sessionStorage.removeItem('adminToken');
      navigate('/admin/login');
    } else {
      sessionStorage.removeItem('userToken');
      navigate('/login');
    }
  };

  // Determine if the current path is an admin path
  const isAdminPath = location.pathname.startsWith('/admin');

  return (
    <>
    <div className="bg-gray-900 min-h-screen">
      {isAdminPath ? (
        <AdminNavbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
      ) : (
        <UserNavbar 
        isLoggedIn={isLoggedIn} 
        onLogout={handleLogout}
        avatarUrl={avatarUrl}
        completedLearning={learningStatus?.completedLearning}
      />
      )}
      <div className="container mx-auto p-4 text-gray-100">
        <Routes>
          {/* User Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path='/verify-email' element={<EmailVerification />} />
          <Route path="/login" element={<Login onLoginSuccess={(token, learningStatus) => handleLoginSuccess(token, learningStatus)} />} />
          <Route path="/tasks" element={<TaskList userToken={userToken} learningStatus={learningStatus} />} />
          <Route path="/profile" element={<UserProfile userToken={userToken} learningStatus={learningStatus}/>} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/essay-instructions" element={<EssayInstructions />} />
          <Route path="/task/:taskId" element={<TaskDetails userToken={userToken} learningStatus={learningStatus} />} />
          <Route path="/bids" element={<TaskProfileDisplay userToken={userToken} taskType="bids" />} />
          <Route 
            path="/bids" 
            element={isLoggedIn ? <TaskProfileDisplay userToken={userToken} taskType="bids" /> : <Login onLoginSuccess={(token, learningStatus) => handleLoginSuccess(token, learningStatus)} />} 
          />
          <Route 
            path="/accepted-tasks" 
            element={isLoggedIn ? <TaskProfileDisplay userToken={userToken} taskType="accepted-tasks" /> : <Login onLoginSuccess={(token, learningStatus) => handleLoginSuccess(token, learningStatus)} />} 
          />
          <Route 
            path="/completed-tasks" 
            element={isLoggedIn ? <TaskProfileDisplay userToken={userToken} taskType="completed-tasks" /> : <Login onLoginSuccess={(token, learningStatus) => handleLoginSuccess(token, learningStatus)} />} 
          />
          <Route 
            path="/revision-tasks" 
            element={isLoggedIn ? <TaskProfileDisplay userToken={userToken} taskType="revision-tasks" /> : <Login onLoginSuccess={(token, learningStatus) => handleLoginSuccess(token, learningStatus)} />} 
          />
          <Route path="/transaction-wallet" element={<TransactionWallet userToken={userToken} />} />
          <Route 
            path="/learning/*" 
            element={isLoggedIn ? <LearningModule userToken={userToken} learningStatus={learningStatus} /> : <Login onLoginSuccess={(token, learningStatus) => handleLoginSuccess(token, learningStatus)} />}
          />
          <Route 
          path="/blogging/*" 
          element={isLoggedIn ? <BloggingModule userToken={userToken} learningStatus={learningStatus} /> : <Login onLoginSuccess={(token, learningStatus) => handleLoginSuccess(token, learningStatus)} />} 
          />
          <Route 
          path="/java-learning/*" 
          element={isLoggedIn ? <JavaLearningModule userToken={userToken} learningStatus={learningStatus} /> : <Login onLoginSuccess={(token, learningStatus) => handleLoginSuccess(token, learningStatus)} />}
          />

          {/* Admin Routes */}
          <Route path="/admin/register" element={<AdminRegister />} />
          <Route path="/admin/login" element={<AdminLogin onLoginSuccess={(token, _, isAdmin) => handleLoginSuccess(token, null, isAdmin)} />} />
          <Route path="/admin/dashboard" element={isAdmin ? <AdminDashboard /> : <AdminLogin onLoginSuccess={(token, _, isAdmin) => handleLoginSuccess(token, null, isAdmin)} />} />
          <Route path="/admin/tasks" element={isAdmin ? <TaskManagement /> : <AdminLogin onLoginSuccess={(token, _, isAdmin) => handleLoginSuccess(token, null, isAdmin)} />} />
          <Route path="/admin/writers" element={isAdmin ? <AdminWriters /> : <AdminLogin onLoginSuccess={(token, _, isAdmin) => handleLoginSuccess(token, null, isAdmin)} />} />
          <Route path="/admin/orders" element={isAdmin ? <AdminOrders /> : <AdminLogin onLoginSuccess={(token, _, isAdmin) => handleLoginSuccess(token, null, isAdmin)} />} />
          <Route path="/admin/bids" element={isAdmin ? <AdminBids /> : <AdminLogin onLoginSuccess={(token, _, isAdmin) => handleLoginSuccess(token, null, isAdmin)} />} />
          <Route path="/admin/transactions" element={isAdmin ? <AdminTransactions /> : <AdminLogin onLoginSuccess={(token, _, isAdmin) => handleLoginSuccess(token, null, isAdmin)} />} />
          <Route path="/admin/orders/:id" element={isAdmin ? <AdminTaskDetails /> : <AdminLogin onLoginSuccess={(token, _, isAdmin) => handleLoginSuccess(token, null, isAdmin)} />} />
        </Routes>
      </div>
    </div>
    </>
  );
}

export default App;
