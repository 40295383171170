import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './APAStyle.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function APAStyle() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/apa7');
    };
    return (
        <div className="page-content">
            <header className="header">
                <h1>Mastering APA Style: A Comprehensive Guide with Practical Examples</h1>
            </header>

            <section className="cointent">
                <div className="section-block">
                    <h2>Introduction to APA Style</h2>
                    <p className="highlighted-text">
                        APA Style is a widely used formatting and citation style in the social sciences. This guide aims to provide a comprehensive overview with practical examples to help you master the nuances of APA formatting and citation.
                    </p>
                </div>

                <div className="section-block">
                    <h2>History of APA Style</h2>
                    <p>
                        The American Psychological Association (APA) established APA Style in 1929 to standardize writing in the social sciences. Over the years, it has evolved through various editions, with the 7th edition being the most current.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Basic Formatting Guidelines</h2>
                    <ul>
                        <li>Use 1-inch margins.</li>
                        <li>Readable font like 12-point Times New Roman.</li>
                        <li>Double-spacing throughout the document.</li>
                        <li>
                            Title page should include:
                            <ul>
                                <li>Title</li>
                                <li>Author's name</li>
                                <li>Institutional affiliation</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>In-Text Citations</h2>
                    <p>
                        In-text citations in APA Style include the author's last name and the year of publication. For example, <span className="important">(Smith, 2020)</span>. If directly quoting, include the page number as well. Proper in-text citations are crucial for giving credit to original authors and avoiding plagiarism.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Reference List Basics</h2>
                    <p>
                        The Reference List is a crucial component of APA Style, providing full citations for all sources used. It should start on a new page, titled <span className="italic-text">References</span>, and entries should be in alphabetical order. Each entry must include the author's name, publication year, title, and source.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Citing Books</h2>
                    <p>
                        When citing a book in APA Style, include the author's last name, first initial, publication year, title in <span className="italic-text">italics</span>, and publisher. For example: <span className="bold-text">Smith, J. (2020). Understanding Psychology. Academic Press.</span> This format ensures that readers can easily locate the source.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Citing Journal Articles</h2>
                    <p>
                        To cite a journal article, include the author's name, publication year, article title, journal name in <span className="italic-text">italics</span>, volume number, and page range. For example: <span className="bold-text">Smith, J. (2020). The impact of social media. Journal of Social Science, 12(3), 45-67.</span> This format is essential for scholarly communication.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Citing Online Sources</h2>
                    <p>
                        Citing online sources requires the author's name, publication date, title, website name, and the URL. For example: <a href="https://www.psychologytoday.com/online-behavior" target="_blank" rel="noopener noreferrer">Smith, J. (2020). Understanding online behavior. Psychology Today.</a> This ensures proper attribution for digital content.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Tables and Figures</h2>
                    <p>
                        When including tables and figures in your work, each must be labeled and numbered. Provide a title and a brief description. Refer to them in your text, and ensure they follow APA formatting guidelines. This enhances the clarity and effectiveness of your data presentation.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Common Mistakes</h2>
                    <p>
                        Common mistakes in APA Style include incorrect formatting of citations, improper use of italics, and neglecting to include a reference list. Familiarizing yourself with these errors can help improve your writing. Always double-check your work to ensure compliance with APA guidelines.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Resources for Mastery</h2>
                    <p>
                        To master APA Style, utilize resources such as the APA Manual, online citation tools, and writing centers at your institution. Websites like the APA Style website offer free resources and examples. Continuous practice and seeking help will enhance your understanding and application of the style.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Conclusion</h2>
                    <p>
                        Mastering APA Style is essential for effective academic writing. By understanding its guidelines, practicing proper citation, and utilizing available resources, you can enhance the quality of your work. Remember, adherence to APA Style not only gives credit to authors but also strengthens your credibility as a writer.
                    </p>
                </div>
            </section>

            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default APAStyle;
