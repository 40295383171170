import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithCustomToken } from 'firebase/auth';
import { auth } from '../../firebase';
import { API_BASE_URL } from '../config';

const AdminLogin = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      // Sign in with Firebase
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Get the ID token
      const idToken = await user.getIdToken();

      // Send the ID token to your backend
      const response = await fetch(`${API_BASE_URL}/api/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });

      const result = await response.json();

      if (response.ok) {
        // Exchange the custom token for an ID token
        const userCredential = await signInWithCustomToken(auth, result.token);
        const finalIdToken = await userCredential.user.getIdToken();

        sessionStorage.setItem('adminToken', finalIdToken);
        onLoginSuccess(finalIdToken, null, true);
        navigate('/admin/dashboard');
      } else {
        setMessage(result.error || "Login failed");
      }
    } catch (error) {
      console.error('Login error:', error);
      setMessage(error.message || "An error occurred during login");
    }
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="bg-gray-900 p-8 rounded-lg shadow-md max-w-lg mx-auto">
        <h2 className="text-2xl font-bold mb-6 text-gray-100 text-center">Admin Login</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border rounded bg-gray-800 text-white"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 border rounded bg-gray-800 text-white"
          />
          <button
            type="submit"
            className="w-full bg-teal-500 text-white font-bold py-3 px-6 rounded-full hover:bg-teal-600 transition duration-300"
          >
            Login
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
};

export default AdminLogin;