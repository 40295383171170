// src/pages/IntegrationAndDeploymentGuide.js
import React from 'react';
import CodeBlock from '../components/CodeBlock';
import Sidebar from '../components/Sidebar';


const IntegrationAndDeploymentGuide = () => {
    const mavenMySQLCode = `
<dependencies>
    <dependency>
        <groupId>mysql</groupId>
        <artifactId>mysql-connector-java</artifactId>
        <version>8.0.26</version>
    </dependency>
</dependencies>
    `;

    const gradleMySQLCode = `
dependencies {
    implementation 'mysql:mysql-connector-java:8.0.26'
}
    `;

    const jdbcConnectionCode = `
import java.sql.Connection;
import java.sql.DriverManager;
import java.sql.ResultSet;
import java.sql.Statement;

public class JDBCExample {
    public static void main(String[] args) {
        String jdbcURL = "jdbc:mysql://localhost:3306/mydatabase";
        String username = "root";
        String password = "password";

        try (Connection connection = DriverManager.getConnection(jdbcURL, username, password)) {
            System.out.println("Connected to the database");

            String sql = "SELECT * FROM users";
            Statement statement = connection.createStatement();
            ResultSet resultSet = statement.executeQuery(sql);

            while (resultSet.next()) {
                int id = resultSet.getInt("id");
                String name = resultSet.getString("name");
                String email = resultSet.getString("email");

                System.out.println("ID: " + id + ", Name: " + name + ", Email: " + email);
            }
        } catch (Exception e) {
            e.printStackTrace();
        }
    }
}
    `;

    const insertQueryCode = `
String insertSQL = "INSERT INTO users (name, email) VALUES ('John Doe', 'john.doe@example.com')";
int rowsInserted = statement.executeUpdate(insertSQL);
if (rowsInserted > 0) {
    System.out.println("A new user was inserted successfully!");
}
    `;

    const springBootMavenCode = `
<dependencies>
    <dependency>
        <groupId>org.springframework.boot</groupId>
        <artifactId>spring-boot-starter-web</artifactId>
    </dependency>
</dependencies>
    `;

    const springBootGradleCode = `
dependencies {
    implementation 'org.springframework.boot:spring-boot-starter-web'
}
    `;

    const springBootRestCode = `
import org.springframework.boot.SpringApplication;
import org.springframework.boot.autoconfigure.SpringBootApplication;
import org.springframework.web.bind.annotation.GetMapping;
import org.springframework.web.bind.annotation.PathVariable;
import org.springframework.web.bind.annotation.RequestMapping;
import org.springframework.web.bind.annotation.RestController;

@SpringBootApplication
public class MySpringBootApplication {

    public static void main(String[] args) {
        SpringApplication.run(MySpringBootApplication.class, args);
    }
}

@RestController
@RequestMapping("/api")
public class UserController {

    @GetMapping("/users/{id}")
    public User getUserById(@PathVariable int id) {
        return new User(id, "John Doe", "john.doe@example.com");
    }
}

class User {
    private int id;
    private String name;
    private String email;

    public User(int id, String name, String email) {
        this.id = id;
        this.name = name;
        this.email = email;
    }

    // Getters and setters...
}
    `;

    const runJarCode = `
java -jar my-spring-boot-app.jar
    `;

    const copyWarCode = `
cp my-web-app.war /path/to/tomcat/webapps/
    `;

    const environmentVariablesCode = `
export JAVA_HOME=/path/to/java
export CATALINA_HOME=/path/to/tomcat
    `;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Integration and Deployment in Java: A Comprehensive Guide</h1>
                <p>
                    Integration and deployment are crucial stages in the software development lifecycle, ensuring that your Java applications can interact with external systems (like databases or web services) and be deployed in various environments. This guide covers key topics such as using JDBC for database connectivity, creating RESTful web services with Spring Boot, and deploying Java applications on servers. Each section includes detailed explanations, code examples, and practical applications.
                </p>

                <hr className="section-divider" />

                <h2>1. Using JDBC for Database Connectivity</h2>

                <h3>1.1. Overview</h3>
                <p>
                    Java Database Connectivity (JDBC) is an API that enables Java applications to interact with relational databases. JDBC allows you to execute SQL queries, retrieve results, and update records in the database from within your Java code.
                </p>

                <ul>
                    <li><strong>Driver Manager:</strong> Manages a list of database drivers. It matches connection requests from the application with the correct database driver using a URL.</li>
                    <li><strong>Connection:</strong> Represents a session with a specific database.</li>
                    <li><strong>Statement:</strong> Used to execute SQL queries.</li>
                    <li><strong>ResultSet:</strong> Represents the result set of a query.</li>
                </ul>

                <h3>1.2. Setting Up JDBC</h3>
                <p>
                    Before using JDBC, you need to include the appropriate database driver in your project. For example, if you're connecting to a MySQL database, you would include the MySQL JDBC driver.
                </p>

                <h4>Adding MySQL JDBC Driver with Maven:</h4>
                <CodeBlock code={mavenMySQLCode} />

                <h4>Adding MySQL JDBC Driver with Gradle:</h4>
                <CodeBlock code={gradleMySQLCode} />

                <h3>1.3. Connecting to a Database</h3>
                <p>To connect to a database, you need the database URL, username, and password. The <code>DriverManager.getConnection()</code> method is used to establish a connection.</p>

                <CodeBlock code={jdbcConnectionCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Connection:</strong> The <code>DriverManager.getConnection()</code> method establishes a connection to the database using the provided URL, username, and password.</li>
                    <li><strong>Statement:</strong> The <code>Statement</code> object is used to execute SQL queries.</li>
                    <li><strong>ResultSet:</strong> The <code>ResultSet</code> object holds the data retrieved from the database, which can be iterated over to process the query results.</li>
                </ul>

                <h3>1.4. Executing SQL Queries</h3>
                <p>JDBC allows you to execute different types of SQL queries, including SELECT, INSERT, UPDATE, and DELETE.</p>

                <h4>Executing an INSERT Query:</h4>
                <CodeBlock code={insertQueryCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>executeUpdate():</strong> The <code>executeUpdate()</code> method is used for executing SQL statements that change the database, such as INSERT, UPDATE, or DELETE. It returns the number of rows affected by the query.</li>
                </ul>

                <h3>1.5. Real-World Application</h3>
                <p>
                    JDBC is commonly used in enterprise applications for interacting with relational databases. It provides a low-level API that allows developers to execute SQL queries directly from Java code. For example, a Java web application might use JDBC to query a MySQL database for user information, process the data, and display it on a web page.
                </p>

                <hr className="section-divider" />

                <h2>2. Introduction to RESTful Web Services in Java (using Spring Boot)</h2>

                <h3>2.1. Overview</h3>
                <p>
                    RESTful web services allow Java applications to communicate with other systems over HTTP, making them an essential part of modern web applications. Spring Boot simplifies the process of creating RESTful web services by providing a framework that handles much of the boilerplate code for you.
                </p>

                <ul>
                    <li><strong>REST (Representational State Transfer):</strong> A style of architecture that uses standard HTTP methods (GET, POST, PUT, DELETE) for communication.</li>
                    <li><strong>Spring Boot:</strong> A framework that simplifies the development of Spring applications by providing a set of conventions and tools that minimize configuration.</li>
                </ul>

                <h3>2.2. Setting Up a Spring Boot Project</h3>
                <p>
                    To create a Spring Boot project, you can use Spring Initializr, a web-based tool that generates a project with the necessary dependencies.
                </p>

                <h4>Setting Up with Spring Initializr:</h4>
                <ul>
                    <li>Go to <a href="https://start.spring.io/" target="_blank" rel="noopener noreferrer">Spring Initializr</a>.</li>
                    <li>Select "Maven Project" or "Gradle Project" and choose "Java" as the language.</li>
                    <li>Add the "Spring Web" dependency.</li>
                    <li>Generate the project and download the ZIP file.</li>
                </ul>

                <h4>Setting Up with Maven:</h4>
                <CodeBlock code={springBootMavenCode} />

                <h4>Setting Up with Gradle:</h4>
                <CodeBlock code={springBootGradleCode} />

                <h3>2.3. Creating a RESTful Web Service</h3>
                <p>
                    Spring Boot makes it easy to create RESTful web services by annotating controller classes with <code>@RestController</code> and mapping HTTP requests to methods using <code>@RequestMapping</code> or other request mapping annotations.
                </p>

                <CodeBlock code={springBootRestCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>@SpringBootApplication:</strong> Marks the main class as a Spring Boot application.</li>
                    <li><strong>@RestController:</strong> Indicates that the class will handle HTTP requests and return JSON responses.</li>
                    <li><strong>@RequestMapping:</strong> Maps the base URL <code>/api</code> to the <code>UserController</code>.</li>

                </ul>

                <h3>2.4. Running the Spring Boot Application</h3>
                <p>
                    <strong>Run the Application:</strong> You can run the Spring Boot application using your IDE or by running <code>mvn spring-boot:run</code> or <code>gradle bootRun</code> in the terminal.
                </p>
                <p>
                    <strong>Accessing the REST API:</strong> Once the application is running, you can access the REST API by navigating to <code>http://localhost:8080/api/users/1</code> in your browser or using a tool like Postman.
                </p>

                <h3>2.5. Real-World Application</h3>
                <p>
                    RESTful web services are widely used in modern web applications to expose APIs that can be consumed by client applications, such as web browsers, mobile apps, or other servers. For example, an e-commerce application might expose RESTful APIs for managing products, processing orders, and handling customer accounts. Spring Boot's simplicity and convention-over-configuration approach make it a popular choice for developing these services.
                </p>

                <hr className="section-divider" />

                <h2>3. Deploying Java Applications on Servers</h2>

                <h3>3.1. Overview</h3>
                <p>
                    Once a Java application is built and tested, it needs to be deployed to a server where it can be accessed by users. Deployment involves copying the packaged application (JAR or WAR) to the server and configuring the server to run the application.
                </p>

                <ul>
                    <li><strong>Application Server:</strong> A server that provides an environment for running Java EE applications, such as Apache Tomcat, WildFly, or GlassFish.</li>
                    <li><strong>Web Server:</strong> A server that serves web pages and can forward requests to an application server. Examples include Apache HTTP Server and Nginx.</li>
                </ul>

                <h3>3.2. Deploying a JAR File</h3>
                <p>
                    If your application is packaged as a JAR file (e.g., a Spring Boot application), you can deploy it directly on the server.
                </p>

                <h4>Steps to Deploy a JAR File:</h4>
                <ul>
                    <li><strong>Transfer the JAR File:</strong> Copy the JAR file to the server using a tool like SCP, SFTP, or a cloud-based service.</li>
                    <li><strong>Run the Application:</strong> Use the command <code>java -jar my-app.jar</code> to start the application.</li>
                </ul>

                <CodeBlock code={runJarCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li>This command runs the JAR file, starting the Spring Boot application. The application will be accessible at <code>http://localhost:8080</code> by default.</li>
                </ul>

                <h3>3.3. Deploying a WAR File</h3>
                <p>
                    If your application is packaged as a WAR file, you need to deploy it to an application server like Apache Tomcat.
                </p>

                <h4>Steps to Deploy a WAR File:</h4>
                <ul>
                    <li><strong>Transfer the WAR File:</strong> Copy the WAR file to the <code>webapps</code> directory of your Tomcat server.</li>
                    <li><strong>Restart the Server:</strong> Restart Tomcat to deploy the WAR file. Tomcat will automatically unpack the WAR file and start the application.</li>
                </ul>

                <CodeBlock code={copyWarCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li>This command copies the WAR file to the <code>webapps</code> directory of Tomcat, where it will be automatically deployed.</li>
                </ul>

                <h3>3.4. Configuring the Server</h3>
                <p>
                    Server configuration may involve setting environment variables, adjusting server memory settings, or configuring the server to run as a service.
                </p>

                <h4>Setting Environment Variables:</h4>
                <CodeBlock code={environmentVariablesCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>JAVA_HOME:</strong> Specifies the location of the JDK.</li>
                    <li><strong>CATALINA_HOME:</strong> Specifies the location of the Tomcat installation.</li>
                </ul>

                <h3>3.5. Real-World Application</h3>
                <p>
                    Deploying Java applications on servers is a critical step in making them available to users. For instance, a Spring Boot application might be deployed as a JAR file on a cloud server (e.g., AWS EC2) to provide RESTful web services. Similarly, a traditional Java web application could be deployed as a WAR file on an on-premises Tomcat server to handle internal business processes. Understanding the deployment process and how to configure servers is essential for ensuring that applications run smoothly in production environments.
                </p>

                <hr className="section-divider" />

                <h2>Conclusion</h2>
                <p>
                    Integration and deployment are vital aspects of Java application development that ensure your applications are capable of interacting with external systems and are accessible to users. By mastering JDBC, you can connect Java applications to relational databases and perform various database operations. With Spring Boot, you can quickly create and expose RESTful web services that are easily consumed by other applications. Finally, understanding how to deploy Java applications on servers enables you to bring your applications into production environments where they can be used by end-users.
                </p>

                <ul>
                    <li><strong>JDBC for Database Connectivity:</strong> Provides a direct way to interact with relational databases from Java code, executing queries and processing results.</li>
                    <li><strong>RESTful Web Services with Spring Boot:</strong> Simplifies the creation of RESTful APIs, making it easier to build and deploy web services.</li>
                    <li><strong>Deploying Java Applications:</strong> Involves packaging applications as JAR or WAR files and deploying them on servers, ensuring that they are accessible to users.</li>
                </ul>

                <p>
                    By incorporating these practices into your development process, you can build and deploy robust, scalable Java applications that meet the demands of modern software environments. Whether you're developing enterprise applications, web services, or desktop applications, these tools and techniques will help you deliver high-quality software that is ready for integration and deployment.
                </p>

            </div>
        </div>
    );
};

export default IntegrationAndDeploymentGuide;
