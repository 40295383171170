import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../components/config';
import { FaChevronLeft } from 'react-icons/fa';
import './Quiz.css'; 
import { Alert } from '@mui/material';

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Quiz({ userToken }) {
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showAnswer, setShowAnswer] = useState(false);
    const [score, setScore] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);
    const [quizPart, setQuizPart] = useState('multiple-choice');
    const [essayTopic, setEssayTopic] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [essayContent, setEssayContent] = useState('');
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };


    const essayQuestions = [
        {
          topic: "Case Study (History): Cuban Missile Crisis",
          details: "In 1962, during the Cuban Missile Crisis, President John F. Kennedy was faced with a critical decision: how to respond to the Soviet Union's placement of nuclear missiles in Cuba. The options included a diplomatic approach, a naval blockade, or a full-scale military invasion. Each choice carried significant risks and potential global consequences.",
          question: "Analyze Kennedy's decision-making process during this crisis. What factors do you think influenced his ultimate choice, and how did his decision impact international relations in the following decades?"
        },
        {
          topic: "Case Study (Business): Employee Retention",
          details: "A mid-sized tech company, InnovateTech, has been struggling with employee retention. Despite competitive salaries, they've lost several key team members to larger corporations. Exit interviews reveal that employees feel undervalued and see limited growth opportunities. The company's CEO wants to address this issue without significantly increasing the budget.",
          question: "Develop a comprehensive strategy to improve employee retention at InnovateTech. Consider both monetary and non-monetary incentives, and explain how your proposed solutions would address the specific concerns raised by departing employees."
        },
        {
          topic: "Case Study (Nursing): Diabetic Patient Care",
          details: "Sarah, a 68-year-old patient with type 2 diabetes, has been admitted to the hospital with a foot ulcer that's not healing properly. She's been managing her diabetes for 15 years but has recently struggled with maintaining her blood sugar levels. Sarah lives alone and has expressed frustration with her declining health and difficulty in following her treatment plan.",
          question: "As Sarah's nurse, outline a comprehensive care plan to address her immediate health concerns and long-term management of her condition. How would you approach patient education and support to improve Sarah's adherence to her treatment plan and overall quality of life?"
        },
        {
          topic: "The Role of Metaphor in Poetry",
          details: "",
          question: "Discuss the role of metaphor in poetry, using examples from at least three different poets from diverse cultural backgrounds. How do these poets use metaphor to convey complex emotions, ideas, or cultural experiences that might be difficult to express literally?"
        },
        {
          topic: "Evolution of the English Language",
          details: "",
          question: "Analyze the evolution of the English language from Old English to Modern English. Discuss major historical events and cultural influences that have shaped the language, and provide specific examples of how vocabulary, grammar, and pronunciation have changed over time."
        }
    ];


    useEffect(() => {     
        const fetchedQuestions = shuffleArray([
            {
                question: "Which word is a synonym for 'beautiful'?",
                options: ["Ugly", "Pretty", "Dirty", "Old"],
                correctAnswer: 1 
            },
            {
                question: "Complete the sentence: 'She _____ to school every day.'",
                options: ["go", "goes", "going", "gone"],
                correctAnswer: 1
            },
            {
                question: "Which sentence is correct?",
                options: ["The book is belonging to me.", "The book belongs to me.", "The book belong to me.", "The book is belong to me."],
                correctAnswer: 1
            },
            {
                question: "What is the plural of 'child'?",
                options: ["Childs", "Childen", "Children", "Childrens"],
                correctAnswer: 2
            },
            {
                question: "Choose the correct spelling:",
                options: ["Recieve", "Receive", "Receeve", "Receve"],
                correctAnswer: 1
            },
            {
                question: "Which word means 'a place where you can borrow books'?",
                options: ["Bookstore", "Library", "School", "Office"],
                correctAnswer: 1
            },
            {
                question: "What is the past tense of 'eat'?",
                options: ["Eated", "Ate", "Eaten", "Eating"],
                correctAnswer: 1
            },
            {
                question: "Choose the correct sentence:",
                options: ["I am going to the market for buy vegetables.", "I am going to the market to buying vegetables.", "I am going to the market to buy vegetables.", "I am going to the market for buying vegetables."],
                correctAnswer: 2
            },
            {
                question: "Which word is an adverb?",
                options: ["Quick", "Quicker", "Quickly", "Quickest"],
                correctAnswer: 2
            },
            {
                question: "What does the idiom 'It's raining cats and dogs' mean?",
                options: ["Cats and dogs are falling from the sky", "It's raining very heavily", "It's a good day for pets", "The weather is unpredictable"],
                correctAnswer: 1
            },
            {
                question: "Choose the correct word to complete the sentence: 'The cake smells _____.'",
                options: ["good", "well", "goodly", "nicely"],
                correctAnswer: 0
            },
            {
                question: "Which sentence uses the correct article?",
                options: ["I saw an unicorn in the forest.", "I saw a unicorn in the forest.", "I saw the unicorn in the forest.", "I saw unicorn in the forest."],
                correctAnswer: 1
            },
            {
                question: "What is the comparative form of 'far'?",
                options: ["Farer", "More far", "Farther", "Most far"],
                correctAnswer: 2
            },
            {
                question: "Choose the correct sentence:",
                options: ["The man which I met yesterday is my uncle.", "The man who I met yesterday is my uncle.", "The man what I met yesterday is my uncle.", "The man whom I met yesterday is my uncle."],
                correctAnswer: 1
            },
            {
                question: "What does the phrase 'break a leg' mean?",
                options: ["Injure yourself", "Good luck", "Run away", "Take a break"],
                correctAnswer: 1
            },
            {
                question: "Which word is NOT a preposition?",
                options: ["Under", "Behind", "Quickly", "Between"],
                correctAnswer: 2
            },
            {
                question: "Choose the correct form of the verb: 'If I _____ rich, I would buy a big house.'",
                options: ["am", "was", "were", "be"],
                correctAnswer: 2
            },
            {
                question: "What is the correct order of adjectives in this sentence? 'She bought a _____ dress.'",
                options: ["new beautiful red silk", "beautiful red new silk", "silk new red beautiful", "red silk beautiful new"],
                correctAnswer: 0
            },
            {
                question: "Which sentence uses the correct punctuation?",
                options: ["'Where are you going?' She asked.", "'Where are you going,' she asked.", "'Where are you going'? She asked.", "'Where are you going?' she asked."],
                correctAnswer: 3
            },
            {
                question: "What is the meaning of the prefix 'un-' in the word 'unhappy'?",
                options: ["Again", "Before", "Not", "Very"],
                correctAnswer: 2
            },
            {
                question: "Choose the correct sentence:",
                options: ["I have been to Nairobi last year.", "I had been to Nairobi last year.", "I went to Nairobi last year.", "I have gone to Nairobi last year."],
                correctAnswer: 2
            },
            {
                question: "Which word is a conjunction?",
                options: ["Quickly", "Under", "Because", "Happy"],
                correctAnswer: 2
            },
            {
                question: "What is the correct passive voice for the sentence 'The chef is cooking dinner'?",
                options: ["Dinner is cooked by the chef.", "Dinner is being cooked by the chef.", "Dinner was being cooked by the chef.", "Dinner has been cooked by the chef."],
                correctAnswer: 1
            }
        ]);
        setQuestions(fetchedQuestions);
    }, [userToken]);

    const handleOptionSelect = (index) => {
        setSelectedOption(index);
        if (questions[currentQuestion].correctAnswer === index) {
            setScore(score + 1);
        }
        setShowAnswer(true);
        setTimeout(() => {
            setShowAnswer(false);
            if (currentQuestion < questions.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
                setSelectedOption(null);
            } else {
                setIsCompleted(true);
            }
        }, 1000); // 1 second delay to show feedback
    };

    const submitQuizResult = async () => {
        try {
            console.log('useToken: ', userToken);
            const response = await axios.post(`${API_BASE_URL}/api/training/complete-test`, {}, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            console.log(response.data.message);
            alert("You have successfully completed training. You can now proceed and bid on tasks.");
            navigate('/tasks');
        } catch (error) {
            console.error('Error submitting quiz result:', error);
        }
    };

    const handleEssayTopicChange = (event) => {
        setEssayTopic(event.target.value);
      };

    return (
        <div className="quiz-container">
          <div className="quiz-header">
            <h1>Quiz</h1>
          </div>
          {quizPart === 'multiple-choice' && !isCompleted ? (
            <div className="question-block">
              <h2>Question {currentQuestion + 1}</h2>
              <p>{questions[currentQuestion]?.question}</p>
              <div>
                {questions[currentQuestion]?.options.map((option, index) => (
                  <label key={index} className="option">
                    <input
                      type="radio"
                      name="option"
                      checked={selectedOption === index}
                      onChange={() => handleOptionSelect(index)}
                    />
                    {option}
                  </label>
                ))}
              </div>
              {showAnswer && (
                <div className="feedback">
                  {questions[currentQuestion].correctAnswer === selectedOption ? 'Correct!' : 'Incorrect!'}
                </div>
              )}
            </div>
          ) : quizPart === 'essay' ? (
            <div className="question-block">
              <h2>Essay Part</h2>
              <div>
                <label htmlFor="essay-topic">Select Essay Topic:</label>
                <select
                  id="essay-topic"
                  value={essayTopic}
                  onChange={handleEssayTopicChange}
                >
                  <option value="">Choose a topic</option>
                  {essayQuestions.map((question, index) => (
                    <option key={index} value={question.topic}>
                      {question.topic}
                    </option>
                  ))}
                </select>
              </div>
              {essayTopic && (
                <div className="essay-question">
                  <h3>{essayTopic}</h3>
                  <p>{essayQuestions.find(q => q.topic === essayTopic).details}</p>
                  <p><strong>Question:</strong> {essayQuestions.find(q => q.topic === essayTopic).question}</p>
                </div>
              )}
              <Alert severity="info" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                Please submit your completed essay to admin@fitfreelance.co.ke. Ensure your essay is at least 2 pages long and addresses all aspects of the chosen question.
                <br></br>
                You'll get verified within 3-5 business days after submitting the essay.
              </Alert>
              <label className="text-gray-400">
              <a href="/essay-instructions" className="text-teal-400 underline ml-1">
                Read the instructuions on how to write the essay.
              </a>
            </label>
              <button
                onClick={submitQuizResult}
                disabled={!essayTopic || essayContent.length < 500}
              >
                Submit Quiz
              </button>
            </div>
          ) : (
            <div className="question-block">
              <h2>Quiz Completed!</h2>
              <p className="score">Your Score: {score} / {questions.length}</p>
              <button onClick={() => setQuizPart('essay')}>
                Proceed to Essay Part
              </button>
            </div>
          )}
          <div className="navigation-icons">
            <button className="nav-icon right" onClick={handleBack}>
              <FaChevronLeft />
            </button>
          </div>
        </div>
    );
}
    
export default Quiz;