// src/pages/ExceptionHandling.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';


const ExceptionHandling = () => {
    const tryCatchCode = `
public class TryCatchExample {
    public static void main(String[] args) {
        try {
            int[] numbers = {1, 2, 3};
            System.out.println("Accessing element at index 5: " + numbers[5]); // This will throw an ArrayIndexOutOfBoundsException
        } catch (ArrayIndexOutOfBoundsException e) {
            System.out.println("Exception caught: " + e);
        }
        System.out.println("Program continues after the catch block.");
    }
}
`;

    const finallyCode = `
import java.io.*;

public class FinallyExample {
    public static void main(String[] args) {
        FileReader file = null;
        try {
            file = new FileReader("example.txt");
            BufferedReader fileInput = new BufferedReader(file);

            // Reading file line by line
            for (int i = 0; i < 3; i++) {
                System.out.println(fileInput.readLine());
            }
        } catch (IOException e) {
            System.out.println("Exception caught: " + e);
        } finally {
            // Ensuring the file is closed
            try {
                if (file != null) {
                    file.close();
                }
            } catch (IOException e) {
                System.out.println("Error closing the file.");
            }
            System.out.println("Finally block executed.");
        }
    }
}
`;

    const throwThrowsCode = `
public class ThrowThrowsExample {
    public static void main(String[] args) {
        try {
            validateAge(15); // This will throw an exception
        } catch (Exception e) {
            System.out.println("Exception caught: " + e.getMessage());
        }
    }

    static void validateAge(int age) throws Exception {
        if (age < 18) {
            throw new Exception("Age must be at least 18.");
        } else {
            System.out.println("Age is valid.");
        }
    }
}
`;

    const customExceptionCode = `
class InvalidAgeException extends Exception {
    public InvalidAgeException(String message) {
        super(message);
    }
}

public class CustomExceptionExample {
    public static void main(String[] args) {
        try {
            validateAge(15); // This will throw an InvalidAgeException
        } catch (InvalidAgeException e) {
            System.out.println("Exception caught: " + e.getMessage());
        }
    }

    static void validateAge(int age) throws InvalidAgeException {
        if (age < 18) {
            throw new InvalidAgeException("Invalid age: Age must be at least 18.");
        } else {
            System.out.println("Age is valid.");
        }
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Exception Handling in Java: A Comprehensive Guide</h1>
                <p>
                    Exception handling is a critical aspect of robust software development. It allows developers to anticipate and manage potential errors, ensuring that applications run smoothly even in the face of unexpected issues. In Java, exception handling is implemented using try-catch blocks, finally, throw, throws, and custom exceptions. This guide will explore these concepts in detail, providing examples and real-world applications to help you master exception handling in Java.
                </p>

                <hr className="section-divider" />

                <h2>1. Try-Catch Blocks</h2>
                <h3>Understanding Try-Catch</h3>
                <p>
                    The try-catch block is the cornerstone of exception handling in Java. It allows you to define a block of code (inside the try block) that might throw an exception and then handle that exception in the catch block. If an exception occurs within the try block, the control is immediately transferred to the corresponding catch block.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={tryCatchCode} />

                <p><strong>Real-World Application:</strong> In a file processing application, try-catch blocks are often used to handle potential file I/O errors. For example, if the application tries to read a file that doesn't exist, a FileNotFoundException can be caught and handled gracefully, perhaps by prompting the user to select a different file.</p>

                <hr className="section-divider" />

                <h2>2. Finally and Throw/Throws</h2>
                <h3>Finally Block</h3>
                <p>
                    The finally block is used to execute code that must run regardless of whether an exception is thrown or not. This is particularly useful for cleaning up resources, such as closing files or releasing database connections.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={finallyCode} />

                <h3>Throw and Throws</h3>
                <p>
                    <strong>Throw:</strong> The throw keyword is used to explicitly throw an exception from a method or a block of code. This is useful when you want to create custom exceptions or propagate exceptions to be handled at a higher level.<br />
                    <strong>Throws:</strong> The throws keyword is used in a method declaration to specify that the method can throw certain exceptions, which must be handled by the caller.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={throwThrowsCode} />

                <p><strong>Real-World Application:</strong> The finally block is commonly used in database applications to ensure that database connections are closed after operations are completed, regardless of whether those operations succeed or fail. The throw and throws keywords are used in scenarios where certain conditions are not met (e.g., invalid input), allowing the program to handle those situations appropriately.</p>

                <hr className="section-divider" />

                <h2>3. Creating Custom Exceptions</h2>
                <h3>Why Create Custom Exceptions?</h3>
                <p>
                    Custom exceptions allow you to create meaningful exception classes that are specific to your application’s domain. This enhances code readability and helps in debugging by providing more context-specific error messages.
                </p>

                <h3>How to Create a Custom Exception</h3>
                <p>
                    To create a custom exception, extend the Exception class (for checked exceptions) or the RuntimeException class (for unchecked exceptions).
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={customExceptionCode} />

                <p><strong>Real-World Application:</strong> Custom exceptions are particularly useful in business applications. For example, in an e-commerce platform, you might create exceptions like InsufficientStockException or PaymentFailedException to handle specific error scenarios related to inventory management or payment processing.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Exception handling is an essential skill for any Java developer. By effectively using try-catch blocks, finally, throw, throws, and custom exceptions, you can write more reliable and maintainable code. Exception handling not only helps in managing errors but also improves the user experience by preventing abrupt program terminations and providing meaningful error messages.</p>

                <p>
                    In real-world applications, these techniques are crucial for handling various error conditions gracefully, from file I/O and database operations to business logic validation and user input handling. Mastering these concepts will enable you to build robust, production-quality Java applications that can withstand unexpected scenarios.
                </p>

                <NextButton nextPage="/basic-io" />
            </div>
        </div>
    );
};

export default ExceptionHandling;
