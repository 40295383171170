import React, { useEffect } from 'react';
import './PlagiarismChecker.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function PlagiarismChecker() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/google-scholar');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="plagiarism-checker">
            <header className="header">
                <h1>Mastering Turnitin</h1>
                <p className="subtitle">Your Guide to Plagiarism Detection and Academic Integrity</p>
            </header>

            <section className="conitent">
                <div className="section-block">
                    <h2>Introduction to Turnitin</h2>
                    <p>Turnitin is a powerful tool for plagiarism detection and academic integrity. This guide will walk you through its features, benefits, and best practices. Real-life examples are included to help you understand how to effectively use Turnitin.</p>
                </div>

                <div className="section-block">
                    <h2>What is Turnitin?</h2>
                    <p>Turnitin is an online platform designed to promote originality in student work. It checks submitted documents against a vast database to identify similarities and potential plagiarism. Both educators and students need to understand its functionality.</p>
                </div>

                <div className="section-block">
                    <h2>How Turnitin Works</h2>
                    <p>Turnitin uses advanced algorithms to compare submitted papers against its extensive database, including academic journals, web pages, and student papers. The system generates a similarity report that highlights matched text and provides a percentage of originality.</p>
                </div>

                <div className="section-block">
                    <h2>Using Turnitin</h2>
                    <p>To use Turnitin, you must first create an account. Register with your institution's code and personal information. Once registered, you can easily submit assignments and access your plagiarism reports.</p>
                </div>

                <div className="section-block">
                    <h2>Submitting Papers</h2>
                    <p>Submitting papers to Turnitin is straightforward. You can upload documents in various formats, including PDF, Word, and text files. Ensure your submission adheres to your institution's guidelines to avoid issues.</p>
                </div>

                <div className="section-block">
                    <h2>Understanding the Similarity Report</h2>
                    <p>The similarity report generated by Turnitin provides insights into potential plagiarism. It highlights matched text and sources, helping you understand where improvements are needed. Familiarize yourself with interpreting these reports to enhance your writing.</p>
                </div>

                <div className="section-block">
                    <h2>Real-Life Example: Student Submission</h2>
                    <p>Consider a student who submits an essay through Turnitin. The similarity report reveals high matches with published articles. This prompts the student to revise and properly cite sources, improving their academic integrity.</p>
                </div>

                <div className="section-block">
                    <h2>Best Practices for Educators</h2>
                    <p>Educators should encourage students to use Turnitin proactively. By integrating it into the writing process, students can learn about proper citation and originality. Regular feedback on similarity reports can foster a culture of academic honesty.</p>
                </div>

                <div className="section-block">
                    <h2>Common Misconceptions</h2>
                    <p>Many believe Turnitin is solely a plagiarism checker. However, it also serves as a valuable educational tool. Understanding how to leverage its features can enhance the learning experience and promote better writing practices.</p>
                </div>

                <div className="section-block">
                    <h2>Turnitin for Research</h2>
                    <p>Researchers can utilize Turnitin to ensure their work is original before publication. By checking drafts against existing literature, they can avoid unintentional plagiarism and maintain the integrity of their research.</p>
                </div>

                <div className="section-block">
                    <h2>Conclusion: Mastering Turnitin</h2>
                    <p>Mastering Turnitin is essential for maintaining academic integrity. By understanding its features and utilizing it effectively, both students and educators can enhance the quality of academic work. Embrace Turnitin as a tool for learning and growth.</p>
                </div>
            </section>

            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default PlagiarismChecker;
