import React, { useEffect } from 'react';
import './ResearchProcess.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function ResearchProcess() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/apa-style');
    };

    return (
        <div className="research-process-page">
            <header className="header">
                <h1>Research Process and Academic Writing Journey</h1>
            </header>

            <section className="conitent">
                <div className="section-block">
                    <h2>Introduction</h2>
                    <p>
                        Academic writing is the art of expressing complex ideas with precision, evidence, and clarity. This tutorial will guide you through the essential steps in the research process and academic writing journey, helping you to master the skills needed for academic success.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Research Process: Four-Pointer Short Steps</h2>
                    <ol>
                        <li>Understand the Question: Choose manageable topics and engage with the question.</li>
                        <li>Gather Materials: Collect relevant materials to support your research.</li>
                        <li>Extract and Support Points: Aim for one main point per paragraph and link each point to class readings.</li>
                        <li>Write and Submit: Structure your paper according to the guidelines and submit it for review.</li>
                    </ol>
                    <p>
                        The first approach to any research process is understanding the question. Despite the availability of data and information on the internet, it's essential to select questions that you can comfortably handle. Engaging with the question and forming solid points to directly answer it is crucial for success.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Academic Writing</h2>
                    <p>
                        Academic writing involves crafting an introduction that captures attention and sets the stage for your argument. The introduction should be broad at the beginning, then narrow down to the specific topic, and include a thesis statement that outlines your opinion, reasoning, and potential counterarguments.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Elements of a Paragraph</h2>
                    <ul>
                        <li>Body paragraphs support the thesis and focus on a single aspect.</li>
                        <li>Topic sentences serve as micro-theses for each paragraph.</li>
                        <li>Elaborate on the topic sentence with evidence and citations.</li>
                        <li>Cover one idea per paragraph, supported by class sources.</li>
                        <li>Follow a five-part structure: topic, explanation, evidence, analysis, conclusion.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>Writing Conclusion</h2>
                    <p>
                        The conclusion summarizes the main points and reinforces the key argument. It should answer the essay question, referring back to the introduction, and avoid introducing new information. Use the introduction as a framework for writing the conclusion, ensuring it includes key words from the question, especially in the final sentence.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Further Reading</h2>
                    <ul>
                        <li><a href="https://www.uvocorp.com/freelancewriting/paragraph-structure-in-academic-writing" target="_blank" rel="noopener noreferrer">Paragraph Structure in Academic Writing</a></li>
                        <li><a href="https://www.barnsley.ac.uk/shops-services/higher-education-library/study-skills/constructing-paragraphs/" target="_blank" rel="noopener noreferrer">Constructing Paragraphs</a></li>
                        <li><a href="https://www.newcastle.edu.au/__data/assets/pdf_file/0009/333765/LD-Conclusions-LH.pdf" target="_blank" rel="noopener noreferrer">Writing Conclusions: How to End Your Paper Smartly</a></li>
                    </ul>
                </div>
            </section>


            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default ResearchProcess;
