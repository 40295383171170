import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const AdminRegister = () => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [adminSecret, setAdminSecret] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    const adminData = {
      email,
      fullName,
      password,
      adminSecret
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(adminData),
      });
      const result = await response.json();
      if (response.ok) {
        setMessage(result.message || "Admin registration successful!");
        setTimeout(() => navigate('/admin/login'), 2000);
      } else {
        setMessage(result.error || "Admin registration failed");
      }
    } catch (error) {
      console.error('Registration error:', error);
      setMessage("An error occurred during admin registration");
    }
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="bg-gray-900 p-8 rounded-lg shadow-md max-w-lg mx-auto">
        <h2 className="text-2xl font-bold mb-6 text-gray-100 text-center">Admin Registration</h2>
        <form onSubmit={handleRegister} className="space-y-4">
          <input
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="w-full p-3 border rounded bg-gray-900"
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border rounded bg-gray-900"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 border rounded bg-gray-900"
            required
          />
          <input
            type="password"
            placeholder="Admin Secret"
            value={adminSecret}
            onChange={(e) => setAdminSecret(e.target.value)}
            className="w-full p-3 border rounded bg-gray-900"
            required
          />
          <button
            type="submit"
            className="w-full bg-orange-500 text-white font-bold py-3 px-6 rounded-full hover:bg-orange-600 transition duration-300"
          >
            Register as Admin
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
};

export default AdminRegister;