// src/pages/Java8Features.js
import React from 'react';
import CodeBlock from '../components/CodeBlock';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';

const Java8Features = () => {
    const lambdaCode = `
        // Traditional way using an anonymous class
        Runnable traditionalRunnable = new Runnable() {
            @Override
            public void run() {
                System.out.println("Hello from traditional Runnable!");
            }
        };

        // Using a lambda expression
        Runnable lambdaRunnable = () -> System.out.println("Hello from Lambda Runnable!");

        public class LambdaExample {
            public static void main(String[] args) {
                traditionalRunnable.run();
                lambdaRunnable.run();
            }
        }
    `;

    const streamsCode = `
        import java.util.Arrays;
        import java.util.List;
        import java.util.stream.Collectors;

        public class StreamsExample {
            public static void main(String[] args) {
                List<String> names = Arrays.asList("Alice", "Bob", "Charlie", "David", "Edward");

                // Filtering and mapping using streams
                List<String> filteredNames = names.stream()
                    .filter(name -> name.startsWith("A") || name.startsWith("E"))
                    .map(String::toUpperCase)
                    .collect(Collectors.toList());

                System.out.println(filteredNames); // Output: [ALICE, EDWARD]
            }
        }
    `;

    const optionalCode = `
        import java.util.Optional;

        public class OptionalExample {
            public static void main(String[] args) {
                String name = "Alice";
                Optional<String> optionalName = Optional.ofNullable(name);

                // Checking if a value is present
                if (optionalName.isPresent()) {
                    System.out.println("Name is present: " + optionalName.get());
                } else {
                    System.out.println("Name is not present");
                }

                // Providing a default value
                String defaultName = optionalName.orElse("Unknown");
                System.out.println("Default Name: " + defaultName);
            }
        }
    `;

    const dateTimeCode = `
        import java.time.LocalDate;
        import java.time.LocalTime;
        import java.time.LocalDateTime;

        public class DateTimeExample {
            public static void main(String[] args) {
                // Working with LocalDate
                LocalDate today = LocalDate.now();
                LocalDate specificDate = LocalDate.of(2023, 9, 15);

                System.out.println("Today: " + today); // Output: Today: 2023-09-15
                System.out.println("Specific Date: " + specificDate); // Output: Specific Date: 2023-09-15

                // Working with LocalTime
                LocalTime now = LocalTime.now();
                LocalTime specificTime = LocalTime.of(14, 30, 0);

                System.out.println("Current Time: " + now); // Output: Current Time: 14:30:00
                System.out.println("Specific Time: " + specificTime); // Output: Specific Time: 14:30:00

                // Working with LocalDateTime
                LocalDateTime dateTime = LocalDateTime.of(specificDate, specificTime);
                System.out.println("DateTime: " + dateTime); // Output: DateTime: 2023-09-15T14:30:00
            }
        }
    `;

    const zonedDateTimeCode = `
        import java.time.ZonedDateTime;
        import java.time.ZoneId;

        public class ZonedDateTimeExample {
            public static void main(String[] args) {
                ZonedDateTime zonedDateTime = ZonedDateTime.now(ZoneId.of("Europe/Paris"));
                System.out.println("Current DateTime in Paris: " + zonedDateTime);
            }
        }
    `;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Java 8+ Features: A Comprehensive Guide</h1>
                <p>
                    Java 8 introduced a number of significant enhancements to the language that have profoundly influenced how developers write Java code. These features include lambda expressions, the Streams API, the Optional class, and a new Date and Time API. Together, these additions make Java more expressive, concise, and better suited for modern software development practices. This guide will delve into each of these features in detail, providing clear explanations, code examples, and real-world applications.
                </p>

                <hr className="section-divider" />

                <h2>1. Lambda Expressions</h2>

                <h3>1.1. Overview</h3>
                <p>
                    Lambda expressions are a key feature introduced in Java 8 that enable you to treat functionality as a method argument or to define anonymous methods. Essentially, lambda expressions allow for a more concise way to write code, particularly when working with functional interfaces.
                </p>

                <h3>1.2. Syntax of Lambda Expressions</h3>
                <p><strong>Basic Syntax:</strong></p>
                <ul>
                    <li><strong>Parameters:</strong> (parameter1, parameter2) -&gt; {`{ body }`}</li>
                    <li>If there is a single parameter, you can omit parentheses.</li>
                    <li>If the body contains a single expression, you can omit curly braces and the return keyword.</li>
                </ul>

                <CodeBlock code={lambdaCode} />

                <h4>Explanation:</h4>
                <p><strong>Traditional Anonymous Class:</strong> The Runnable interface is implemented using an anonymous class, which can be verbose.</p>
                <p><strong>Lambda Expression:</strong> The same functionality is achieved more concisely with a lambda expression, removing boilerplate code.</p>

                <h3>1.3. Real-World Application</h3>
                <p>
                    Lambda expressions are particularly useful in scenarios involving event handling, list processing, or any situation where functional interfaces are prevalent. For example, in a GUI application, lambda expressions can be used to handle button clicks or other events more concisely.
                </p>

                <hr className="section-divider" />

                <h2>2. Streams API</h2>

                <h3>2.1. Overview</h3>
                <p>
                    The Streams API, introduced in Java 8, is a powerful tool for processing sequences of elements in a functional-style. It allows developers to write more concise and readable code for performing operations such as filtering, mapping, and reducing collections.
                </p>

                <h3>2.2. Creating and Using Streams</h3>

                <CodeBlock code={streamsCode} />

                <h4>Explanation:</h4>
                <p><strong>Filter:</strong> The <code>filter()</code> method is used to select names starting with "A" or "E".</p>
                <p><strong>Map:</strong> The <code>map()</code> method transforms the selected names to uppercase.</p>
                <p><strong>Collect:</strong> The <code>collect()</code> method is a terminal operation that gathers the results into a List.</p>

                <h3>2.3. Parallel Streams</h3>

                <CodeBlock code={`List<String> names = Arrays.asList("Alice", "Bob", "Charlie", "David", "Edward");\n\nList<String> filteredNames = names.parallelStream()\n    .filter(name -> name.startsWith("A") || name.startsWith("E"))\n    .map(String::toUpperCase)\n    .collect(Collectors.toList());`} />

                <h4>Explanation:</h4>
                <p><strong>Parallel Stream:</strong> Processing is done in parallel, potentially speeding up the operation on large datasets.</p>

                <h3>2.4. Real-World Application</h3>
                <p>
                    The Streams API is particularly useful for processing collections of data, such as filtering and transforming lists or arrays. It is commonly used in data processing pipelines, financial calculations, and any scenario where operations can be expressed as a sequence of transformations.
                </p>

                <hr className="section-divider" />

                <h2>3. Optional Class</h2>

                <h3>3.1. Overview</h3>
                <p>
                    The Optional class was introduced in Java 8 as a way to handle potentially null values in a more elegant way. It is intended to prevent NullPointerException by explicitly handling the absence of a value.
                </p>

                <h3>3.2. Creating and Using Optional</h3>

                <CodeBlock code={optionalCode} />

                <h4>Explanation:</h4>
                <p><code>ofNullable()</code>: Creates an Optional that may or may not contain a non-null value.</p>
                <p><code>isPresent()</code>: Checks if the value is present in the Optional.</p>
                <p><code>get()</code>: Retrieves the value if present; otherwise, it throws NoSuchElementException.</p>
                <p><code>orElse()</code>: Provides a fallback value if the Optional is empty.</p>

                <h3>3.3. Chaining Optional Operations</h3>

                <CodeBlock code={`String name = null;\nOptional<String> optionalName = Optional.ofNullable(name);\n\noptionalName\n    .map(String::toUpperCase)\n    .ifPresent(System.out::println); // Does nothing because the value is absent`} />

                <h4>Explanation:</h4>
                <p><code>map()</code>: Applies a function if the value is present.</p>
                <p><code>ifPresent()</code>: Performs an action if a value is present.</p>

                <h3>3.4. Real-World Application</h3>
                <p>
                    Optional is useful in APIs where null values are common and need to be handled gracefully. It is often used in method return types where a value might not always be available, such as finding an entity in a database or processing user input.
                </p>

                <hr className="section-divider" />

                <h2>4. Date and Time API (java.time)</h2>

                <h3>4.1. Overview</h3>
                <p>
                    The Date and Time API, introduced in Java 8, is a modern and comprehensive framework for handling dates, times, and durations. The new java.time package addresses many issues with the old java.util.Date and java.util.Calendar classes, such as mutability and poor API design.
                </p>

                <h3>4.2. Working with LocalDate and LocalTime</h3>

                <CodeBlock code={dateTimeCode} />

                <h4>Explanation:</h4>
                <p><code>LocalDate</code>: Represents a date (year, month, day) without time.</p>
                <p><code>LocalTime</code>: Represents a time (hour, minute, second) without a date.</p>
                <p><code>LocalDateTime</code>: Combines both date and time.</p>

                <h3>4.3. Working with Time Zones</h3>

                <CodeBlock code={zonedDateTimeCode} />

                <h4>Explanation:</h4>
                <p><code>ZonedDateTime</code>: Represents a date and time with a specific time zone, providing a complete representation of time.</p>

                <h3>4.4. Real-World Application</h3>
                <p>
                    The new Date and Time API is widely used in applications that require accurate and immutable date and time representations. It is essential in domains like finance, where precise timekeeping is crucial, and in international applications where time zone management is important.
                </p>

                <hr className="section-divider" />

                <h2>Conclusion</h2>
                <p>
                    Java 8 introduced several powerful features that have transformed the way developers write Java code. By mastering lambda expressions, you can write more concise and expressive code. The Streams API allows you to process collections more efficiently using a functional approach. The Optional class provides a safer way to handle potentially null values, reducing the risk of NullPointerException. Finally, the new Date and Time API offers a modern, robust framework for handling dates, times, and durations, solving many of the issues with the old date-time classes.
                </p>
                <p>
                    Each of these features plays a critical role in making Java more expressive, safer, and better suited for modern development practices. Whether you're developing enterprise applications, APIs, or any other software in Java, these tools will help you write cleaner, more efficient, and more maintainable code.
                </p>
                <NextButton nextPage="/unit-testing-tdd" />
            </div>
        </div>
    );
};

export default Java8Features;
