import React, { useState } from 'react';
import axios from 'axios';
import { FaFileAlt } from 'react-icons/fa';
import { API_BASE_URL } from '../config';
import TaskDescription from './TaskDescription';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { motion } from 'framer-motion';

const RevisionSubmission = ({ task, userToken, onBack }) => {
  const [submissionDetails, setSubmissionDetails] = useState('');
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  // Handle file input changes
  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  // Handle form submission
  const handleSubmission = async () => {
    if (files.length === 0) {
      setSubmissionError('Please upload at least one file.');
      return;
    }

    setSubmitting(true);
    setSubmissionError(null);

    const formData = new FormData();
    formData.append('submissionDetails', submissionDetails);
    files.forEach((file) => {
      formData.append('documents', file);
    });

    try {
      await axios.post(`${API_BASE_URL}/api/users/task/${task.id}/submit-revision`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userToken}`,
        },
      });
      setSubmitting(false);
      onBack(); // Callback to return to the previous screen
    } catch (error) {
      console.error('Error submitting revision:', error);
      setSubmissionError('Failed to submit the revision. Please try again.');
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen p-8">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Alert severity="info" className="mb-8">
          <AlertTitle>Submission Instructions</AlertTitle>
          <ol>
            <li>Upload all the required documents as per the instructions</li>
            <li>Submit Plag and AI Turnitin reports</li>
            <li>Submit your phone number for payment</li>
          </ol>
        </Alert>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Alert severity="error" className="mb-8">
          <AlertTitle>Admin feedback</AlertTitle>
          {task.feedback}
        </Alert>
      </motion.div>
      <button onClick={onBack} className="mb-4 text-teal-400 hover:text-teal-600">
        Back to Profile
      </button>
      <h2 className="text-3xl font-bold mb-4 text-teal-300">{task.title}</h2>
      <TaskDescription description={task.description} />

      {task.documents && task.documents.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-teal-400">Support Documents</h3>
          <ul className="list-disc list-inside pl-4">
            {task.documents.map((doc, index) => (
              <li key={index} className="flex items-center mb-2">
                <FaFileAlt className="mr-2 text-teal-400" />
                <a
                  href={`${API_BASE_URL}/uploads/${doc.fileName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-teal-300 hover:underline"
                >
                  {doc.fileName} ({Math.round(doc.fileSize / 1024)} KB)
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div>
        <h3 className="text-xl font-semibold mb-2">Upload Revision</h3>
        <textarea
          className="w-full p-2 mb-4 bg-gray-800 text-gray-100 rounded"
          rows="4"
          placeholder="Enter revision details..."
          value={submissionDetails}
          onChange={(e) => setSubmissionDetails(e.target.value)}
        />
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="mb-4 text-gray-300"
        />
        {submissionError && <div className="text-red-500 mb-4">{submissionError}</div>}
        <button
          onClick={handleSubmission}
          disabled={submitting}
          className={`bg-teal-400 text-gray-900 font-semibold px-4 py-2 rounded transition-colors ${submitting ? 'bg-teal-500 cursor-not-allowed' : 'hover:bg-teal-500'}`}
        >
          {submitting ? 'Submitting...' : 'Submit Revision'}
        </button>
      </div>
    </div>
  );
};

export default RevisionSubmission;