// src/components/NextButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NextButton = ({ nextPage }) => {
    const navigate = useNavigate();

    return (
        <button className="next-button" onClick={() => navigate(nextPage)}>
            NEXT PAGE
        </button>
    );
};

export default NextButton;
