import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Introduction from './learning-materials2/pages/Introduction';
import OOPBasics from './learning-materials2/pages/OOPBasics';
import ArraysCollections from './learning-materials2/pages/ArraysCollections';
import ExceptionHandling from './learning-materials2/pages/ExceptionHandling';
import BasicIO from './learning-materials2/pages/BasicIO';
import JavaAPIs from './learning-materials2/pages/JavaAPIs';
import AdvancedOOP from './learning-materials2/pages/AdvancedOOP';
import AdvancedDataStructures from './learning-materials2/pages/AdvancedDataStructures';
import Multithreading from './learning-materials2/pages/Multithreading';
import JavaIOSerialization from './learning-materials2/pages/JavaIOSerialization';
import ExceptionHandlingAdvanced from './learning-materials2/pages/ExceptionHandlingAdvanced';
import JavaMemoryManagement from './learning-materials2/pages/JavaMemoryManagement';
import DesignPatterns from './learning-materials2/pages/DesignPatterns';
import JavaNetworking from './learning-materials2/pages/JavaNetworking';
import AdvancedMultithreading from './learning-materials2/pages/AdvancedMultithreading';
import Java8Features from './learning-materials2/pages/Java8Features';
import UnitTestingTDD from './learning-materials2/pages/UnitTestingTDD';
import BuildingJavaApplications from './learning-materials2/pages/BuildingJavaApplications';
import IntegrationDeployment from './learning-materials2/pages/IntegrationDeployment';
import LockableContent from './LockableComponent';

function JavaLearningModule({ userToken, learningStatus }) {
  return (
    <Routes>
      <Route path="introduction" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><Introduction /></LockableContent>} />
      <Route path="oop-basics" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><OOPBasics /></LockableContent>} />
      <Route path="arrays-collections" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><ArraysCollections /></LockableContent>} />
      <Route path="exception-handling" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><ExceptionHandling /></LockableContent>} />
      <Route path="basic-io" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><BasicIO /></LockableContent>} />
      <Route path="java-apis" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><JavaAPIs /></LockableContent>} />
      <Route path="advanced-oop" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><AdvancedOOP /></LockableContent>} />
      <Route path="advanced-data-structures" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><AdvancedDataStructures /></LockableContent>} />
      <Route path="multithreading" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><Multithreading /></LockableContent>} />
      <Route path="java-io-serialization" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><JavaIOSerialization /></LockableContent>} />
      <Route path="exception-handling-advanced" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><ExceptionHandlingAdvanced /></LockableContent>} />
      <Route path="java-memory-management" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><JavaMemoryManagement /></LockableContent>} />
      <Route path="design-patterns" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><DesignPatterns /></LockableContent>} />
      <Route path="java-networking" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><JavaNetworking /></LockableContent>} />
      <Route path="advanced-multithreading" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><AdvancedMultithreading /></LockableContent>} />
      <Route path="java-8-features" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><Java8Features /></LockableContent>} />
      <Route path="unit-testing-tdd" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><UnitTestingTDD /></LockableContent>} />
      <Route path="building-java-applications" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><BuildingJavaApplications /></LockableContent>} />
      <Route path="integration-deployment" element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} ><IntegrationDeployment /></LockableContent>} />
      <Route path="/" element={<Introduction /> } />
    </Routes>
  );
}

export default JavaLearningModule;