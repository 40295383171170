import React, { useEffect } from 'react';
import './ToolsResources.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function ToolsResources() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/quiz');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="tools-resources-page">
            <header className="header">
                <h1>Enhancing Academic Writing: Essential Tools and Resources</h1>
            </header>

            <section className="contient">
                <div className="section-block">
                    <h2>Introduction to Academic Writing</h2>
                    <p>
                        Academic writing is characterized by its formal tone, clarity, and adherence to specific formatting rules. It serves as a means to convey complex ideas in a structured manner, making it essential for students and researchers alike.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Writing Software Tools</h2>
                    <p>
                        Utilizing writing software can streamline your writing process. Tools like Microsoft Word and Google Docs offer features such as spell check, grammar suggestions, and collaborative editing, making them invaluable for academic writers.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Managing References</h2>
                    <p>
                        Managing references is crucial in academic writing. Tools like Zotero and EndNote help you organize, cite, and create bibliographies efficiently, ensuring that your sources are properly credited and easily accessible.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Plagiarism Checkers</h2>
                    <p>
                        To maintain academic integrity, using plagiarism checkers such as Turnitin or Grammarly is essential. These tools help identify unintentional plagiarism and ensure that your work is original and properly attributed.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Grammar and Style Checkers</h2>
                    <p>
                        Tools like Grammarly and ProWritingAid provide real-time feedback on grammar, punctuation, and style. They help improve the readability of your writing by suggesting changes that enhance clarity and coherence.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Online Writing Communities</h2>
                    <p>
                        Engaging with online writing communities such as WritersCafe or Scribophile can provide valuable feedback. These platforms allow you to share your work, receive critiques, and connect with fellow writers for support and motivation.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Research Databases</h2>
                    <p>
                        Access to research databases like JSTOR and Google Scholar is vital for academic writing. These resources provide a wealth of scholarly articles and papers that can enhance your understanding of your topic and support your arguments.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Writing Guides and Tutorials</h2>
                    <p>
                        Numerous writing guides and tutorials are available online, offering tips on structure, style, and formatting. Resources like the Purdue OWL provide comprehensive information to help you refine your academic writing skills.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Effective Time Management</h2>
                    <p>
                        Effective time management is crucial for academic writing success. Tools like Trello and Todoist can help you organize tasks, set deadlines, and maintain a productive writing schedule to meet your academic goals.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Peer Review Process</h2>
                    <p>
                        Participating in the peer review process is an excellent way to improve your writing. Sharing drafts with peers allows you to gain constructive feedback and refine your work before submission, enhancing its quality.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Conclusion and Resources</h2>
                    <p>
                        In conclusion, leveraging the right tools and resources can significantly enhance your academic writing. By incorporating these strategies into your writing process, you can produce well-structured, clear, and impactful academic work.
                    </p>
                </div>
            </section>

            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default ToolsResources;
