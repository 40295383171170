import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './MLAPaper.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function MLAPaper() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/MLAStyleEssay');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="page-content">
            <header className="header">
                <h1>Mastering MLA Style: A Comprehensive Guide with Practical Examples</h1>
            </header>

            <section className="cointent">
                <div className="section-block">
                    <h2>Introduction to MLA Style</h2>
                    <p className="highlighted-text">
                        MLA Style is a standardized format for writing and citing sources in academic work. This guide provides practical examples to enhance your understanding and application of the style.
                    </p>
                </div>

                <div className="section-block">
                    <h2>What is MLA Style?</h2>
                    <p>
                        MLA (Modern Language Association) Style is primarily used in the humanities. It provides a consistent framework for citing sources and formatting papers, ensuring clarity and credibility in academic writing.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Core Components</h2>
                    <p>
                        The essential components of MLA Style include <span className="bold-text">in-text citations</span>, a <span className="bold-text">Works Cited page</span>, and specific formatting rules for fonts, margins, and spacing. Understanding these components is crucial for effective writing.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Formatting Your Paper</h2>
                    <ul>
                        <li>Use 12-point Times New Roman font.</li>
                        <li>Double-space your text.</li>
                        <li>Set 1-inch margins.</li>
                        <li>Include a header with your last name and page number in the upper right corner.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>In-Text Citations</h2>
                    <p>
                        In-text citations in MLA typically include the author's last name and the page number. For example, <span className="important">(Smith 23)</span>. This method allows readers to locate the full citation in the Works Cited page easily.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Works Cited Page</h2>
                    <p>
                        The Works Cited page lists all sources referenced in your paper. Each entry should follow the MLA format, including the author's name, title of the work, publication details, and medium of publication.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Citing Books</h2>
                    <p>
                        When citing books in MLA, include the author's name, title in <span className="italic-text">italics</span>, publisher, and year of publication. For example: <span className="bold-text">Smith, John. <span className="italic-text">Understanding MLA Style</span>. Academic Press, 2020.</span>
                    </p>
                </div>

                <div className="section-block">
                    <h2>Citing Articles</h2>
                    <p>
                        For journal articles, include the author's name, article title in quotation marks, journal title in <span className="italic-text">italics</span>, volume, issue number, year, and page range. For example: <span className="bold-text">Doe, Jane. "Research Methods in MLA." <span className="italic-text">Journal of Writing</span>, vol. 10, no. 2, 2021, pp. 45-60.</span>
                    </p>
                </div>

                <div className="section-block">
                    <h2>Citing Online Sources</h2>
                    <p>
                        When citing online sources, include the author's name, title of the webpage, website name, publication date, and URL. For example: <a href="https://www.writingresources.com/mla" target="_blank" rel="noopener noreferrer">Brown, Lisa. "MLA Style Guide." Writing Resources, 2022, www.writingresources.com/mla.</a>
                    </p>
                </div>

                <div className="section-block">
                    <h2>Common Mistakes</h2>
                    <p>
                        Avoid common MLA mistakes such as incorrect formatting, missing citations, and improper punctuation. Ensure all sources are accurately cited to maintain academic integrity and avoid plagiarism.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Practical Examples</h2>
                    <p>
                        This section provides practical examples of MLA citations for various sources, demonstrating how to apply the rules effectively.
                    </p>
                </div>

                <div className="section-block">
                    <h2>Conclusion</h2>
                    <p>
                        Mastering MLA Style is essential for academic success in the humanities. By understanding its components, practicing citations, and avoiding common pitfalls, you can enhance your writing and research skills significantly.
                    </p>
                </div>
            </section>

            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default MLAPaper;
