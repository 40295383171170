// src/pages/ExceptionHandlingAdvanced.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';


const ExceptionHandlingAdvanced = () => {
    const specificExceptionCode = `
try {
    int result = 10 / 0;
} catch (ArithmeticException e) {
    System.out.println("Cannot divide by zero: " + e.getMessage());
}
`;

    const swallowingExceptionAntiPatternCode = `
try {
    // Some risky operation
} catch (IOException e) {
    // Swallowed exception - Anti-pattern
}
`;

    const correctApproachCode = `
try {
    // Some risky operation
} catch (IOException e) {
    e.printStackTrace(); // Log the exception
    throw new RuntimeException("Failed to execute operation", e); // Re-throw as a runtime exception
}
`;

    const finallyBlockCode = `
FileInputStream fis = null;
try {
    fis = new FileInputStream("example.txt");
    // Perform file operations
} catch (IOException e) {
    e.printStackTrace();
} finally {
    if (fis != null) {
        try {
            fis.close();
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}
`;

    const customExceptionCode = `
class InsufficientFundsException extends Exception {
    public InsufficientFundsException(String message) {
        super(message);
    }
}

class BankAccount {
    private double balance;

    public void withdraw(double amount) throws InsufficientFundsException {
        if (amount > balance) {
            throw new InsufficientFundsException("Insufficient funds for withdrawal");
        }
        balance -= amount;
    }
}
`;

    const chainedExceptionCode = `
class DataAccessException extends Exception {
    public DataAccessException(String message, Throwable cause) {
        super(message, cause);
    }
}

class Database {
    public void connect() throws SQLException {
        // Simulating an SQL Exception
        throw new SQLException("Unable to connect to database");
    }
}

class DataService {
    private Database database = new Database();

    public void fetchData() throws DataAccessException {
        try {
            database.connect();
        } catch (SQLException e) {
            throw new DataAccessException("Failed to fetch data", e); // Chained exception
        }
    }
}

public class ChainedExceptionExample {
    public static void main(String[] args) {
        DataService dataService = new DataService();
        try {
            dataService.fetchData();
        } catch (DataAccessException e) {
            e.printStackTrace();
        }
    }
}
`;

    const getCauseExampleCode = `
public class CauseExample {
    public static void main(String[] args) {
        try {
            throw new DataAccessException("Data access error", new SQLException("SQL error"));
        } catch (DataAccessException e) {
            System.out.println("Cause: " + e.getCause());
        }
    }
}
`;

    const loggingExampleCode = `
import java.io.IOException;
import java.util.logging.Level;
import java.util.logging.Logger;

public class LoggingExample {
    private static final Logger logger = Logger.getLogger(LoggingExample.class.getName());

    public static void main(String[] args) {
        try {
            performRiskyOperation();
        } catch (IOException e) {
            logger.log(Level.SEVERE, "IOException occurred", e);
        }
    }

    public static void performRiskyOperation() throws IOException {
        throw new IOException("Simulated IO exception");
    }
}
`;

    const log4jExampleCode = `
import org.apache.log4j.Logger;
import org.apache.log4j.BasicConfigurator;

public class Log4jExample {
    private static final Logger logger = Logger.getLogger(Log4jExample.class);

    public static void main(String[] args) {
        BasicConfigurator.configure(); // Configures basic settings for Log4j
        
        try {
            performRiskyOperation();
        } catch (IOException e) {
            logger.error("An error occurred", e);
        }
    }

    public static void performRiskyOperation() throws IOException {
        throw new IOException("Simulated IO exception");
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Advanced Exception Handling in Java: A Comprehensive Guide</h1>
                <p>
                    Exception handling is a fundamental aspect of robust software development. It allows developers to manage errors gracefully and ensure that applications behave predictably even in the face of unexpected conditions. Advanced exception handling techniques go beyond the basics of try-catch blocks and delve into best practices, chained exceptions, and logging. This guide will explore these advanced topics in detail, providing examples and best practices to help you write more resilient Java code.
                </p>

                <hr className="section-divider" />

                <h2>1. Best Practices in Exception Handling</h2>

                <h3>1.1. Use Specific Exceptions</h3>
                <p>Always catch the most specific exception possible rather than a general <code>Exception</code>. This makes your code more predictable and easier to debug.</p>

                <h4>Example Code:</h4>
                <CodeBlock code={specificExceptionCode} />

                <h4>Explanation:</h4>
                <p><strong>Specific Exception:</strong> Catching <code>ArithmeticException</code> directly is better than catching <code>Exception</code> because it specifically handles the division by zero scenario.</p>

                <h3>1.2. Avoid Swallowing Exceptions</h3>
                <p>Swallowing exceptions, where an exception is caught but not handled or logged, can make debugging difficult and lead to silent failures.</p>

                <h4>Example Code (Anti-pattern):</h4>
                <CodeBlock code={swallowingExceptionAntiPatternCode} />

                <h4>Correct Approach:</h4>
                <CodeBlock code={correctApproachCode} />

                <h4>Explanation:</h4>
                <p><strong>Logging and Re-throwing:</strong> By logging the exception and re-throwing it as a <code>RuntimeException</code>, the application provides feedback about the failure, making it easier to trace and fix the issue.</p>

                <h3>1.3. Clean Up Resources in a Finally Block</h3>
                <p>Always release resources such as file handles, database connections, or sockets in a <code>finally</code> block to ensure they are closed even if an exception occurs.</p>

                <h4>Example Code:</h4>
                <CodeBlock code={finallyBlockCode} />

                <h4>Explanation:</h4>
                <p><strong>Finally Block:</strong> Ensures that the <code>FileInputStream</code> is closed regardless of whether an exception was thrown or not, preventing resource leaks.</p>

                <h3>1.4. Use Custom Exceptions for Domain-Specific Errors</h3>
                <p>Creating custom exceptions allows you to represent specific error conditions in your domain model, improving the clarity of your code.</p>

                <h4>Example Code:</h4>
                <CodeBlock code={customExceptionCode} />

                <h4>Explanation:</h4>
                <p><strong>Custom Exception:</strong> The <code>InsufficientFundsException</code> clearly indicates a specific problem (insufficient funds), making the code easier to understand and maintain.</p>

                <hr className="section-divider" />

                <h2>2. Chained Exceptions</h2>

                <h3>2.1. Overview</h3>
                <p>Chained exceptions allow you to relate one exception to another, providing more context when an error occurs. This is particularly useful when a method throws a low-level exception that is caught and wrapped in a higher-level exception.</p>

                <h3>2.2. Creating Chained Exceptions</h3>
                <p>When catching an exception, you can wrap it in a new exception and throw the new exception. The original exception is passed as a cause, which can be retrieved later.</p>

                <h4>Example Code:</h4>
                <CodeBlock code={chainedExceptionCode} />

                <h4>Explanation:</h4>
                <p><strong>Chaining:</strong> The <code>DataAccessException</code> wraps the <code>SQLException</code>, preserving the original exception as the cause. This allows you to see both the high-level error (data access failure) and the low-level cause (SQL failure) when the stack trace is printed.</p>

                <h3>2.3. Accessing the Cause</h3>
                <p>You can retrieve the original exception (the cause) from the chained exception using the <code>getCause()</code> method.</p>

                <h4>Example Code:</h4>
                <CodeBlock code={getCauseExampleCode} />

                <h4>Explanation:</h4>
                <p><code>getCause():</code> Allows you to retrieve the underlying cause of the exception, providing more context for debugging.</p>

                <p><strong>Real-World Application:</strong> Chained exceptions are especially useful in layered applications where exceptions may propagate through different layers. For example, in a web application, a low-level database exception might be wrapped in a service-layer exception, which is then wrapped in a controller-layer exception. Chained exceptions allow developers to trace the error back to its origin while preserving context across layers.</p>

                <hr className="section-divider" />

                <h2>3. Logging Exceptions</h2>

                <h3>3.1. Overview</h3>
                <p>Logging exceptions is a critical practice in production systems. It allows developers and system administrators to monitor the health of applications, diagnose issues, and understand the circumstances leading up to failures. Java provides the <code>java.util.logging</code> package for logging, but third-party libraries like Log4j and SLF4J are also widely used.</p>

                <h3>3.2. Using java.util.logging</h3>
                <p>The <code>java.util.logging</code> package provides a flexible framework for logging messages in Java applications.</p>

                <h4>Example Code:</h4>
                <CodeBlock code={loggingExampleCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Logger:</strong> The <code>Logger</code> class is used to log messages at various levels (e.g., INFO, WARNING, SEVERE).</li>
                    <li><strong>Logging an Exception:</strong> The <code>log(Level level, String msg, Throwable thrown)</code> method logs an exception with a custom message, providing both the message and the stack trace.</li>
                </ul>

                <h3>3.3. Configuring Logging</h3>
                <p>You can configure the logging level and output destination (e.g., console, file) using a configuration file or programmatically.</p>

                <h4>Example Configuration (logging.properties):</h4>
                <pre>
                    handlers= java.util.logging.ConsoleHandler, java.util.logging.FileHandler{'\n'}
                    .level= INFO{'\n'}
                    java.util.logging.ConsoleHandler.level = FINE{'\n'}
                    java.util.logging.FileHandler.pattern = %h/java%u.log{'\n'}
                    java.util.logging.FileHandler.append = true{'\n'}
                    java.util.logging.FileHandler.level = INFO{'\n'}
                </pre>

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Console and File Handlers:</strong> This configuration logs messages to both the console and a file.</li>
                    <li><strong>Logging Levels:</strong> Adjusts the level of detail based on the severity of the messages.</li>
                </ul>

                <h3>3.4. Using Log4j for Advanced Logging</h3>
                <p>Log4j is a popular logging framework that provides more features and flexibility than the built-in <code>java.util.logging</code>.</p>

                <h4>Example Code (Using Log4j):</h4>
                <CodeBlock code={log4jExampleCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Log4j Setup:</strong> Log4j provides a more advanced logging setup with customizable appenders (e.g., console, file, network) and layouts (e.g., pattern-based logging).</li>
                    <li><strong>Error Logging:</strong> The <code>logger.error()</code> method logs an error message along with the exception's stack trace.</li>
                </ul>

                <p><strong>Real-World Application:</strong> Logging exceptions is vital in large-scale enterprise applications where issues must be monitored and diagnosed efficiently. In a web application, for instance, exceptions might be logged to a centralized logging system, which aggregates logs from multiple servers. This allows developers and system administrators to detect patterns, analyze the root cause of issues, and take corrective actions before they impact users.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Advanced exception handling in Java involves not only catching and handling exceptions but doing so in a way that makes your code more maintainable, debuggable, and user-friendly. Best practices like using specific exceptions, avoiding swallowing exceptions, and cleaning up resources ensure that your application is robust and predictable. Chained exceptions provide a way to propagate errors with context, making it easier to trace the root cause of an issue. Logging exceptions, whether through Java's built-in logging or third-party frameworks like Log4j, is essential for monitoring and diagnosing issues in production environments.</p>

                <p>By mastering these advanced exception handling techniques, you can write Java applications that are not only resilient to errors but also provide clear and actionable insights when something goes wrong. This is critical for developing reliable, maintainable, and scalable software solutions.</p>

                <NextButton nextPage="/java-memory-management" />
            </div>
        </div>
    );
};

export default ExceptionHandlingAdvanced;
