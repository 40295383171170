import React from 'react';
import { useNavigate } from 'react-router-dom';

const EssayInstructions = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col items-center justify-center p-8">
      <div className="max-w-3xl w-full px-6 py-8 bg-gray-800 rounded-lg shadow-md overflow-y-auto max-h-[80vh]">
        <h1 className="text-3xl font-bold mb-6 text-teal-400 text-center">Advanced Academia Essay Instructions</h1>
        <div className="space-y-6 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold text-teal-400">General Guidelines</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Choose one essay question from the provided list to answer.</li>
              <li>Your essay should be approximately 550-600 words in length.</li>
              <li>Use formal academic language appropriate for university-level writing.</li>
              <li>Adhere to the latest edition of your chosen citation style (APA, MLA, Harvard, or Chicago). Be consistent throughout your essay.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">Essay Structure</h2>
            <p>Your essay must include the following components:</p>
            <ol className="list-decimal list-inside space-y-2">
              <li>
                <strong>Introduction</strong> (approximately 10% of total word count)
                <ul className="list-disc list-inside ml-4 mt-2">
                  <li>Begin with an engaging hook to capture the reader's attention.</li>
                  <li>Provide necessary background information on your topic.</li>
                  <li>End with a clear and concise thesis statement that outlines your main argument or position.</li>
                </ul>
              </li>
              <li>
                <strong>Body</strong> (approximately 75% of total word count)
                <ul className="list-disc list-inside ml-4 mt-2">
                  <li>Divide your argument into three main points, each addressed in a separate paragraph.</li>
                  <li>Each body paragraph should:
                    <ol className="list-[lower-alpha] list-inside ml-4">
                      <li>Begin with a clear topic sentence that introduces the main idea of the paragraph.</li>
                      <li>Provide evidence and examples to support your point.</li>
                      <li>Include at least one in-text citation from a credible academic source.</li>
                      <li>Explain how the evidence supports your thesis.</li>
                      <li>End with a concluding sentence that transitions to the next paragraph.</li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Conclusion</strong> (approximately 15% of total word count)
                <ul className="list-disc list-inside ml-4 mt-2">
                  <li>Restate your thesis using different wording.</li>
                  <li>Summarize the main points discussed in your body paragraphs.</li>
                  <li>Provide a final thought or call to action related to your topic.</li>
                  <li>Do not introduce any new information in the conclusion.</li>
                </ul>
              </li>
            </ol>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">Research and Citations</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Use at least three credible academic sources to support your arguments.</li>
              <li>Credible sources include peer-reviewed journal articles, academic books, and reputable institutional websites.</li>
              <li>Avoid using general websites, blogs, or non-academic sources.</li>
              <li>Include both in-text citations and a references list/bibliography at the end of your essay.</li>
              <li>Format your citations and references according to your chosen citation style.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">Formatting</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Use 12-point Times New Roman or Arial font.</li>
              <li>Set 1-inch margins on all sides.</li>
              <li>Double-space your entire essay, including the references list.</li>
              <li>Include a header with your last name and page number on each page.</li>
              <li>Center your title on the first page, followed by your essay.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">Evaluation Criteria</h2>
            <p>Your essay will be evaluated based on:</p>
            <ol className="list-decimal list-inside space-y-2">
              <li>Clarity and strength of your thesis statement</li>
              <li>Quality and relevance of your arguments and supporting evidence</li>
              <li>Proper use of academic sources and correct citation format</li>
              <li>Coherence and logical flow of ideas</li>
              <li>Grammar, spelling, and punctuation</li>
              <li>Adherence to the required structure and formatting guidelines</li>
            </ol>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-teal-400">Submission</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Submit your essay as a .doc, .docx, or .pdf file.</li>
              <li>Name your file using the following format: LastName_FirstName_EssayTopic.</li>
              <li>Include a cover page with your name, student ID, course name, instructor's name, and submission date.</li>
            </ul>
          </section>

          <p className="italic">Remember to proofread and edit your essay carefully before submission. Consider using your institution's writing center resources for additional support if needed.</p>
        </div>
        <button
          onClick={() => navigate(-1)}
          className="mt-6 w-full p-3 bg-teal-500 text-gray-900 rounded hover:bg-teal-400 transition duration-300"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default EssayInstructions;