import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, applyActionCode, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { motion } from 'framer-motion';

const EmailVerification = () => {
  const [message, setMessage] = useState('Processing your request...');
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  useEffect(() => {
    const handleActionCode = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const actionCode = urlParams.get('oobCode');
        const mode = urlParams.get('mode');

        if (!actionCode) {
          setMessage('Invalid action code.');
          return;
        }

        if (mode === 'verifyEmail') {
          await applyActionCode(auth, actionCode);
          setMessage('Email verification successful!');
          setTimeout(() => navigate('/login'), 3000);
        } else if (mode === 'resetPassword') {
          await verifyPasswordResetCode(auth, actionCode);
          setIsPasswordReset(true);
          setMessage('Enter your new password');
        } else {
          setMessage('Invalid action.');
        }
      } catch (error) {
        console.error('Error:', error);
        setMessage(`An error occurred: ${error.message}`);
      }
    };

    handleActionCode();
  }, [auth, navigate, location]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      const urlParams = new URLSearchParams(location.search);
      const actionCode = urlParams.get('oobCode');
      await confirmPasswordReset(auth, actionCode, newPassword);
      setMessage('Password reset successful!');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      console.error('Error:', error);
      setMessage(`An error occurred during password reset: ${error.message}`);
    }
  };

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen flex items-center justify-center">
      <motion.div
        className="bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h1
          className="text-3xl font-bold mb-6 text-center text-purple-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {isPasswordReset ? 'Password Reset' : 'Email Verification'}
        </motion.h1>
        <motion.div
          className="text-xl text-center text-gray-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          {isPasswordReset ? (
            <form onSubmit={handlePasswordReset}>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                className="w-full p-2 mb-4 bg-gray-700 rounded"
                required
              />
              <button
                type="submit"
                className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition-colors"
              >
                Reset Password
              </button>
            </form>
          ) : (
            <p>{message}</p>
          )}
        </motion.div>
        {message.includes('successful') && (
          <motion.div
            className="mt-6 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <p className="text-teal-400">Redirecting to login page...</p>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default EmailVerification;