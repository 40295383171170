import React, { useEffect } from 'react';
import './AcademicWriting.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
function AcademicWriting() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/learning/research-process');
    };
    return (
        <div className="academic-writing-intro">
            <header className="header">
                <h1>Mastering the Art of Academic Writing</h1>
                <p className="subtitle">Introduction</p>
            </header>

            <div className="contient">
                <div className="section-block">
                    <h2>Introduction to Academic Writing</h2>
                    <p>In this presentation, we will explore strategies and techniques essential for mastering the art of academic writing. This skill is crucial for students and professionals alike, as it enhances communication and persuasion in scholarly work.</p>
                </div>

                <div className="section-block">
                    <h2>Understanding Academic Writing</h2>
                    <p>Academic writing is characterized by a formal tone, precise word choice, and a clear structure. It serves to convey complex ideas effectively. Understanding its distinct conventions is vital for success in scholarly pursuits.</p>
                </div>

                <div className="section-block">
                    <h2>Key Components</h2>
                    <ul>
                        <li>Clear Thesis Statement</li>
                        <li>Coherent Structure</li>
                        <li>Well-Supported Arguments</li>
                    </ul>
                    <p>Each part contributes to the overall effectiveness of your writing, ensuring clarity and persuasiveness.</p>
                </div>

                <div className="section-block">
                    <h2>Research and Evidence</h2>
                    <p>Effective academic writing relies heavily on research and evidence. Citing credible sources not only strengthens your arguments but also showcases your commitment to academic integrity. Always verify your sources!</p>
                </div>

                <div className="section-block">
                    <h2>Structuring Your Paper</h2>
                    <p>A well-structured paper includes an introduction, body paragraphs, and a conclusion. Each section has a specific purpose and helps guide the reader through your arguments and findings. Clarity is key.</p>
                </div>

                <div className="section-block">
                    <h2>Clarity and Precision</h2>
                    <p>Using clear and precise language is essential in academic writing. Avoid jargon and overly complex sentences. Aim for simplicity while maintaining a professional tone to convey your ideas effectively.</p>
                </div>

                <div className="section-block">
                    <h2>Citing Sources</h2>
                    <p>Proper citation is crucial in academic writing. Familiarize yourself with different citation styles like APA, MLA, or Chicago. Accurate citations not only give credit to original authors but also enhance your credibility.</p>
                </div>

                <div className="section-block">
                    <h2>Editing and Revising</h2>
                    <p>Editing and revising are integral parts of the writing process. Take the time to review your work for grammar, spelling, and clarity. Consider peer feedback to identify areas for improvement.</p>
                </div>

                <div className="section-block">
                    <h2>Avoiding Plagiarism</h2>
                    <p>Plagiarism can have serious consequences in academia. Always ensure that you properly attribute any ideas or phrases that are not your own. Use plagiarism detection tools to safeguard your work.</p>
                </div>

                <div className="section-block">
                    <h2>Practice Makes Perfect</h2>
                    <p>Like any skill, academic writing improves with practice. Engage in regular writing exercises, seek feedback, and revise your work. The more you write, the more confident and proficient you will become.</p>
                </div>

                <div className="section-block">
                    <h2>Resources for Improvement</h2>
                    <p>Utilize available resources to enhance your writing skills. Consider workshops, online courses, or writing centers at your institution. These tools can provide valuable insights and guidance on your writing journey.</p>
                </div>

                <div className="section-block">
                    <h2>Conclusion and Takeaways</h2>
                    <p>Mastering academic writing is a journey that involves understanding its components, practicing regularly, and seeking feedback. By implementing these strategies, you can enhance your writing and achieve academic success.</p>
                </div>


            </div>
            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default AcademicWriting;
