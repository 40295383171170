import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AdminNavbar = ({ isLoggedIn, onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navLinks = [
    { to: "/admin/dashboard", text: "Dashboard" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMenuOpen]);

  return (
    <nav className="bg-white p-4 shadow-md">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <Link to="/admin/dashboard" className="text-black text-xl font-bold">Admin Dashboard</Link>
          
          {/* Hamburger menu for mobile */}
          <button 
            className="lg:hidden text-white focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>

          {/* Desktop menu */}
          <div className="hidden lg:flex items-center space-x-4">
            {navLinks.map((link) => (
              <Link key={link.to} to={link.to} className='text-gray-700 hover:text-orange-500 transition duration-300'>
                {link.text}
              </Link>
            ))}
            {isLoggedIn ? (
              <button onClick={onLogout} className='text-gray-700 hover:text-orange-500 transition duration-300'>Logout</button>
            ) : (
              <Link to="/admin/login" className='text-gray-700 hover:text-orange-500 transition duration-300'>Login</Link>
            )}
          </div>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="lg:hidden mt-4 space-y-2">
            {navLinks.map((link) => (
              <Link 
                key={link.to} 
                to={link.to} 
                className='block py-2 text-gray-700 hover:text-orange-500 transition duration-300'
                onClick={() => setIsMenuOpen(false)}
              >
                {link.text}
              </Link>
            ))}
            {isLoggedIn ? (
              <button 
                onClick={() => {
                  onLogout();
                  setIsMenuOpen(false);
                }} 
                className="block w-full text-left py-2 text-gray-700 hover:text-orange-500 transition duration-300"
              >
                Logout
              </button>
            ) : (
              <Link 
                to="/admin/login" 
                className='block py-2 text-gray-700 hover:text-orange-500 transition duration-300'
                onClick={() => setIsMenuOpen(false)}
              >
                Login
              </Link>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default AdminNavbar;
