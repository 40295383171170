import React from 'react';

const ModalLearning = ({ isOpen, onClose, onOpenLearningPathModal, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-teal-400">Training Required</h2>
        <p className="text-gray-300 mb-6">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={onOpenLearningPathModal} // Trigger the Learning Path Modal
            className="bg-purple-600 text-white py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300 mr-2"
          >
            Select Learning Path
          </button>
          <button
            onClick={onClose}
            className="bg-gray-600 text-white py-2 px-4 rounded-full hover:bg-gray-700 transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalLearning;
