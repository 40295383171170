// src/pages/JavaIOSerialization.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';


const JavaIOSerialization = () => {
    const byteStreamExampleCode = `
import java.io.FileInputStream;
import java.io.FileOutputStream;
import java.io.IOException;

public class ByteStreamExample {
    public static void main(String[] args) {
        try (FileInputStream fis = new FileInputStream("input.txt");
             FileOutputStream fos = new FileOutputStream("output.txt")) {

            int byteContent;
            while ((byteContent = fis.read()) != -1) {
                fos.write(byteContent);
            }

            System.out.println("File copied successfully.");
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}
`;

    const characterStreamExampleCode = `
import java.io.FileReader;
import java.io.FileWriter;
import java.io.IOException;

public class CharacterStreamExample {
    public static void main(String[] args) {
        try (FileReader fr = new FileReader("input.txt");
             FileWriter fw = new FileWriter("output.txt")) {

            int charContent;
            while ((charContent = fr.read()) != -1) {
                fw.write(charContent);
            }

            System.out.println("Character file copied successfully.");
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}
`;

    const serializationExampleCode = `
import java.io.FileOutputStream;
import java.io.IOException;
import java.io.ObjectOutputStream;
import java.io.Serializable;

class Employee implements Serializable {
    private static final long serialVersionUID = 1L;
    private String name;
    private int id;

    public Employee(String name, int id) {
        this.name = name;
        this.id = id;
    }

    @Override
    public String toString() {
        return "Employee [Name=" + name + ", ID=" + id + "]";
    }
}

public class SerializationExample {
    public static void main(String[] args) {
        Employee emp = new Employee("John Doe", 101);

        try (FileOutputStream fos = new FileOutputStream("employee.ser");
             ObjectOutputStream oos = new ObjectOutputStream(fos)) {

            oos.writeObject(emp);
            System.out.println("Serialization successful. Object saved to employee.ser");

        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}
`;

    const deserializationExampleCode = `
import java.io.FileInputStream;
import java.io.IOException;
import java.io.ObjectInputStream;

public class DeserializationExample {
    public static void main(String[] args) {
        try (FileInputStream fis = new FileInputStream("employee.ser");
             ObjectInputStream ois = new ObjectInputStream(fis)) {

            Employee emp = (Employee) ois.readObject();
            System.out.println("Deserialization successful. Object: " + emp);

        } catch (IOException | ClassNotFoundException e) {
            e.printStackTrace();
        }
    }
}
`;

    const transientExampleCode = `
class Employee implements Serializable {
    private static final long serialVersionUID = 1L;
    private String name;
    private int id;
    private transient String password; // This field will not be serialized

    public Employee(String name, int id, String password) {
        this.name = name;
        this.id = id;
        this.password = password;
    }

    @Override
    public String toString() {
        return "Employee [Name=" + name + ", ID=" + id + ", Password=" + password + "]";
    }
}
`;

    const bufferedReaderExampleCode = `
import java.io.BufferedReader;
import java.io.FileReader;
import java.io.IOException;

public class BufferedReaderExample {
    public static void main(String[] args) {
        try (BufferedReader br = new BufferedReader(new FileReader("input.txt"))) {
            String line;
            while ((line = br.readLine()) != null) {
                System.out.println(line);
            }
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}
`;

    const bufferedWriterExampleCode = `
import java.io.BufferedWriter;
import java.io.FileWriter;
import java.io.IOException;

public class BufferedWriterExample {
    public static void main(String[] args) {
        try (BufferedWriter bw = new BufferedWriter(new FileWriter("output.txt"))) {
            bw.write("Hello, World!");
            bw.newLine(); // Adds a newline
            bw.write("BufferedWriter makes writing efficient.");
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Java I/O and Serialization: A Comprehensive Guide</h1>
                <p>
                    Java Input/Output (I/O) operations are fundamental to interacting with external data sources such as files, networks, or system input. Serialization, on the other hand, allows Java objects to be converted into a byte stream, making it possible to store objects in a file, transmit them over a network, or save them in a database. Understanding how to effectively use Java's I/O and serialization mechanisms is crucial for building robust and efficient applications. This guide will cover streams, file I/O, serialization and deserialization, and working with BufferedReader and BufferedWriter.
                </p>

                <hr className="section-divider" />

                <h2>1. Streams and File I/O</h2>

                <h3>Overview</h3>
                <p>
                    Java I/O operations revolve around the concept of streams. A stream is a sequence of data elements made available over time. Streams in Java are of two types:
                </p>
                <ul>
                    <li><strong>Input Streams:</strong> Used to read data.</li>
                    <li><strong>Output Streams:</strong> Used to write data.</li>
                </ul>

                <h3>InputStream and OutputStream</h3>
                <p><strong>InputStream:</strong> The InputStream class is the superclass of all classes representing an input stream of bytes.</p>
                <p><strong>OutputStream:</strong> The OutputStream class is the superclass of all classes representing an output stream of bytes.</p>

                <h3>Example Code: Reading and Writing Bytes</h3>
                <CodeBlock code={byteStreamExampleCode} />

                <h4>Output:</h4>
                <pre>File copied successfully.</pre>

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>FileInputStream:</strong> Reads bytes from a file (input.txt) one byte at a time.</li>
                    <li><strong>FileOutputStream:</strong> Writes bytes to a file (output.txt).</li>
                    <li><strong>Auto-Closeable Resources:</strong> The try-with-resources statement ensures that streams are closed automatically, even if an exception occurs.</li>
                </ul>

                <h3>Reader and Writer</h3>
                <p>For character-based data, Java provides Reader and Writer classes:</p>
                <ul>
                    <li><strong>Reader:</strong> The Reader class is the abstract class for reading character streams.</li>
                    <li><strong>Writer:</strong> The Writer class is the abstract class for writing character streams.</li>
                </ul>

                <h3>Example Code: Reading and Writing Characters</h3>
                <CodeBlock code={characterStreamExampleCode} />

                <h4>Output:</h4>
                <pre>Character file copied successfully.</pre>

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>FileReader:</strong> Reads characters from a file (input.txt).</li>
                    <li><strong>FileWriter:</strong> Writes characters to a file (output.txt).</li>
                    <li><strong>Character Streams:</strong> Designed for handling text data, making it easier to work with Unicode characters.</li>
                </ul>

                <p><strong>Real-World Application:</strong> Streams and file I/O are fundamental in many applications, such as file editors, data processors, and loggers. For example, a text editor uses character streams to read and write files, while a data processor might use byte streams to handle binary data like images or compressed files.</p>

                <hr className="section-divider" />

                <h2>2. Serialization and Deserialization</h2>

                <h3>Overview</h3>
                <p>
                    Serialization is the process of converting an object into a byte stream, which can then be saved to a file, sent over a network, or stored in a database. Deserialization is the reverse process, where the byte stream is converted back into an object.
                </p>

                <h3>The Serializable Interface</h3>
                <p>
                    To serialize an object, the class of the object must implement the <code>Serializable</code> interface. This interface is a marker interface, meaning it does not contain any methods but serves to indicate that a class can be serialized.
                </p>

                <h3>Example Code: Serialization</h3>
                <CodeBlock code={serializationExampleCode} />

                <h4>Output:</h4>
                <pre>Serialization successful. Object saved to employee.ser</pre>

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>Serializable Interface:</strong> The Employee class implements Serializable, enabling its objects to be serialized.</li>
                    <li><strong>ObjectOutputStream:</strong> Converts the Employee object into a byte stream and writes it to the file employee.ser.</li>
                </ul>

                <h3>Example Code: Deserialization</h3>
                <CodeBlock code={deserializationExampleCode} />

                <h4>Output:</h4>
                <pre>Deserialization successful. Object: Employee [Name=John Doe, ID=101]</pre>

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>ObjectInputStream:</strong> Reads the byte stream from employee.ser and converts it back into an Employee object.</li>
                    <li><strong>Type Casting:</strong> The readObject() method returns an Object, so it needs to be cast to the appropriate type (Employee).</li>
                </ul>

                <h3>Transient Keyword</h3>
                <p>The transient keyword can be used to prevent a field from being serialized. This is useful for fields that do not need to be persisted, such as passwords or sensitive data.</p>

                <h3>Example Code: Using transient</h3>
                <CodeBlock code={transientExampleCode} />

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>Transient Field:</strong> The password field will not be saved during serialization, and its value will be null after deserialization.</li>
                </ul>

                <p><strong>Real-World Application:</strong> Serialization is commonly used in distributed systems where objects need to be transmitted over the network. For example, in a remote method invocation (RMI) system, objects are serialized to be sent across the network to remote servers or clients. Serialization is also used in session management, where objects are stored and retrieved across user sessions.</p>

                <hr className="section-divider" />

                <h2>3. Working with BufferedReader and BufferedWriter</h2>

                <h3>Overview</h3>
                <p>BufferedReader and BufferedWriter are classes used to efficiently read and write text data. They are wrappers around Reader and Writer classes that provide buffering, which improves performance by reducing the number of I/O operations.</p>

                <h3>BufferedReader</h3>
                <p>BufferedReader reads text from a character input stream and buffers the characters for efficient reading.</p>

                <h3>Example Code: Reading Text with BufferedReader</h3>
                <CodeBlock code={bufferedReaderExampleCode} />

                <h4>Output:</h4>
                <pre>(Contents of input.txt printed line by line)</pre>

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>BufferedReader:</strong> Wraps around a FileReader to read text lines from a file efficiently.</li>
                    <li><strong>readLine():</strong> Reads one line of text at a time, returning null when the end of the file is reached.</li>
                </ul>

                <h3>BufferedWriter</h3>
                <p>BufferedWriter writes text to a character output stream, buffering characters to improve efficiency.</p>

                <h3>Example Code: Writing Text with BufferedWriter</h3>
                <CodeBlock code={bufferedWriterExampleCode} />

                <h4>Output:</h4>
                <pre>(Text written to output.txt)</pre>

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>BufferedWriter:</strong> Wraps around a FileWriter to write text efficiently.</li>
                    <li><strong>newLine():</strong> Writes a platform-independent newline character.</li>
                </ul>

                <p><strong>Real-World Application:</strong> Buffered I/O is crucial in applications that involve reading and writing large text files. For example, in a log monitoring system, BufferedReader can be used to efficiently read logs line by line, while BufferedWriter can be used to write filtered logs to another file or stream. This ensures that the application performs optimally even when dealing with large amounts of data.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Java's I/O and serialization mechanisms are powerful tools that enable developers to interact with external data sources, persist objects, and handle text efficiently. Streams and file I/O are the foundation for reading and writing data, whether it's bytes or characters. Serialization and deserialization allow objects to be easily saved and restored, which is essential for distributed systems and session management. BufferedReader and BufferedWriter provide efficient ways to handle text data, ensuring that applications perform well even when processing large files.</p>

                <p>By mastering these concepts, you'll be well-equipped to handle a wide range of data processing tasks in Java, from basic file operations to complex distributed systems. Whether you're building desktop applications, web services, or data-driven systems, understanding Java I/O and serialization is crucial for creating robust, efficient, and scalable solutions.</p>

                <NextButton nextPage="/exception-handling-advanced" />
            </div>
        </div>
    );
};

export default JavaIOSerialization;
