// src/pages/AdvancedMultithreading.js
import React from 'react';
import CodeBlock from '../components/CodeBlock';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';


const AdvancedMultithreading = () => {
    const reentrantLockCode = `
import java.util.concurrent.locks.Lock;
import java.util.concurrent.locks.ReentrantLock;

class SharedResource {
    private final Lock lock = new ReentrantLock();

    public void safeIncrement() {
        lock.lock(); // Acquire the lock
        try {
            // Critical section (thread-safe code)
            System.out.println(Thread.currentThread().getName() + " acquired the lock.");
            // Simulate work
            Thread.sleep(100);
        } catch (InterruptedException e) {
            e.printStackTrace();
        } finally {
            lock.unlock(); // Release the lock
            System.out.println(Thread.currentThread().getName() + " released the lock.");
        }
    }
}

public class ReentrantLockExample {
    public static void main(String[] args) {
        SharedResource resource = new SharedResource();

        Runnable task = resource::safeIncrement;

        Thread t1 = new Thread(task, "Thread-1");
        Thread t2 = new Thread(task, "Thread-2");

        t1.start();
        t2.start();
    }
}`;

    const threadPoolCode = `
import java.util.concurrent.ExecutorService;
import java.util.concurrent.Executors;

public class ThreadPoolExample {
    public static void main(String[] args) {
        ExecutorService executor = Executors.newFixedThreadPool(3);

        Runnable task = () -> {
            System.out.println(Thread.currentThread().getName() + " is executing task.");
            try {
                Thread.sleep(100);
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        };

        for (int i = 0; i < 10; i++) {
            executor.submit(task);
        }

        executor.shutdown(); // Initiates an orderly shutdown
    }
}`;

    const concurrentHashMapCode = `
import java.util.concurrent.ConcurrentHashMap;
import java.util.concurrent.ExecutorService;
import java.util.concurrent.Executors;

public class ConcurrentHashMapExample {
    public static void main(String[] args) {
        ConcurrentHashMap<String, Integer> map = new ConcurrentHashMap<>();

        ExecutorService executor = Executors.newFixedThreadPool(3);

        Runnable writerTask = () -> {
            for (int i = 0; i < 100; i++) {
                map.put(Thread.currentThread().getName() + "-" + i, i);
            }
        };

        Runnable readerTask = () -> {
            for (int i = 0; i < 100; i++) {
                map.get(Thread.currentThread().getName() + "-" + i);
            }
        };

        executor.submit(writerTask);
        executor.submit(readerTask);

        executor.shutdown();
    }
}`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Advanced Multithreading and Concurrency in Java: A Comprehensive Guide</h1>
                <p>
                    Multithreading and concurrency are critical for building high-performance, scalable applications in Java. However, they also introduce complexity, particularly around resource sharing, synchronization, and thread management. Java provides advanced tools and techniques to manage these complexities, including locks, thread pools, and concurrent collections. This guide will explore these advanced topics in detail, with clear explanations, practical examples, and real-world applications.
                </p>

                <hr className="section-divider" />

                <h2>1. Locks and Reentrant Locks</h2>

                <h3>1.1. Overview</h3>
                <p>
                    In multithreaded applications, locks are used to control access to shared resources, ensuring that only one thread can access a resource at a time. Java provides several types of locks, with ReentrantLock being one of the most versatile and commonly used.
                </p>

                <ul>
                    <li><strong>synchronized Keyword vs. Locks:</strong> The synchronized keyword is the simplest way to enforce mutual exclusion in Java. However, ReentrantLock provides more advanced features, such as the ability to try acquiring a lock, interrupting a lock attempt, and setting a fairness policy.</li>
                </ul>

                <h3>1.2. ReentrantLock</h3>
                <p>
                    ReentrantLock is a class that implements the Lock interface. It provides the same basic behavior as the synchronized keyword but with additional flexibility.
                </p>

                <p><strong>Key Features of ReentrantLock:</strong></p>
                <ul>
                    <li><strong>Lock Acquisition:</strong> You can explicitly acquire and release locks.</li>
                    <li><strong>Fairness Policy:</strong> Ensures that threads acquire locks in the order they requested them.</li>
                    <li><strong>Reentrancy:</strong> The same thread can acquire the lock multiple times without causing a deadlock.</li>
                </ul>

                <CodeBlock code={reentrantLockCode} />

                <h4>Explanation:</h4>
                <p><strong>ReentrantLock:</strong> The <code>safeIncrement()</code> method uses a ReentrantLock to ensure that only one thread can execute the critical section at a time.</p>
                <p><strong>Lock and Unlock:</strong> The <code>lock.lock()</code> method acquires the lock, and <code>lock.unlock()</code> releases it, ensuring that the critical section is thread-safe.</p>

                <h3>1.3. Fairness Policy</h3>
                <CodeBlock code={`Lock lock = new ReentrantLock(true); // Fair lock`} />

                <h4>Explanation:</h4>
                <p><strong>Fairness:</strong> By passing <code>true</code> to the ReentrantLock constructor, you create a fair lock, ensuring that threads acquire the lock in the order they requested it.</p>

                <h3>1.4. Real-World Application</h3>
                <p>
                    Locks, particularly ReentrantLock, are essential in scenarios where fine-grained control over thread synchronization is required. For example, in a banking system where multiple threads process transactions, locks ensure that account balances are updated correctly without race conditions.
                </p>

                <hr className="section-divider" />

                <h2>2. Thread Pools</h2>

                <h3>2.1. Overview</h3>
                <p>
                    Thread pools manage a pool of worker threads, reusing them for executing multiple tasks. This approach reduces the overhead of creating and destroying threads for each task, improves performance, and allows better control over concurrency.
                </p>

                <h3>2.2. Executors Framework</h3>
                <p>
                    The Executors framework in Java provides a way to create and manage thread pools. It abstracts the creation and management of threads, allowing developers to focus on task submission rather than thread management.
                </p>

                <p><strong>Commonly Used Thread Pools:</strong></p>
                <ul>
                    <li><strong>Fixed Thread Pool:</strong> A thread pool with a fixed number of threads.</li>
                    <li><strong>Cached Thread Pool:</strong> A pool that creates new threads as needed but reuses idle threads.</li>
                    <li><strong>Single Thread Executor:</strong> A single-threaded executor that executes tasks sequentially.</li>
                </ul>

                <CodeBlock code={threadPoolCode} />

                <h4>Explanation:</h4>
                <p><strong>Fixed Thread Pool:</strong> The <code>Executors.newFixedThreadPool(3)</code> creates a thread pool with three threads. The tasks are submitted to the pool, and the threads execute them concurrently.</p>
                <p><strong>Task Submission:</strong> The <code>submit()</code> method submits tasks to the pool, which are executed by the available threads.</p>
                <p><strong>Shutdown:</strong> The <code>shutdown()</code> method initiates an orderly shutdown of the pool, allowing previously submitted tasks to execute before terminating.</p>

                <h3>2.3. Managing Thread Pools</h3>
                <p><strong>Graceful Shutdown:</strong> Use <code>shutdown()</code> to stop accepting new tasks and finish executing existing tasks. Use <code>shutdownNow()</code> to attempt to stop all actively executing tasks and halt the processing of waiting tasks.</p>
                <p><strong>Handling Rejected Tasks:</strong> If the thread pool cannot accept new tasks (e.g., because it has been shut down), a <code>RejectedExecutionException</code> is thrown. You can customize this behavior by implementing a <code>RejectedExecutionHandler</code>.</p>

                <h3>2.4. Real-World Application</h3>
                <p>
                    Thread pools are ideal for server applications, such as web servers, where handling a large number of concurrent requests efficiently is critical. They are also used in parallel processing tasks, such as image processing or data analysis, where multiple tasks need to be executed concurrently but managed efficiently.
                </p>

                <hr className="section-divider" />

                <h2>3. Concurrent Collections (ConcurrentHashMap)</h2>

                <h3>3.1. Overview</h3>
                <p>
                    Concurrent collections are designed to handle concurrency more efficiently than standard collections like <code>HashMap</code> or <code>ArrayList</code>, which are not thread-safe. <code>ConcurrentHashMap</code> is one of the most commonly used concurrent collections, providing a thread-safe implementation of <code>HashMap</code> with better performance under concurrent access.
                </p>

                <h3>3.2. ConcurrentHashMap</h3>
                <CodeBlock code={concurrentHashMapCode} />

                <h4>Explanation:</h4>
                <p><strong>ConcurrentHashMap:</strong> This example demonstrates a <code>ConcurrentHashMap</code> being accessed by multiple threads simultaneously, both for reading and writing.</p>
                <p><strong>Thread Safety:</strong> Unlike <code>HashMap</code>, which is not thread-safe, <code>ConcurrentHashMap</code> allows safe concurrent access by multiple threads without explicit synchronization.</p>

                <h3>3.3. Performance Considerations</h3>
                <p><strong>Lock Striping:</strong> <code>ConcurrentHashMap</code> uses a technique called lock striping, which divides the map into segments, each of which can be locked independently. This reduces contention and increases throughput in concurrent environments.</p>
                <p><strong>Iterators:</strong> The iterators returned by <code>ConcurrentHashMap</code> are weakly consistent, meaning they reflect the state of the map at the time they were created and may not reflect subsequent updates.</p>

                <h3>3.4. Real-World Application</h3>
                <p>
                    <code>ConcurrentHashMap</code> is commonly used in scenarios where multiple threads need to read and update a shared map concurrently, such as in caching mechanisms, real-time analytics, or in-memory data grids. It is especially useful in high-concurrency environments where performance is critical.
                </p>

                <hr className="section-divider" />

                <h2>Conclusion</h2>
                <p>
                    Advanced multithreading and concurrency are essential for developing high-performance, scalable Java applications. By understanding and utilizing locks, thread pools, and concurrent collections, developers can create robust, thread-safe applications that can efficiently manage multiple tasks and resources.
                </p>
                <p>
                    <strong>Locks and Reentrant Locks:</strong> Provide fine-grained control over thread synchronization, ensuring that shared resources are accessed safely in a concurrent environment.
                </p>
                <p>
                    <strong>Thread Pools:</strong> Simplify thread management, improve performance, and allow efficient execution of multiple tasks in parallel, making them ideal for server applications and parallel processing.
                </p>
                <p>
                    <strong>Concurrent Collections:</strong> Such as <code>ConcurrentHashMap</code>, offer thread-safe data structures that perform efficiently under concurrent access, making them indispensable in high-concurrency scenarios.
                </p>
                <p>
                    By mastering these advanced concepts, you can build Java applications that are not only functionally correct but also performant, scalable, and capable of handling the demands of modern computing environments. Whether you're developing server-side applications, real-time systems, or complex multi-threaded software, these tools and techniques will help you achieve optimal concurrency management.
                </p>
                <NextButton nextPage="/java-8-features" />
            </div>
        </div>
    );
};

export default AdvancedMultithreading;
