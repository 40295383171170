// src/pages/JavaMemoryManagement.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';


const JavaMemoryManagement = () => {
    const heapExampleCode = `
public class HeapExample {
    public static void main(String[] args) {
        int[] largeArray = new int[1000000]; // Allocated in heap
        System.out.println("Array created with size: " + largeArray.length);
    }
}
`;

    const stackExampleCode = `
public class StackExample {
    public static void main(String[] args) {
        int result = factorial(5);
        System.out.println("Factorial: " + result);
    }

    public static int factorial(int n) {
        if (n == 1) {
            return 1;
        }
        return n * factorial(n - 1);
    }
}
`;

    const gcDemoCode = `
public class GCDemo {
    public static void main(String[] args) {
        for (int i = 0; i < 10000; i++) {
            String s = new String("Garbage Collection Demo " + i);
        }
        System.gc(); // Request garbage collection
        System.out.println("Garbage Collection requested");
    }
}
`;

    const memoryLeakExampleCode = `
import java.util.ArrayList;
import java.util.List;

public class MemoryLeakExample {
    private static List<byte[]> list = new ArrayList<>();

    public static void main(String[] args) {
        while (true) {
            list.add(new byte[1024 * 1024]); // 1 MB
        }
    }
}
`;

    const tryWithResourcesCode = `
import java.io.BufferedReader;
import java.io.FileReader;
import java.io.IOException;

public class ResourceManagementExample {
    public static void main(String[] args) {
        try (BufferedReader br = new BufferedReader(new FileReader("example.txt"))) {
            String line;
            while ((line = br.readLine()) != null) {
                System.out.println(line);
            }
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}
`;

    const dataStructureOptimizationCode = `
import java.util.HashMap;
import java.util.Map;

public class DataStructureOptimizationExample {
    public static void main(String[] args) {
        Map<String, Integer> cache = new HashMap<>();
        for (int i = 0; i < 10000; i++) {
            cache.put("key" + i, i);
        }
        System.out.println("Cache populated with " + cache.size() + " entries");
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Java Memory Management: A Comprehensive Guide</h1>
                <p>
                    Memory management is a critical aspect of any programming language, and in Java, it is primarily handled by the Java Virtual Machine (JVM). Understanding how memory is allocated, managed, and optimized in Java is essential for developing efficient and robust applications. This guide will explore the key concepts of Java memory management, including the heap and stack, garbage collection mechanisms, and how to avoid memory leaks and optimize memory usage.
                </p>

                <hr className="section-divider" />

                <h2>1. Understanding Heap and Stack</h2>

                <h3>1.1. Overview of Java Memory Areas</h3>
                <p>
                    Java memory is divided into several key areas:
                </p>
                <ul>
                    <li><strong>Heap:</strong> The heap is the runtime data area from which memory for all class instances and arrays is allocated. It is a shared area accessible by all threads.</li>
                    <li><strong>Stack:</strong> Each thread in Java has its own stack, which stores frames. Each frame corresponds to a method call and contains the local variables, method parameters, and return addresses.</li>
                </ul>

                <h3>1.2. Heap Memory</h3>
                <p>
                    Heap memory is where objects are allocated in Java. It is divided into several generations to manage objects based on their lifecycles:
                </p>
                <ul>
                    <li><strong>Young Generation:</strong> This is where all new objects are created. It is further divided into:
                        <ul>
                            <li><strong>Eden Space:</strong> Most objects are initially allocated in the Eden space.</li>
                            <li><strong>Survivor Spaces (S0 and S1):</strong> Objects that survive garbage collection in Eden are moved to the Survivor spaces.</li>
                        </ul>
                    </li>
                    <li><strong>Old Generation (Tenured):</strong> Objects that have lived long enough in the Survivor spaces are moved here.</li>
                    <li><strong>Permanent Generation (Metaspace in Java 8 and later):</strong> Stores metadata like class definitions. In Java 8, the PermGen space was replaced with Metaspace.</li>
                </ul>

                <h3>Example Code (Heap Usage):</h3>
                <CodeBlock code={heapExampleCode} />

                <h4>Output:</h4>
                <pre>Array created with size: 1000000</pre>

                <h4>Explanation:</h4>
                <p><strong>Heap Allocation:</strong> The <code>largeArray</code> is allocated in the heap, demonstrating how large objects are stored in this memory area.</p>

                <h3>1.3. Stack Memory</h3>
                <p>
                    Stack memory is used for method execution. Each method call creates a new stack frame that contains the method’s local variables, parameters, and the return address.
                </p>

                <h3>Example Code (Stack Usage):</h3>
                <CodeBlock code={stackExampleCode} />

                <h4>Output:</h4>
                <pre>Factorial: 120</pre>

                <h4>Explanation:</h4>
                <p><strong>Stack Frames:</strong> Each recursive call to <code>factorial()</code> creates a new stack frame, which is pushed onto the stack. The stack frames are popped off the stack as the method returns.</p>

                <h3>1.4. Differences Between Heap and Stack</h3>
                <ul>
                    <li><strong>Scope:</strong>
                        <ul>
                            <li><strong>Stack:</strong> Local variables and method calls.</li>
                            <li><strong>Heap:</strong> Objects and global variables.</li>
                        </ul>
                    </li>
                    <li><strong>Lifetime:</strong>
                        <ul>
                            <li><strong>Stack:</strong> Exists until the method completes execution.</li>
                            <li><strong>Heap:</strong> Exists until garbage collected.</li>
                        </ul>
                    </li>
                    <li><strong>Access:</strong>
                        <ul>
                            <li><strong>Stack:</strong> LIFO (Last In, First Out) order.</li>
                            <li><strong>Heap:</strong> Objects are accessed through references.</li>
                        </ul>
                    </li>
                </ul>

                <p><strong>Real-World Application:</strong> Understanding the heap and stack is crucial in performance-sensitive applications like gaming or financial trading systems, where efficient memory management can significantly impact responsiveness and throughput.</p>

                <hr className="section-divider" />

                <h2>2. Garbage Collection Mechanisms</h2>

                <h3>2.1. Overview of Garbage Collection</h3>
                <p>Garbage collection (GC) in Java is an automatic process by which the JVM reclaims memory occupied by objects that are no longer referenced by the application. This process helps prevent memory leaks and reduces the likelihood of out-of-memory errors.</p>

                <h3>2.2. Types of Garbage Collectors</h3>
                <p>Java offers several types of garbage collectors, each with its own advantages and use cases:</p>
                <ul>
                    <li><strong>Serial Garbage Collector:</strong>
                        <ul>
                            <li><strong>Use Case:</strong> Suitable for single-threaded environments or applications with small datasets.</li>
                            <li><strong>How It Works:</strong> Uses a single thread to perform GC. It stops all application threads during garbage collection (stop-the-world event).</li>
                        </ul>
                    </li>
                    <li><strong>Parallel Garbage Collector:</strong>
                        <ul>
                            <li><strong>Use Case:</strong> Ideal for applications running on multi-core processors.</li>
                            <li><strong>How It Works:</strong> Uses multiple threads to perform GC, reducing the pause time during garbage collection.</li>
                        </ul>
                    </li>
                    <li><strong>Concurrent Mark-Sweep (CMS) Garbage Collector:</strong>
                        <ul>
                            <li><strong>Use Case:</strong> Applications requiring low pause times, such as interactive applications.</li>
                            <li><strong>How It Works:</strong> Performs most of the garbage collection concurrently with the application, minimizing pause times.</li>
                        </ul>
                    </li>
                    <li><strong>G1 Garbage Collector (Garbage First):</strong>
                        <ul>
                            <li><strong>Use Case:</strong> Suitable for large heaps with multi-threaded applications.</li>
                            <li><strong>How It Works:</strong> Divides the heap into regions and performs garbage collection on regions with the most garbage first.</li>
                        </ul>
                    </li>
                    <li><strong>Z Garbage Collector (ZGC):</strong>
                        <ul>
                            <li><strong>Use Case:</strong> Designed for applications requiring large heap sizes with minimal latency.</li>
                            <li><strong>How It Works:</strong> Operates concurrently with the application with very low pause times, even for large heaps.</li>
                        </ul>
                    </li>
                </ul>

                <h3>2.3. Garbage Collection Phases</h3>
                <ul>
                    <li><strong>Mark Phase:</strong> Identifies objects that are still reachable (i.e., referenced by other objects).</li>
                    <li><strong>Sweep Phase:</strong> Reclaims memory occupied by objects that are no longer reachable.</li>
                    <li><strong>Compaction Phase (Optional):</strong> Reorganizes the heap to eliminate fragmentation by moving live objects together.</li>
                </ul>

                <h3>Example Code (GC Logging):</h3>
                <CodeBlock code={gcDemoCode} />

                <h4>Output:</h4>
                <pre>Garbage Collection requested</pre>

                <h4>Explanation:</h4>
                <p><strong>GC Request:</strong> The <code>System.gc()</code> method requests garbage collection, though it is not guaranteed to run immediately. Logging GC activities can be enabled with JVM flags like <code>-XX:+PrintGCDetails</code>.</p>

                <p><strong>Real-World Application:</strong> Choosing the right garbage collector is crucial for applications like high-frequency trading platforms, where latency and throughput are critical. For example, the G1 garbage collector is often preferred in such environments due to its ability to balance pause times and throughput.</p>

                <hr className="section-divider" />

                <h2>3. Memory Leaks and Optimization</h2>

                <h3>3.1. Understanding Memory Leaks</h3>
                <p>A memory leak in Java occurs when objects that are no longer needed by the application continue to be referenced, preventing the garbage collector from reclaiming their memory. Over time, this can lead to increased memory usage and, eventually, an <code>OutOfMemoryError</code>.</p>

                <h3>3.2. Common Causes of Memory Leaks</h3>
                <ul>
                    <li><strong>Unclosed Resources:</strong> Failing to close file streams, database connections, or sockets can lead to memory leaks.</li>
                    <li><strong>Static References:</strong> Objects held by static fields persist for the lifetime of the application, potentially leading to memory leaks if not managed properly.</li>
                    <li><strong>Listeners and Callbacks:</strong> Registering listeners or callbacks without properly deregistering them can cause memory leaks, as these objects remain in memory.</li>
                </ul>

                <h3>Example Code (Memory Leak):</h3>
                <CodeBlock code={memoryLeakExampleCode} />

                <h4>Explanation:</h4>
                <p><strong>Memory Leak:</strong> The list continues to grow indefinitely, consuming more memory until the application runs out of memory.</p>

                <h3>3.3. Detecting and Preventing Memory Leaks</h3>
                <p>Profiling Tools: Use profiling tools like VisualVM, YourKit, or Eclipse MAT to monitor memory usage and identify potential leaks.</p>

                <p><strong>Best Practices:</strong></p>
                <ul>
                    <li><strong>Use try-with-resources:</strong> Automatically closes resources, preventing memory leaks.</li>
                    <li><strong>Weak References:</strong> Use <code>WeakReference</code> or <code>SoftReference</code> for objects that should not prevent garbage collection.</li>
                    <li><strong>Explicit Deregistration:</strong> Ensure that listeners, callbacks, and event handlers are explicitly deregistered when no longer needed.</li>
                </ul>

                <h3>Example Code (Preventing Memory Leak with try-with-resources):</h3>
                <CodeBlock code={tryWithResourcesCode} />

                <h4>Explanation:</h4>
                <p><strong>try-with-resources:</strong> Ensures that the <code>BufferedReader</code> is closed automatically, even if an exception occurs, preventing resource leaks.</p>

                <h3>3.4. Memory Optimization Techniques</h3>
                <ul>
                    <li><strong>Object Pooling:</strong> Reuse objects instead of creating new ones frequently. Useful for expensive-to-create objects like database connections.</li>
                    <li><strong>Minimize Object Creation:</strong> Avoid unnecessary object creation, especially in tight loops or frequently called methods.</li>
                    <li><strong>Optimize Data Structures:</strong> Choose the most efficient data structures (e.g., <code>ArrayList</code> vs. <code>LinkedList</code>, <code>HashMap</code> vs. <code>TreeMap</code>) based on the use case.</li>
                    <li><strong>Reduce Scope:</strong> Limit the scope of variables to reduce their lifetime and facilitate garbage collection.</li>
                </ul>

                <h3>Example Code (Optimizing Data Structure):</h3>
                <CodeBlock code={dataStructureOptimizationCode} />

                <h4>Output:</h4>
                <pre>Cache populated with 10000 entries</pre>

                <h4>Explanation:</h4>
                <p><strong>Choosing the Right Data Structure:</strong> Using a <code>HashMap</code> for fast lookups ensures efficient memory usage and performance in scenarios where the order of entries is not important.</p>

                <p><strong>Real-World Application:</strong> Memory leaks can be particularly problematic in long-running applications such as servers or desktop applications. For instance, in a web server handling millions of requests, even small memory leaks can accumulate over time, leading to degraded performance or crashes. By implementing memory management best practices and using tools to detect leaks, developers can ensure that their applications remain stable and performant.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Java memory management is a complex but crucial aspect of building efficient and reliable applications. By understanding how memory is allocated on the heap and stack, how garbage collection works, and how to prevent memory leaks, you can write code that not only performs well but also scales effectively.</p>

                <p>The heap and stack are fundamental concepts that dictate where and how data is stored in memory. Garbage collection automates the reclamation of memory, but choosing the right garbage collector and understanding its impact on application performance is key. Memory leaks can severely affect the performance and stability of an application, so it is important to follow best practices for resource management and optimize memory usage.</p>

                <p>Mastering these concepts will enable you to develop Java applications that are not only functional but also efficient, reliable, and capable of handling large-scale operations without running into memory issues. Whether you are developing enterprise-level software, mobile applications, or high-performance systems, effective memory management is a critical skill that will enhance the quality of your software solutions.</p>

                <NextButton nextPage="/design-patterns" />
            </div>
        </div>
    );
};

export default JavaMemoryManagement;
