// FAQ.js
import React from 'react';

const FAQ = () => {
  const faqs = [
    {
      question: "What is FitFreelance?",
      answer: "FitFreelance is an online school dedicated to helping freelancers improve their skills while still offering a substantial income. We offer expert guidence, skill development workshops, and professional growth opportunities."
    },
    {
      question: "Who can benefit from FitFreelance?",
      answer: "Our programs are designed for students, researchers, and professionals looking to enhance their Freelance skills. We welcome people from all walks of lofe and most importamtly those interested in boosting their income through part time jobs."
    },
    {
      question: "What types of courses do you offer?",
      answer: "we offer transcription courses, article/blog writing, academic writing, data entry among many others that will ensure a steady flow of income."
    },
    {
      question: "Are the courses self-paced or scheduled?",
      answer: "We offer both self-paced and scheduled courses to accommodate different learning preferences and time constraints."
    },
    {
      question: "Do you provide personalized feedback on writing assignments?",
      answer: "Yes, many of our courses include personalized feedback from experienced academic writers to help you improve your skills."
    },
    {
      question: "How long does it take to complete a course?",
      answer: "Course duration varies depending on the complexity and depth of the subject matter. Most courses range from 4 to 12 weeks."
    },
  ];

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-12">
        <h2 className="text-3xl font-bold mb-8 text-center text-teal-400">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <div key={index} className="mb-6">
              <h3 className="text-xl font-semibold mb-2 text-purple-300">{faq.question}</h3>
              <p className="text-gray-300">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;