import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const AdminWriters = () => {
  const [writers, setWriters] = useState([]);
  const [filteredWriters, setFilteredWriters] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchWriters();
  }, []);

  useEffect(() => {
    const results = writers.filter(writer =>
      writer.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      writer.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredWriters(results);
  }, [searchTerm, writers]);

  const fetchWriters = async () => {
    try {
      const token = sessionStorage.getItem('adminToken');
      if (!token) {
        throw new Error('No admin token found');
      }

      const response = await fetch(`${API_BASE_URL}/api/admin/writers`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      setWriters(data);
      setFilteredWriters(data);
    } catch (e) {
      console.error('Failed to fetch writers:', e);
      setError(e.message);
    }
  };

  const handleSuspend = async (writerId) => {
    try {
      const token = sessionStorage.getItem('adminToken');
      if (!token) {
        throw new Error('No admin token found');
      }

      const response = await fetch(`${API_BASE_URL}/api/admin/writers/${writerId}/status`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status: 'suspended' })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh the writers list after suspending a writer
      fetchWriters();
    } catch (e) {
      console.error('Failed to suspend writer:', e);
      setError(e.message);
    }
  };

  const handleVerify = async (writerId) => {
    try {
      const token = sessionStorage.getItem('adminToken');
      if (!token) {
        throw new Error('No admin token found');
      }

      const response = await fetch(`${API_BASE_URL}/api/admin/writers/${writerId}/verify`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Refresh the writers list after verifying a writer
      fetchWriters();
    } catch (e) {
      console.error('Failed to verify writer:', e);
      setError(e.message);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!writers.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="lg:w-64 bg-teal-500 text-gray-100 flex flex-col">
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4">Writers Admin</h1>
          <nav>
            <Link to="/admin/dashboard" className="block py-2 hover:bg-gray-700">Dashboard</Link>
            <Link to="/admin/tasks" className="block py-2 hover:bg-gray-700">Manage Tasks</Link>
            <Link to="/admin/writers" className="block py-2 hover:bg-gray-700">My Writers</Link>
            <Link to="/admin/orders" className="block py-2 hover:bg-gray-700">Orders</Link>
            <Link to="/admin/bids" className="block py-2 hover:bg-gray-700">Bids</Link>
            <Link to="/admin/transactions" className="block py-2 hover:bg-gray-700">Transactions</Link>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Bar */}
        <header className="bg-teal-300 shadow-sm z-10">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h2 className="font-semibold text-xl text-gray-600">Writers</h2>
          </div>
        </header>

        {/* Writers List */}
        <main className="flex-1 overflow-x-auto bg-gray-600">
          <div className="container mx-auto px-4 py-8">
            <div className="bg-white shadow-md rounded-lg">
              <div className="p-6">
                <h3 className="text-lg text-gray-800 font-semibold mb-4">Writers List</h3>
                <input
                  type="text"
                  placeholder="Search writers..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="w-full px-3 py-2 border border-gray-300 text-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                />
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="py-3 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                      <th className="py-3 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                      <th className="py-3 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                      <th className="py-3 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                      <th className="py-3 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Verified</th>
                      <th className="py-3 px-4 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredWriters.map(writer => (
                      <tr key={writer.id}>
                        <td className="py-3 px-4 border-b text-sm text-gray-900">{writer.id}</td>
                        <td className="py-3 px-4 border-b text-sm text-gray-900">{writer.fullName}</td>
                        <td className="py-3 px-4 border-b text-sm text-gray-500">{writer.email}</td>
                        <td className="py-3 px-4 border-b text-sm text-gray-500">{writer.status}</td>
                        <td className="py-3 px-4 border-b text-sm text-gray-500">
                          {writer.learningProgress.completedLearning ? 'Yes' : 'No'}
                        </td>
                        <td className="py-3 px-4 border-b text-sm">
                          {writer.status === 'active' && (
                            <button 
                              onClick={() => handleSuspend(writer.id)} 
                              className="text-red-500 hover:text-red-700 mr-2"
                            >
                              Suspend
                            </button>
                          )}
                          {!writer.learningProgress.completedLearning && (
                            <button 
                              onClick={() => handleVerify(writer.id)} 
                              className="text-green-500 hover:text-green-700"
                            >
                              Verify
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminWriters;