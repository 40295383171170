// src/components/CodeBlock.js
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CodeBlock = ({ code }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Reset the "Copied!" message after 2 seconds
    };

    return (
        <div className="code-block">
            <pre>
                <code>{code}</code>
            </pre>
            <CopyToClipboard text={code} onCopy={handleCopy}>
                <button className="copy-button">
                    {copied ? 'Copied!' : 'Copy'}
                </button>
            </CopyToClipboard>
        </div>
    );
};

export default CodeBlock;
