import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './ArticleWriting/components/Sidebar';
import Blogging from './ArticleWriting/pages/Blogging';
import ArticleWritingGuide from './ArticleWriting/pages/ArticleWritingGuide';
import LockableContent from './LockableComponent';
import Quiz from './AcademicWritingLearning/pages/Quiz';
import { API_BASE_URL } from './components/config';

function BloggingModule({ userToken, learningStatus }) {
  const [hasCompletedLearning, setHasCompletedLearning] = useState(learningStatus);

  useEffect(() => {
    const fetchLearningStatus = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/training/status`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        const data = await response.json();
        setHasCompletedLearning(data);
      } catch (error) {
        console.error('Error fetching status:', error);
      }
    };
    fetchLearningStatus();
  }, [userToken]);

  const handleUnlock = () => {
    setHasCompletedLearning(prevState => ({...prevState, isPaid: true}));
  };

  return (
    <div className="flex flex-col md:flex-row">
      <Sidebar />
      <div className="flex-1 p-4 bg-white">
        <Routes>
          <Route
            path="/"
            element={<Blogging />}
          />
          <Route
            path="Blogging"
            element={
              <LockableContent 
                initialLearningStatus={hasCompletedLearning.isPaid} 
                userToken={userToken}
                onUnlock={handleUnlock}
              >
                <Blogging />
              </LockableContent>
            }
          />
          <Route
            path="ArticleWritingGuide"
            element={
              <LockableContent 
                initialLearningStatus={hasCompletedLearning.isPaid} 
                userToken={userToken}
                onUnlock={handleUnlock}
              >
                <ArticleWritingGuide />
              </LockableContent>
            }
          />
          <Route
            path='Quiz'
            element={
              <LockableContent 
                initialLearningStatus={hasCompletedLearning.isPaid} 
                userToken={userToken}
                onUnlock={handleUnlock}
              >
                <Quiz userToken={userToken} />
              </LockableContent>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default BloggingModule;