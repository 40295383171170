// src/pages/BuildingJavaApplications.js
import React from 'react';
import CodeBlock from '../components/CodeBlock';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';

const BuildingJavaApplications = () => {
    const mavenPomCode = `
        <project xmlns="http://maven.apache.org/POM/4.0.0"
                 xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                 xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd">
            <modelVersion>4.0.0</modelVersion>
            <groupId>com.example</groupId>
            <artifactId>my-app</artifactId>
            <version>1.0-SNAPSHOT</version>
            <dependencies>
                <dependency>
                    <groupId>org.springframework.boot</groupId>
                    <artifactId>spring-boot-starter-web</artifactId>
                    <version>2.7.0</version>
                </dependency>
            </dependencies>
            <build>
                <plugins>
                    <plugin>
                        <groupId>org.apache.maven.plugins</groupId>
                        <artifactId>maven-compiler-plugin</artifactId>
                        <version>3.8.1</version>
                        <configuration>
                            <source>17</source>
                            <target>17</target>
                        </configuration>
                    </plugin>
                </plugins>
            </build>
        </project>
    `;

    const gradleBuildCode = `
        plugins {
            id 'java'
        }

        group 'com.example'
        version '1.0-SNAPSHOT'

        repositories {
            mavenCentral()
        }

        dependencies {
            implementation 'org.springframework.boot:spring-boot-starter-web:2.7.0'
        }

        tasks.withType(JavaCompile) {
            sourceCompatibility = '17'
            targetCompatibility = '17'
        }
    `;

    const mavenJarPluginCode = `
        <build>
            <plugins>
                <plugin>
                    <groupId>org.apache.maven.plugins</groupId>
                    <artifactId>maven-jar-plugin</artifactId>
                    <version>3.2.0</version>
                    <configuration>
                        <archive>
                            <manifest>
                                <addClasspath>true</addClasspath>
                                <mainClass>com.example.Main</mainClass>
                            </manifest>
                        </archive>
                    </configuration>
                </plugin>
            </plugins>
        </build>
    `;

    const gradleJarTaskCode = `
        jar {
            manifest {
                attributes(
                    'Main-Class': 'com.example.Main'
                )
            }
        }
    `;

    const javaFxMavenCode = `
        <dependencies>
            <dependency>
                <groupId>org.openjfx</groupId>
                <artifactId>javafx-controls</artifactId>
                <version>17.0.1</version>
            </dependency>
            <dependency>
                <groupId>org.openjfx</groupId>
                <artifactId>javafx-fxml</artifactId>
                <version>17.0.1</version>
            </dependency>
        </dependencies>
    `;

    const javaFxGradleCode = `
        dependencies {
            implementation 'org.openjfx:javafx-controls:17.0.1'
            implementation 'org.openjfx:javafx-fxml:17.0.1'
        }
    `;

    const javaFxAppCode = `
        import javafx.application.Application;
        import javafx.scene.Scene;
        import javafx.scene.control.Button;
        import javafx.scene.layout.StackPane;
        import javafx.stage.Stage;

        public class HelloWorld extends Application {

            @Override
            public void start(Stage primaryStage) {
                Button btn = new Button();
                btn.setText("Say 'Hello World'");
                btn.setOnAction(event -> System.out.println("Hello World!"));

                StackPane root = new StackPane();
                root.getChildren().add(btn);

                Scene scene = new Scene(root, 300, 250);

                primaryStage.setTitle("Hello World");
                primaryStage.setScene(scene);
                primaryStage.show();
            }

            public static void main(String[] args) {
                launch(args);
            }
        }
    `;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Java Application Development: A Comprehensive Guide</h1>
                <p>
                    Building Java applications involves various aspects such as managing dependencies, packaging the application for distribution, and, in some cases, developing a graphical user interface (GUI). Understanding how to effectively use tools like Maven or Gradle for dependency management, packaging applications as JAR or WAR files, and developing GUIs using JavaFX can significantly enhance your productivity and the quality of your applications. This guide will cover these key topics in detail, providing practical examples and real-world applications.
                </p>

                <hr className="section-divider" />

                <h2>1. Introduction to Maven/Gradle for Dependency Management</h2>

                <h3>1.1. Overview</h3>
                <p>
                    Maven and Gradle are popular build automation tools used in Java development to manage project dependencies, automate the build process, and handle project structure. They are essential for maintaining consistency across different development environments and for simplifying complex build processes.
                </p>

                <ul>
                    <li><strong>Maven:</strong> A build automation tool primarily focused on dependency management and project management through a <code>pom.xml</code> file.</li>
                    <li><strong>Gradle:</strong> A more flexible and powerful build tool that uses a <code>build.gradle</code> file for defining the build process, offering both declarative and imperative programming capabilities.</li>
                </ul>

                <h3>1.2. Managing Dependencies with Maven</h3>
                <p>Maven uses a <code>pom.xml</code> (Project Object Model) file to define a project's dependencies, plugins, and build configurations.</p>

                <CodeBlock code={mavenPomCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Dependencies:</strong> The dependencies section lists all the libraries your project needs. Maven automatically downloads these from a central repository.</li>
                    <li><strong>Plugins:</strong> The build section defines plugins that control the build process, such as the Maven Compiler Plugin, which sets the Java version for the project.</li>
                </ul>

                <h3>1.3. Managing Dependencies with Gradle</h3>
                <p>Gradle uses a <code>build.gradle</code> file to define dependencies and build scripts.</p>

                <CodeBlock code={gradleBuildCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Plugins:</strong> The plugins block applies the Java plugin, which adds tasks for compiling Java code, running tests, and creating JARs.</li>
                    <li><strong>Dependencies:</strong> The dependencies block is where you declare the libraries your project depends on. Gradle downloads these from repositories like Maven Central.</li>
                    <li><strong>Tasks:</strong> The tasks.withType(JavaCompile) block sets the Java version for the project.</li>
                </ul>

                <h3>1.4. Real-World Application</h3>
                <p>
                    Dependency management tools like Maven and Gradle are indispensable in modern Java development. They are used in almost all Java projects to manage libraries, build and package applications, and ensure that the correct versions of libraries are used across different environments. For example, in a Spring Boot project, Maven or Gradle is used to manage dependencies like Spring Core, Spring Data, and Spring MVC, ensuring that all required libraries are downloaded and configured automatically.
                </p>

                <hr className="section-divider" />

                <h2>2. Packaging Java Applications (JAR, WAR)</h2>

                <h3>2.1. Overview</h3>
                <p>
                    Packaging is the process of bundling your Java application and its dependencies into a single archive that can be distributed and executed. Java applications are typically packaged as JAR (Java ARchive) files or WAR (Web ARchive) files.
                </p>

                <ul>
                    <li><strong>JAR (Java ARchive):</strong> Used for packaging Java applications into a single file that can be executed or used as a library.</li>
                    <li><strong>WAR (Web ARchive):</strong> Used for packaging web applications, including servlets, JSPs, HTML, and other web resources, along with the required libraries.</li>
                </ul>

                <h3>2.2. Creating a JAR File</h3>
                <p>A JAR file is a package that includes compiled Java classes, metadata, and resources like images or text files. The JAR file format enables developers to package Java applications or libraries into a single file.</p>

                <h4>Creating a JAR with Maven:</h4>
                <CodeBlock code={mavenJarPluginCode} />

                <p><strong>Build the Project:</strong> Run <code>mvn clean package</code> in the terminal. This generates a JAR file in the target directory.</p>

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Main Class:</strong> Specifies the entry point of the application.</li>
                    <li><strong>Manifest:</strong> The manifest file includes metadata like the main class to be executed.</li>
                </ul>

                <h4>Creating a JAR with Gradle:</h4>
                <CodeBlock code={gradleJarTaskCode} />

                <p><strong>Build the Project:</strong> Run <code>gradle build</code> in the terminal. The JAR file will be located in the build/libs directory.</p>

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Main-Class:</strong> The manifest attribute specifies the entry point for the application.</li>
                </ul>

                <h3>2.3. Creating a WAR File</h3>
                <p>A WAR file packages a web application, including Java classes, web pages, and configuration files.</p>

                <h4>Creating a WAR with Maven:</h4>
                <CodeBlock code={mavenJarPluginCode} />

                <p><strong>Build the Project:</strong> Run <code>mvn clean package</code>. The WAR file will be generated in the target directory.</p>

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>WAR Plugin:</strong> The maven-war-plugin packages the application into a WAR file, ready to be deployed to a servlet container like Apache Tomcat.</li>
                </ul>

                <h4>Creating a WAR with Gradle:</h4>
                <CodeBlock code={gradleJarTaskCode} />

                <p><strong>Build the Project:</strong> Run <code>gradle build</code>. The WAR file will be located in the build/libs directory.</p>

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>WAR Plugin:</strong> The war plugin tells Gradle to package the project as a WAR file.</li>
                </ul>

                <h3>2.4. Real-World Application</h3>
                <p>
                    Packaging Java applications into JAR or WAR files is crucial for deployment. For instance, a Java desktop application is often packaged as a JAR file, making it easy to distribute and run on any machine with a Java runtime. Similarly, web applications are packaged as WAR files and deployed to a web server, enabling the application to be accessed via a web browser. In enterprise environments, the ability to automate the packaging process using Maven or Gradle is essential for continuous integration and continuous deployment (CI/CD) pipelines.
                </p>

                <hr className="section-divider" />

                <h2>3. Introduction to JavaFX for GUI Development</h2>

                <h3>3.1. Overview</h3>
                <p>
                    JavaFX is a modern framework for building rich graphical user interfaces (GUIs) in Java. It provides a wide range of features for building desktop applications, including 2D and 3D graphics, animation, and media playback. JavaFX is the successor to Swing and offers a more modern approach to GUI development.
                </p>

                <h3>3.2. Setting Up JavaFX</h3>
                <p>To get started with JavaFX, you need to include the JavaFX libraries in your project. This can be done using Maven or Gradle.</p>

                <h4>Setting Up JavaFX with Maven:</h4>
                <CodeBlock code={javaFxMavenCode} />

                <h4>Setting Up JavaFX with Gradle:</h4>
                <CodeBlock code={javaFxGradleCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>JavaFX Controls:</strong> Provides the UI components like buttons, labels, and text fields.</li>
                    <li><strong>JavaFX FXML:</strong> Supports FXML, an XML-based language used to define the user interface.</li>
                </ul>

                <h3>3.3. Building a Simple JavaFX Application</h3>
                <CodeBlock code={javaFxAppCode} />

                <h4>Explanation:</h4>
                <ul>
                    <li><strong>Application Class:</strong> HelloWorld extends Application, the base class for JavaFX applications.</li>
                    <li><strong>start() Method:</strong> The start() method is the entry point for JavaFX applications. It sets up the primary stage and scene.</li>
                    <li><strong>Button and Event Handling:</strong> A button is created and configured to print "Hello World!" to the console when clicked.</li>
                </ul>

                <h3>3.4. Real-World Application</h3>
                <p>
                    JavaFX is widely used for developing modern desktop applications with rich user interfaces. It is suitable for applications that require sophisticated UIs, such as data visualization tools, media players, and business applications. JavaFX's ability to integrate with web technologies (through WebView) and its support for FXML makes it a versatile framework for both simple and complex GUI applications.
                </p>

                <hr className="section-divider" />

                <h2>Conclusion</h2>
                <p>
                    Building Java applications involves various tools and techniques to ensure that the final product is efficient, maintainable, and user-friendly. By mastering Maven or Gradle for dependency management, you can automate and simplify the build process, ensuring that your project has all the necessary dependencies. Packaging Java applications as JAR or WAR files allows for easy distribution and deployment, making your applications ready for use in different environments. Finally, JavaFX provides a powerful framework for developing rich, modern graphical user interfaces, enabling you to build desktop applications that are both functional and visually appealing.
                </p>

                <ul>
                    <li><strong>Maven/Gradle for Dependency Management:</strong> Essential tools for managing project dependencies and automating the build process.</li>
                    <li><strong>Packaging Java Applications:</strong> Key to distributing and deploying Java applications in various environments.</li>
                    <li><strong>JavaFX for GUI Development:</strong> A modern framework for creating sophisticated and responsive user interfaces for desktop applications.</li>
                </ul>

                <p>
                    By incorporating these practices and tools into your development workflow, you can build high-quality Java applications that are easy to maintain, distribute, and enhance, meeting the demands of modern software development.
                </p>
                <NextButton nextPage="/integration-deployment" />
            </div>
        </div>
    );
};

export default BuildingJavaApplications;
