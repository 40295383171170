import React from 'react';

const TaskDescription = ({ description }) => {
  // Split the description by new lines
  const paragraphs = description.split('\n');

  return (
    <div>
      {paragraphs.map((paragraph, index) => (
        // Map over each line and create a paragraph
        <p key={index}>
          {paragraph.trim() ? paragraph : <br />} {/* Handle empty lines */}
        </p>
      ))}
    </div>
  );
};

export default TaskDescription;
