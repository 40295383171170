/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaCalendar, FaUser, FaClipboardCheck, FaCoins, FaFileAlt, FaArrowLeft } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { API_BASE_URL } from '../config';
import TaskDescription from './TaskDescription';

const TaskDetails = ({ userToken, learningStatus }) => {
  const [task, setTask] = useState(null);
  const [error, setError] = useState(null);
  const [hasBid, setHasBid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showLearningModal, setShowLearningModal] = useState(false);
  const { taskId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTaskDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const token = sessionStorage.getItem('userToken');
        const response = await fetch(`${API_BASE_URL}/api/users/task/${taskId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch task details');
        }

        const data = await response.json();
        setTask(data);
        setHasBid(data.hasBid);
      } catch (err) {
        console.error('Error fetching task details:', err);
        setError('Failed to load task details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTaskDetails();
  }, [taskId]);

  const handleBidAttempt = () => {
    if (learningStatus.completedLearning) {
      placeBid();
    } else {
      setShowLearningModal(true);
    }
  };

  const placeBid = async () => {
    if (!task) return;

    try {
      const token = sessionStorage.getItem('userToken'); 
      const response = await fetch(`${API_BASE_URL}/api/bid/${taskId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ amount: task.price })
      });

      const data = await response.json();

      if (response.ok) {
        setHasBid(true);
      } else {
        console.error('Failed to place bid:', data.error);
        alert(data.error || 'Failed to place bid');
      }
    } catch (error) {
      console.error('Error placing bid:', error);
      alert('An error occurred while placing the bid');
    }
  };

  const handleBack = () => {
    navigate('/tasks');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-900">
        <ClipLoader color="#38bdf8" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!task) {
    return <div>Task not found.</div>;
  }

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
      <button 
        onClick={handleBack}
        className="mb-4 flex items-center text-teal-400 hover:text-teal-300"
      >
        <FaArrowLeft className="mr-2" />
        Back to Task List
      </button>
      <h2 className="text-2xl font-bold mb-4 text-teal-400">{task.title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="flex items-center">
          <FaCalendar className="mr-2 text-teal-400" />
          <span>Deadline: {new Date(task.deadline).toLocaleDateString()}</span>
        </div>
        <div className="flex items-center">
          <FaCoins className="mr-2 text-teal-400" />
          <span>Price: Ksh {task.price}</span>
        </div>
        <div className="flex items-center">
          <FaClipboardCheck className="mr-2 text-teal-400" />
          <span>Status: {task.status}</span>
        </div>
      </div>
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2 text-teal-400">Description</h3>
        <TaskDescription description={task.description} />
      </div>
      {task.documents && task.documents.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-teal-400">Documents</h3>
          <ul className="list-disc list-inside pl-4">
            {task.documents.map((doc, index) => {
              // Extract the upload path for each document individually
              const uploadPath = doc.fileUrl?.match(/\/uploads\/.*$/)?.[0] || '/uploads/';
              console.log(doc, doc.fileUrl);
              
              return (
                <li key={index} className="flex items-center mb-2">
                  <FaFileAlt className="mr-2 text-teal-400" />
                  <a
                    href={`${API_BASE_URL}${uploadPath}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-teal-300 hover:underline"
                  >
                    {doc.fileName} ({Math.round(doc.fileSize / 1024)} KB)
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {task.assignedTo && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-teal-400">Assigned To</h3>
          <div className="flex items-center">
            <FaUser className="mr-2 text-teal-400" />
            <p>{task.assignedTo.fullName}</p>
          </div>
        </div>
      )}
      
    </div>
  );
};

export default TaskDetails;