// src/pages/ArraysCollections.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';


const ArraysCollections = () => {
    const singleArrayCode = `
public class ArrayExample {
    public static void main(String[] args) {
        // Declaration and initialization
        int[] numbers = new int[5]; // Array of size 5

        // Assigning values
        numbers[0] = 10;
        numbers[1] = 20;
        numbers[2] = 30;
        numbers[3] = 40;
        numbers[4] = 50;

        // Accessing array elements
        System.out.println("First Element: " + numbers[0]); // Output: 10
    }
}
`;

    const multiArrayCode = `
public class MultiArrayExample {
    public static void main(String[] args) {
        // Declaration and initialization of a 2D array
        int[][] matrix = {
            {1, 2, 3},
            {4, 5, 6},
            {7, 8, 9}
        };

        // Accessing elements
        System.out.println("Element at (0,0): " + matrix[0][0]); // Output: 1
        System.out.println("Element at (1,2): " + matrix[1][2]); // Output: 6
    }
}
`;

    const arrayListCode = `
import java.util.ArrayList;

public class ArrayListExample {
    public static void main(String[] args) {
        // Creating an ArrayList
        ArrayList<String> fruits = new ArrayList<>();

        // Adding elements
        fruits.add("Apple");
        fruits.add("Banana");
        fruits.add("Orange");

        // Accessing elements
        System.out.println("First Fruit: " + fruits.get(0)); // Output: Apple

        // Removing an element
        fruits.remove("Banana");

        // Displaying the list
        System.out.println("Fruits: " + fruits); // Output: Fruits: [Apple, Orange]
    }
}
`;

    const linkedListCode = `
import java.util.LinkedList;

public class LinkedListExample {
    public static void main(String[] args) {
        // Creating a LinkedList
        LinkedList<String> cities = new LinkedList<>();

        // Adding elements
        cities.add("New York");
        cities.add("Los Angeles");
        cities.add("Chicago");

        // Accessing elements
        System.out.println("First City: " + cities.get(0)); // Output: New York

        // Adding an element at the beginning
        cities.addFirst("San Francisco");

        // Removing the last element
        cities.removeLast();

        // Displaying the list
        System.out.println("Cities: " + cities); // Output: Cities: [San Francisco, New York, Los Angeles]
    }
}
`;

    const forEachCode = `
import java.util.ArrayList;

public class ForEachExample {
    public static void main(String[] args) {
        ArrayList<String> fruits = new ArrayList<>();
        fruits.add("Apple");
        fruits.add("Banana");
        fruits.add("Orange");

        // Iterating through the ArrayList using for-each loop
        for (String fruit : fruits) {
            System.out.println(fruit);
        }
    }
}
`;

    const iteratorCode = `
import java.util.ArrayList;
import java.util.Iterator;

public class IteratorExample {
    public static void main(String[] args) {
        ArrayList<String> fruits = new ArrayList<>();
        fruits.add("Apple");
        fruits.add("Banana");
        fruits.add("Orange");

        // Getting the iterator
        Iterator<String> iterator = fruits.iterator();

        // Iterating through the ArrayList
        while (iterator.hasNext()) {
            String fruit = iterator.next();
            if (fruit.equals("Banana")) {
                iterator.remove(); // Removing "Banana"
            }
        }

        // Displaying the list after removal
        System.out.println("Fruits: " + fruits); // Output: Fruits: [Apple, Orange]
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Understanding Arrays and Collections in Java: A Comprehensive Guide</h1>
                <p>
                    Arrays and collections are fundamental data structures in Java that allow you to store, manipulate, and retrieve groups of related objects or primitive data types. Understanding how to use these structures efficiently is crucial for any Java developer. In this guide, we will explore the concepts of single and multidimensional arrays, the use of ArrayList, LinkedList, and other basic collections, and how to iterate through collections using the for-each loop and the Iterator interface.
                </p>

                <hr className="section-divider" />

                <h2>1. Single and Multidimensional Arrays</h2>
                <h3>Single-Dimensional Arrays</h3>
                <p>
                    An array is a fixed-size, ordered collection of elements of the same type. Arrays in Java are zero-indexed, meaning that the index of the first element is 0.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={singleArrayCode} />

                <h3>Multidimensional Arrays</h3>
                <p>
                    Multidimensional arrays in Java are arrays of arrays. The most commonly used form is the two-dimensional array, which can be visualized as a matrix or a table.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={multiArrayCode} />

                <p><strong>Application Example:</strong> Arrays are widely used in applications where a fixed-size collection of elements is needed. For example, in a game, you might use an array to store the high scores of players, or in a scientific application, a two-dimensional array might represent data points in a grid.</p>

                <hr className="section-divider" />

                <h2>2. Java Collections Framework</h2>
                <p>
                    Java's Collections Framework provides a more flexible and dynamic alternative to arrays. Collections can grow or shrink in size and provide various utilities to manipulate the data. Here we focus on two key types of collections: ArrayList and LinkedList.
                </p>

                <h3>ArrayList</h3>
                <p>
                    An ArrayList is a resizable array implementation of the List interface. Unlike arrays, ArrayList can dynamically grow and shrink as elements are added or removed.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={arrayListCode} />

                <h3>LinkedList</h3>
                <p>
                    A LinkedList is a linear data structure where each element is a separate object, known as a node. Each node contains data and a reference to the next node in the sequence. LinkedList allows for efficient insertion and deletion of elements.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={linkedListCode} />

                <p><strong>Application Example:</strong> Collections are essential in real-world applications for handling dynamic data. For instance, in a social media application, an ArrayList could store a user's list of friends, while a LinkedList might be used to maintain a history of messages, allowing for efficient addition and removal of messages.</p>

                <hr className="section-divider" />

                <h2>3. Iterating Through Collections</h2>
                <p>Iterating through a collection is a common task in programming, and Java provides several ways to do this.</p>

                <h3>for-each Loop</h3>
                <p>The for-each loop is a simplified version of the for loop that is specifically used for iterating through arrays and collections.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={forEachCode} />

                <h3>Iterator Interface</h3>
                <p>
                    The Iterator interface provides a more flexible way to traverse collections, especially when you need to remove elements during iteration.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={iteratorCode} />

                <p><strong>Application Example:</strong> Iterating through collections is a fundamental task in applications like data processing. For example, in an e-commerce platform, you might iterate through a list of products to display them to the user, apply discounts, or filter out products that are out of stock.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Arrays and collections are indispensable tools in a Java programmer’s toolkit. While arrays provide a simple and efficient way to handle fixed-size collections of data, Java collections offer greater flexibility and a rich set of features for handling dynamic data structures. Mastering these concepts allows you to store, manipulate, and access data effectively, which is essential for building robust, scalable applications.</p>

                <p>
                    By understanding how to use single and multidimensional arrays, how to work with ArrayList, LinkedList, and other collections, and how to iterate through these data structures, you can write more efficient and maintainable code. These skills are critical for any Java developer, whether working on small projects or large enterprise systems.
                </p>

                <NextButton nextPage="/exception-handling" />
            </div>
        </div>
    );
};

export default ArraysCollections;
