// src/pages/OOPBasics.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';

const OOPBasics = () => {
    const carClassCode = `
public class Car {
    // Attributes
    String make;
    String model;
    int year;

    // Method to display car details
    void displayDetails() {
        System.out.println("Make: " + make);
        System.out.println("Model: " + model);
        System.out.println("Year: " + year);
    }
}
`;

    const mainMethodCode = `
public class Main {
    public static void main(String[] args) {
        // Creating an object of the Car class
        Car car1 = new Car();
        car1.make = "Toyota";
        car1.model = "Corolla";
        car1.year = 2020;

        // Calling the method to display car details
        car1.displayDetails();
    }
}
`;

    const parameterizedConstructorCode = `
public class Car {
    String make;
    String model;
    int year;

    // Parameterized Constructor
    public Car(String make, String model, int year) {
        this.make = make;
        this.model = model;
        this.year = year;
    }

    void displayDetails() {
        System.out.println("Make: " + make);
        System.out.println("Model: " + model);
        System.out.println("Year: " + year);
    }
}

public class Main {
    public static void main(String[] args) {
        // Creating an object using the parameterized constructor
        Car car1 = new Car("Honda", "Civic", 2018);
        car1.displayDetails();
    }
}
`;

    const publicModifierCode = `
public class Car {
    public String make;
    public String model;
    public int year;

    public void displayDetails() {
        System.out.println("Make: " + make);
        System.out.println("Model: " + model);
        System.out.println("Year: " + year);
    }
}
`;

    const privateModifierCode = `
public class Car {
    private String make;
    private String model;
    private int year;

    public Car(String make, String model, int year) {
        this.make = make;
        this.model = model;
        this.year = year;
    }

    // Getter method for make
    public String getMake() {
        return make;
    }

    // Setter method for make
    public void setMake(String make) {
        this.make = make;
    }
}

public class Main {
    public static void main(String[] args) {
        Car car1 = new Car("Ford", "Mustang", 2021);
        // Accessing private variable via public method
        System.out.println("Make: " + car1.getMake());
    }
}
`;

    const encapsulationCode = `
public class Car {
    private String make;
    private String model;
    private int year;

    // Constructor
    public Car(String make, String model, int year) {
        this.make = make;
        this.model = model;
        this.year = year;
    }

    // Getter for make
    public String getMake() {
        return make;
    }

    // Setter for make
    public void setMake(String make) {
        this.make = make;
    }

    // Method to display details
    public void displayDetails() {
        System.out.println("Make: " + make);
        System.out.println("Model: " + model);
        System.out.println("Year: " + year);
    }
}

public class Main {
    public static void main(String[] args) {
        Car car1 = new Car("Tesla", "Model S", 2022);

        // Modifying private attribute using setter method
        car1.setMake("Tesla - Updated");

        car1.displayDetails();
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Introduction to Object-Oriented Programming (OOP) Basics: A Comprehensive Guide</h1>
                <p>
                    Object-Oriented Programming (OOP) is a programming paradigm that uses "objects" to model real-world entities. OOP is fundamental to many modern programming languages, including Java, and it enables developers to create modular, reusable, and maintainable code. This guide will introduce you to the core concepts of OOP: Classes and Objects, Constructors, Access Modifiers, and Encapsulation and Data Hiding. Understanding these basics is crucial for mastering OOP and developing robust software applications.
                </p>

                <h2>1. Classes and Objects</h2>
                <h3>Classes</h3>
                <p>
                    A class in Java is a blueprint for creating objects. It defines the properties (attributes) and behaviors (methods) that the objects created from the class will have. Classes encapsulate data for the object and methods to manipulate that data.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={carClassCode} />

                <h3>Objects</h3>
                <p>
                    An object is an instance of a class. When a class is defined, no memory is allocated until an object of that class is created. Each object has its own copy of the attributes defined in the class and can use the methods provided by the class.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={mainMethodCode} />

                <p><strong>Application Example:</strong> Classes and objects are the foundation of OOP. In a real-world application, you might create a Customer class to represent customers in an e-commerce system, with attributes like name, email, and purchaseHistory, and methods to manage customer interactions.</p>

                <h2>2. Constructors</h2>
                <p>
                    A constructor in Java is a special method used to initialize objects. The constructor is called when an object of a class is created. It usually sets initial values for the object's attributes.
                </p>

                <h3>Parameterized Constructor</h3>
                <p>
                    A parameterized constructor allows you to pass values to the object at the time of creation, providing a way to initialize attributes with specific values.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={parameterizedConstructorCode} />

                <p><strong>Application Example:</strong> Constructors are particularly useful in initializing objects with user-defined values when creating instances. For instance, in a banking application, you might use a constructor to create a new BankAccount object with a specific account number and initial balance.</p>

                <h2>3. Access Modifiers (public, private, protected)</h2>
                <p>
                    Access modifiers in Java determine the visibility and accessibility of classes, methods, and attributes. They are essential for controlling access to the components of a class.
                </p>

                <h3>public</h3>
                <p>The public access modifier allows the member to be accessible from any other class.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={publicModifierCode} />

                <h3>private</h3>
                <p>The private access modifier restricts access to the member within the class itself. It cannot be accessed from outside the class.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={privateModifierCode} />

                <p><strong>Application Example:</strong> Access modifiers are critical in defining the level of security and integrity for the data in your classes. For example, in a healthcare management system, patient data should be private to prevent unauthorized access, while certain methods might be public to allow for interaction with that data.</p>

                <h2>4. Encapsulation and Data Hiding</h2>
                <p>
                    Encapsulation is the process of wrapping the data (attributes) and code (methods) that operate on the data into a single unit, a class. It is a fundamental principle of OOP that ensures data hiding, which restricts direct access to certain components of an object, thereby protecting the data from unauthorized access or modification.
                </p>

                <h3>Encapsulation in Practice</h3>
                <p>Encapsulation is achieved by declaring the attributes of a class as private and providing public getter and setter methods to access and update these attributes.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={encapsulationCode} />

                <p><strong>Application Example:</strong> Encapsulation and data hiding are vital in real-world applications where data integrity and security are paramount. For instance, in an online banking application, the balance of an account should be private, accessible only through methods that enforce the correct business rules (like deposit and withdraw).</p>

                <p><strong>Conclusion:</strong> Object-Oriented Programming (OOP) is a powerful paradigm that allows developers to create complex and scalable software systems. By understanding the basics of Classes and Objects, Constructors, Access Modifiers, and Encapsulation, you can write cleaner, more organized code that is easier to maintain and extend.</p>

                <p>
                    Mastering these concepts will enable you to model real-world problems more effectively in your code, leading to more efficient and robust applications. Whether you're building a simple utility or a complex enterprise system, these OOP principles are foundational to success in software development.
                </p>

                <NextButton nextPage="/arrays-collections" />
            </div>
        </div>
    );
};

export default OOPBasics;
