import React from 'react';
import './ArticleWritingGuide.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function ArticleWritingGuide() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/Blogging/Quiz');
    };

    return (
        <div className="article-guide-container">
            <header className="header">
                <h1>Comprehensive Article Writing Guide</h1>
            </header>

            <section className="content">
                <div className="section-block">
                    <h2>1. Grammar</h2>

                    <h3>Punctuation</h3>
                    <h4>Commas</h4>
                    <ul>
                        <li>Use a comma after introductory clauses or phrases.</li>
                        <li>Place a comma between two independent clauses joined by a coordinating conjunction.</li>
                        <li>Employ the serial comma (Oxford comma) consistently within each piece of content.</li>
                    </ul>

                    <h4>Percentages</h4>
                    <p>Use the % symbol with numerals (e.g., 75%, 10%). Spell out "percent" only at the beginning of a sentence.</p>

                    <h4>Abbreviations/Acronyms</h4>
                    <ul>
                        <li>Spell out an acronym on first use, followed by the acronym in parentheses.</li>
                        <li>Well-known acronyms (e.g., NASA, FBI) can be used without explanation.</li>
                        <li>Use periods in two-letter abbreviations: U.S., U.K., except for widely recognized ones like EU.</li>
                    </ul>

                    <h4>Measurements</h4>
                    <p>Use numerals for all measurements, followed by abbreviated units (e.g., 5 ft, 3 kg). Spell out both the number and unit when beginning a sentence.</p>

                    <h4>Healthcare Terminology</h4>
                    <p>Use "healthcare" as one word in all instances. Similarly, use terms like "aftercare," "childcare," and "skincare" as single words.</p>

                    <h4>Capitalization</h4>
                    <h5>Title Case</h5>
                    <ul>
                        <li>Capitalize the first and last words in titles and subheadings.</li>
                        <li>Capitalize all major words (nouns, verbs, adjectives, adverbs, pronouns).</li>
                        <li>Lowercase articles, conjunctions, and prepositions of three letters or fewer, unless they're the first or last word.</li>
                    </ul>

                    <h5>Brand Names</h5>
                    <p>Respect official brand name capitalization (e.g., iPhone, eBay, LEGO). Capitalize the first letter when a brand name starts a sentence.</p>

                    <h5>Academic Degrees</h5>
                    <ul>
                        <li>Capitalize formal degree names (e.g., Bachelor of Arts, Master of Science).</li>
                        <li>Use lowercase for general references (e.g., bachelor's degree, master's program).</li>
                    </ul>

                    <h5>Titles</h5>
                    <ul>
                        <li>Capitalize formal titles when they precede a name (e.g., President Biden, CEO Jane Smith).</li>
                        <li>Use lowercase when the title follows the name or stands alone (e.g., Joe Biden, president of the United States).</li>
                    </ul>

                    <h4>Numerals</h4>
                    <ul>
                        <li>Spell out numbers zero through nine; use figures for 10 and above.</li>
                        <li>Use figures for all measurements, regardless of value.</li>
                        <li>Never begin a sentence with a numeral; spell it out or rewrite the sentence.</li>
                    </ul>

                    <h4>Possessives</h4>
                    <ul>
                        <li>For singular nouns, add 's (e.g., The dog's bowl).</li>
                        <li>For plural nouns ending in s, add only an apostrophe (e.g., The dogs' bowls).</li>
                        <li>For singular proper nouns ending in s, add 's or just an apostrophe (be consistent).</li>
                    </ul>

                    <h4>Subject-Verb Agreement</h4>
                    <ul>
                        <li>Ensure subjects and verbs agree in number (e.g., The cat is sleeping; The cats are sleeping).</li>
                        <li>Collective nouns are treated as singular (e.g., The team is winning).</li>
                    </ul>

                    <h4>Titles and Headings</h4>
                    <ul>
                        <li>Use title case for main headings and subheadings.</li>
                        <li>Keep titles concise and descriptive.</li>
                        <li>Ensure parallel structure in lists of headings.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>2. Formatting</h2>

                    <h3>HTML Basics</h3>
                    <ul>
                        <li>Use lowercase for HTML tags (e.g., <code>&lt;h1&gt;&lt;/h1&gt;</code> for main title).</li>
                        <li>Use <code>&lt;p&gt;&lt;/p&gt;</code> for paragraphs, <code>&lt;strong&gt;&lt;/strong&gt;</code> for bold text, and <code>&lt;em&gt;&lt;/em&gt;</code> for italicized text.</li>
                    </ul>

                    <h3>Spacing</h3>
                    <ul>
                        <li>Use a single space between sentences.</li>
                        <li>Separate paragraphs with a blank line.</li>
                        <li>Avoid extra spaces around HTML tags.</li>
                    </ul>

                    <h3>Lists</h3>
                    <h4>Bulleted Lists</h4>
                    <p>Use for items without a specific order:</p>
                    <ul>
                        <li>First item</li>
                        <li>Second item</li>
                        <li>Third item</li>
                    </ul>

                    <h4>Numbered Lists</h4>
                    <p>Use for sequential items or steps:</p>
                    <ol>
                        <li>First step</li>
                        <li>Second step</li>
                        <li>Third step</li>
                    </ol>

                    <h3>Links</h3>
                    <p>Format links as follows: <code>&lt;a href="https://www.example.com"&gt;Anchor Text&lt;/a&gt;</code></p>
                </div>

                <div className="section-block">
                    <h2>3. Citations and References</h2>

                    <h3>Footnotes</h3>
                    <p>Use numbered footnotes within brackets: <code>[1]</code></p>

                    <h3>Reference List</h3>
                    <p>Include a numbered list of sources at the end of the content:</p>
                    <ol>
                        <li>Author Name. "Article Title." Website Name, Publication Date, URL.</li>
                    </ol>
                </div>

                <div className="section-block">
                    <h2>4. Writing Style</h2>

                    <h3>Voice</h3>
                    <ul>
                        <li><strong>First person singular</strong> (I, me, my): For personal narratives or single-person businesses.</li>
                        <li><strong>First person plural</strong> (we, us, our): For company or organizational content.</li>
                        <li><strong>Second person</strong> (you, your): For directly addressing the reader.</li>
                        <li><strong>Third person</strong> (he, she, it, they): For objective or formal content.</li>
                    </ul>

                    <h3>Tone</h3>
                    <ul>
                        <li><strong>Authoritative</strong>: For technical or professional content.</li>
                        <li><strong>Educational</strong>: For informative articles or guides.</li>
                        <li><strong>Formal</strong>: For academic or official documents.</li>
                        <li><strong>Conversational</strong>: For blog posts or casual content.</li>
                        <li><strong>Promotional</strong>: For marketing materials.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>5. Content Types</h2>

                    <h3>Landing Pages</h3>
                    <p>Aim for 500-1000 words. Focus on educating and informing rather than hard selling. Include clear calls-to-action (CTAs).</p>

                    <h3>Product/Service Descriptions</h3>
                    <p>Keep descriptions concise (100-150 words for basic products, more for complex items). Highlight key features and benefits. Use a friendly, inviting tone.</p>

                    <h3>Blog Posts</h3>
                    <p>Minimum length of 300 words; aim for 500-1000 words for in-depth posts. Provide valuable, relevant information to the target audience. Include a mix of educational and entertaining content.</p>

                    <h3>Case Studies</h3>
                    <p>Present real-world examples of product/service effectiveness. Include data and measurable results. Structure with a clear problem, solution, and outcome.</p>

                    <h3>Press Releases</h3>
                    <p>Follow a journalistic style (who, what, when, where, why). Include a compelling headline, dateline, and boilerplate company information. Keep to 400-500 words.</p>
                </div>

                <div className="section-block">
                    <h2>6. Best Practices</h2>

                    <h3>Avoiding Filler</h3>
                    <ul>
                        <li>Ensure each sentence adds value.</li>
                        <li>Eliminate redundant information.</li>
                        <li>Use active voice where possible.</li>
                        <li>Vary sentence structure and word choice.</li>
                    </ul>

                    <h3>Plagiarism Prevention</h3>
                    <ul>
                        <li>Always use your own words.</li>
                        <li>Cite sources when using facts, statistics, or quotes.</li>
                        <li>Paraphrase and summarize information from research.</li>
                    </ul>

                    <h3>Proofreading</h3>
                    <ul>
                        <li>Read content aloud to catch awkward phrasing.</li>
                        <li>Check for spelling, grammar, and punctuation errors.</li>
                        <li>Ensure all client requirements are met.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>7. Word Count Guidelines</h2>
                    <ul>
                        <li>Meet the minimum word count specified in the order.</li>
                        <li>Don't exceed the maximum word count by more than 20-25%.</li>
                        <li>Exclude metadata, references, and HTML code from word count unless specified otherwise.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>8. SEO Optimization</h2>

                    <h3>Meta Elements</h3>

                    <h4>Title Tags</h4>
                    <ul>
                        <li>Keep under 60 characters.</li>
                        <li>Include primary keyword.</li>
                        <li>Make it compelling and relevant.</li>
                    </ul>

                    <h4>Meta Descriptions</h4>
                    <ul>
                        <li>Limit to 155-160 characters.</li>
                        <li>Summarize page content.</li>
                        <li>Include a call-to-action.</li>
                    </ul>

                    <h4>Meta Keywords</h4>
                    <ul>
                        <li>Use up to 10 relevant keywords.</li>
                        <li>Include variations and long-tail keywords.</li>
                        <li>Separate with commas.</li>
                    </ul>

                    <h3>Keyword Usage</h3>
                    <ul>
                        <li>Use primary keyword in title, first paragraph, and throughout content.</li>
                        <li>Aim for a natural keyword density (typically 1-2%).</li>
                        <li>Include related keywords and synonyms.</li>
                    </ul>

                    <h3>Content Structure</h3>
                    <ul>
                        <li>Use descriptive headings and subheadings (H2, H3, etc.).</li>
                        <li>Keep paragraphs short (3-5 sentences).</li>
                        <li>Use bulleted or numbered lists for easy scanning.</li>
                    </ul>

                    <h3>Internal and External Linking</h3>
                    <ul>
                        <li>Include relevant internal links to other pages on the client's site.</li>
                        <li>Use external links to authoritative sources to support claims.</li>
                        <li>Create descriptive, natural-sounding anchor text for links.</li>
                    </ul>
                </div>
            </section>
            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default ArticleWritingGuide;
