import React, { useEffect } from 'react';
import './MLAStyleEssay.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function MLAStyleEssay() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = () => {
        navigate('/learning/chicago-style');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="mla-paper-example">
            <header className="header">
                <h1><span className="highlight">The Impact of Social Media on Adolescent Mental Health</span></h1>
                <p className="author"><span className="highlight">John Smith</span></p>
                <p className="affiliation"><span className="highlight">Department of English, University of Learning</span></p>
                <p className="course"><span className="highlight">ENG 201: Research Writing</span></p>
                <p className="instructor"><span className="highlight">Prof. Jane Doe</span></p>
                <p className="date"><span className="highlight">15 March 2024</span></p>
            </header>

            <div className="cover-page-explanation">
                <p>Explanation: The title is centered and capitalized, the author's name, course details, instructor's name, and date are centered and double-spaced below the title.</p>
            </div>

            <section className="body-text">
                <h2><span className="highlight">Introduction</span></h2>
                <p><span className="highlight">Social media has become an integral part of adolescents' lives.</span> However, its impact on mental health has raised concerns among psychologists and educators alike. This essay explores the various ways in which social media influences the mental well-being of adolescents, focusing on issues such as anxiety, depression, and self-esteem.</p>
                <p className="explanation">Explanation: The introduction provides a brief overview of the essay's content and establishes the context for the discussion.</p>

                <h2><span className="highlight">The Role of Social Media in Adolescent Anxiety</span></h2>
                <p><span className="highlight">Adolescents who spend more than three hours per day on social media are more likely to report feelings of anxiety.</span> According to a study by Garcia, Smith, and Taylor, "the constant exposure to idealized images and lifestyles can exacerbate feelings of inadequacy" (34).</p>
                <p className="explanation">Explanation: In-text citations in MLA format include the author's last name and the page number, without a comma between them.</p>

                <h2><span className="highlight">Social Comparison and Self-Esteem</span></h2>
                <p><span className="highlight">One of the most significant impacts of social media on adolescents is social comparison.</span> Jones and Miller argue that "the curated nature of social media content often presents an idealized version of reality, leading to decreased self-esteem among users" (45).</p>
                <p className="explanation">Explanation: Direct quotes are enclosed in quotation marks, with in-text citations immediately following the quote.</p>

                <h2><span className="highlight">Cyberbullying and Its Effects on Mental Health</span></h2>
                <p>Cyberbullying is a growing concern, with significant implications for adolescent mental health. <span className="highlight">Brown and Lee found that "adolescents who experienced cyberbullying were twice as likely to report suicidal ideation compared to those who were not bullied" (98).</span></p>
                <p className="explanation">Explanation: Proper citation of sources is crucial for maintaining academic integrity and avoiding plagiarism.</p>

                <h2><span className="highlight">Conclusion</span></h2>
                <p>In conclusion, <span className="highlight">social media plays a complex role in the mental health of adolescents.</span> While it provides opportunities for connection, it also poses risks, particularly in relation to anxiety, self-esteem, and cyberbullying. Addressing these issues requires a nuanced understanding of the ways in which adolescents engage with social media.</p>
                <p className="explanation">Explanation: The conclusion summarizes the main points of the essay and reinforces the thesis.</p>
            </section>

            <footer className="works-cited">
                <h3><span className="highlight">Works Cited</span></h3>
                <p className="explanation">Explanation: The Works Cited page begins on a new page, with entries in alphabetical order by the author's last name.</p>

                <ul>
                    <li><span className="highlight">Brown, T., & Lee, K. "The Impact of Cyberbullying on Adolescent Mental Health." Journal of Adolescent Health, vol. 45, no. 3, 2020, pp. 90-102.</span></li>
                    <p className="explanation">Explanation: Entries should include the author's name, title of the work in quotation marks, journal title in italics, volume and issue number, year, and page range.</p>

                    <li><span className="highlight">Garcia, M., Smith, L., & Taylor, A. "Social Media Usage and Mental Health in Adolescents." Journal of Child Psychology, vol. 78, no. 2, 2021, pp. 30-40.</span></li>

                    <li><span className="highlight">Jones, A., & Miller, S. "Social Comparison on Social Media Platforms." Psychology Today, vol. 36, no. 4, 2021, pp. 40-50.</span></li>

                    <li><span className="highlight">Smith, J., Doe, J., & Taylor, R. "Social Media Use and Mental Health." Journal of Mental Health, vol. 67, no. 1, 2022, pp. 10-15.</span></li>
                </ul>
            </footer>


            <div className="download-button-container">
                <h3><span className="highlig">Download this pdf to get a more detailed MLA sample</span></h3>
                <div className="download-button-container">
                    <a href="/MLA_Sample_Paper.pdf" download className="downd-button">
                        <img src="/pdf.jpg" alt="Download PDF" width="60" />
                    </a>
                </div>


            </div>
            <div className="navigation-icons">
                <button className="nav-icon left" onClick={handleBack}>
                    <FaChevronLeft />
                </button>
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default MLAStyleEssay;
