import React, { useState, useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './AcademicWritingLearning/components/Sidebar';
import AcademicWriting from './AcademicWritingLearning/pages/AcademicWriting';
import APA7 from './AcademicWritingLearning/pages/APA7';
import APAStyle from './AcademicWritingLearning/pages/APAStyle';
import ChicagoStyle from './AcademicWritingLearning/pages/ChicagoStyle';
import HarvardStyle from './AcademicWritingLearning/pages/HarvardStyle';
import MLAPaper from './AcademicWritingLearning/pages/MLAPaper';
import PlagiarismChecker from './AcademicWritingLearning/pages/PlagiarismChecker';
import ResearchProcess from './AcademicWritingLearning/pages/ResearchProcess';
import GoogleScholar from './AcademicWritingLearning/pages/GoogleScholar';
import ToolsResources from './AcademicWritingLearning/pages/ToolsResources';
import MLAStyleEssay from './AcademicWritingLearning/pages/MLAStyleEssay';
import Quiz from './AcademicWritingLearning/pages/Quiz';
import LockableContent from './LockableComponent';
import { API_BASE_URL } from './components/config';

function LearningModule({ userToken, learningStatus }) {
   // eslint-disable-next-line no-unused-vars
   const [hasCompletedLearning, setHasCompletedLearning] = useState(learningStatus);
   useEffect(() => {
     const fetchLearningStatus = async () => {
       try {
         const response = await fetch(`${API_BASE_URL}/api/training/status`, {
           headers: {
             Authorization: `Bearer ${userToken}`,
           },
         });
         const data = await response.json();
         setHasCompletedLearning(data);
       } catch (error) {
         console.error('Error fetching status:', error);
       }
     };
 
     fetchLearningStatus();
   }, [userToken]);

  const handleUnlock = () => {
    setHasCompletedLearning(prevState => ({...prevState, isPaid: true}));
  };
  
  return (
    <div className="App">
      <Sidebar />
      <div className="content">
        <Routes>
          <Route path="/" element={<AcademicWriting />} />
          <Route 
            path="apa7" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><APA7 /></LockableContent>}
          />
          <Route 
            path="apa-style" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><APAStyle /></LockableContent>} 
          />
          <Route 
            path="chicago-style" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><ChicagoStyle /></LockableContent>} 
          />
          <Route 
            path="harvard-style" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><HarvardStyle /></LockableContent>} 
          />
          <Route 
            path="mla-sample-paper" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><MLAPaper /></LockableContent>} 
          />
          <Route 
            path="plagiarism-checker" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><PlagiarismChecker /></LockableContent>} 
          />
          <Route 
            path="research-process" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><ResearchProcess /></LockableContent>} 
          />
          <Route 
            path="google-scholar" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><GoogleScholar /></LockableContent>} 
          />
          <Route 
            path="tools-resources" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><ToolsResources /></LockableContent>}
          />
          <Route 
            path="MLAStyleEssay" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><MLAStyleEssay /></LockableContent>}
          />
          <Route 
            path="quiz" 
            element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><Quiz userToken={userToken}/></LockableContent>} 
          />
        </Routes>
      </div>
    </div>
  );
}

export default LearningModule;
