import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const AdminDashboard = () => {
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = sessionStorage.getItem('adminToken');
        if (!token) {
          throw new Error('No admin token found');
        }

        const response = await fetch(`${API_BASE_URL}/api/admin/dashboard-stats`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setStats(data);
      } catch (e) {
        console.error('Failed to fetch dashboard stats:', e);
        setError(e.message);
      }
    };

    fetchStats();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!stats) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="lg:w-64 bg-teal-500 text-gray-100 lg:flex flex-col">
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4">Writers Admin</h1>
          <nav>
            <Link to="/admin/dashboard" className="block py-2 hover:bg-gray-700">Dashboard</Link>
            <Link to="/admin/tasks" className="block py-2 hover:bg-gray-700">Manage Tasks</Link>
            <Link to="/admin/writers" className="block py-2 hover:bg-gray-700">My Writers</Link>
            <Link to="/admin/orders" className="block py-2 hover:bg-gray-700">Orders</Link>
            <Link to="/admin/bids" className="block py-2 hover:bg-gray-700">Bids</Link>
            <Link to="/admin/transactions" className="block py-2 hover:bg-gray-700">Transactions</Link>
          </nav>
        </div>
        <div className="mt-auto p-4 lg:hidden">
          <button className="w-full bg-blue-500 text-white py-2 rounded">Invite Writer</button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Bar */}
        <header className="bg-teal-300 shadow-sm z-10">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h2 className="font-semibold text-xl text-gray-600">Dashboard</h2>
          </div>
        </header>

        {/* Dashboard Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-600">
          <div className="container mx-auto px-4 py-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
              <StatCard title="Incomplete Orders" value={stats.taskStats.incompleteOrders || 0} color="blue" />
              <StatCard title="Unpaid Orders" value={stats.taskStats.unpaidOrders || 0} color="red" />
              <StatCard title="Orders in Revision" value={stats.taskStats.ordersInRevision || 0} color="yellow" />
              <StatCard title="Disputed Orders" value={stats.taskStats.disputedOrders || 0} color="red" />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <StatCard title="Total Writers" value={stats.userStats.totalWriters || 0} color="green" />
              <StatCard title="Suspended Writers" value={stats.userStats.suspendedWriters || 0} color="red" />
              <StatCard title="Active Writers" value={stats.userStats.activeWriters || 0} color="purple" />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const StatCard = ({ title, value, color }) => {
  const colorClasses = {
    blue: 'bg-blue-100 border-blue-500 text-blue-800',
    red: 'bg-red-100 border-red-500 text-red-800',
    yellow: 'bg-yellow-100 border-yellow-500 text-yellow-800',
    green: 'bg-green-100 border-green-500 text-green-800',
    purple: 'bg-purple-100 border-purple-500 text-purple-800',
  };

  return (
    <div className={`border-l-4 rounded-lg p-4 ${colorClasses[color]}`}>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h3 className="font-semibold text-center md:text-left">{title}</h3>
        <span className="text-3xl font-bold">{value}</span>
      </div>
    </div>
  );
};

export default AdminDashboard;
