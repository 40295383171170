import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const AdminTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = sessionStorage.getItem('adminToken');
        if (!token) {
          throw new Error('No admin token found');
        }

        const response = await fetch(`${API_BASE_URL}/api/admin/transactions`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTransactions(data);
      } catch (e) {
        console.error('Failed to fetch transactions:', e);
        setError(e.message);
      }
    };

    fetchTransactions();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="lg:w-64 bg-teal-500 text-gray-100 flex flex-col">
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4">Writers Admin</h1>
          <nav>
            <Link to="/admin/dashboard" className="block py-2 hover:bg-gray-700">Dashboard</Link>
            <Link to="/admin/tasks" className="block py-2 hover:bg-gray-700">Manage Tasks</Link>
            <Link to="/admin/writers" className="block py-2 hover:bg-gray-700">My Writers</Link>
            <Link to="/admin/orders" className="block py-2 hover:bg-gray-700">Orders</Link>
            <Link to="/admin/bids" className="block py-2 hover:bg-gray-700">Bids</Link>
            <Link to="/admin/transactions" className="block py-2 hover:bg-gray-700">Transactions</Link>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Bar */}
        <header className="bg-teal-300 shadow-sm z-10">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h2 className="font-semibold text-xl text-gray-600">Transactions</h2>
          </div>
        </header>

        {/* Transactions Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-600">
          <div className="container mx-auto px-4 py-8">
            <div className="bg-white shadow-md rounded-lg overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction ID</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction Type</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {transactions.map(transaction => (
                    <tr key={transaction.id}>
                      <td className="px-4 py-4 text-sm font-medium text-gray-900">{transaction.id}</td>
                      <td className="px-4 py-4 text-sm text-gray-500">{transaction.user}</td>
                      <td className="px-4 py-4 text-sm text-gray-500">Ksh {transaction.amount}</td>
                      <td className="px-4 py-4 text-sm text-gray-500">{new Date(transaction.date).toLocaleDateString()}</td>
                      <td className={`px-4 py-4 text-sm ${getStatusColor(transaction.status)}`}>
                        {transaction.status}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-500'>{ transaction.transactionType }</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return 'text-green-600';
    case 'pending':
      return 'text-yellow-600';
    case 'failed':
      return 'text-red-600';
    case 'refunded':
      return 'text-blue-600';
    case 'timeout':
      return 'text-gray-600';
    default:
      return '';
  }
};

export default AdminTransactions;
