import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBookOpen, FaClock, FaChevronLeft, FaChevronRight, FaFilter } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { API_BASE_URL } from '../config';
import ModalLearning from './ModalLearning';
import LearningPathModal from './LearningPathModal';

const TaskList = ({ userToken, learningStatus }) => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [token] = useState(userToken || sessionStorage.getItem('userToken'));
  const [hasCompletedLearning, setHasCompletedLearning] = useState(learningStatus.completedLearning);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLearningPathModalOpen, setIsLearningPathModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  // State for filters
  const [filters, setFilters] = useState({
    category: '',
    minPrice: '',
    maxPrice: '',
    deadlineWithin: ''
  });

  // Fetch tasks from API
  const fetchTasks = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/api/users/tasks`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setTasks(data);
        setFilteredTasks(data);
      } else {
        console.error('Expected an array but received:', data);
        setError('Unexpected response format.');
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to fetch tasks.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchTasks();
    } else {
      navigate('/login');
    }
  }, [token, fetchTasks, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(window.innerWidth >= 768 ? 12 : 8);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchLearningStatus = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/training/status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setHasCompletedLearning(data.completedLearning);
      } catch (error) {
        console.error('Error fetching status:', error);
      }
    };

    fetchLearningStatus();
  }, [token]);

  // Real-time filtering
  useEffect(() => {
    let result = tasks;

    // Filter out tasks with passed deadlines
    const now = new Date();
    result = result.filter(task => new Date(task.deadline) > now);

    if (filters.category) {
      result = result.filter(task => task.category.toLowerCase().includes(filters.category.toLowerCase()));
    }

    if (filters.minPrice) {
      result = result.filter(task => task.price >= Number(filters.minPrice));
    }

    if (filters.maxPrice) {
      result = result.filter(task => task.price <= Number(filters.maxPrice));
    }

    if (filters.deadlineWithin) {
      const deadline = new Date(now.getTime() + Number(filters.deadlineWithin) * 24 * 60 * 60 * 1000);
      result = result.filter(task => new Date(task.deadline) <= deadline);
    }

    if (filters.status) {
      result = result.filter(task => task.status === filters.status);
    }

    setFilteredTasks(result);
    setCurrentPage(1);
  }, [tasks, filters]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate time remaining
  const calculateTimeRemaining = (deadline) => {
    const now = new Date();
    const endTime = new Date(deadline);
    const timeDiff = endTime - now;

    if (timeDiff <= 0) {
      return 'Deadline passed';
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60)) / (1000 * 60));

    return `${days}d ${hours}h ${minutes}m`;
  };

  // Place a bid on a task
  const placeBid = async (taskId, price) => {
    if (!hasCompletedLearning) {
      setIsModalOpen(true);
      return;
    }
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/bid/${taskId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ amount: price }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        fetchTasks();
      } else {
        console.error('Failed to place bid:', data.error);
        if (data.error === 'You have reached the maximum number of bids allowed in 24 hours') {
          alert('You have reached the maximum number of bids allowed in 24 hours. Please try again later.');
        } else {
          alert(data.error || 'Failed to place bid');
        }
      }
    } catch (error) {
      console.error('Error placing bid:', error);
      alert('An error occurred while placing the bid');
    }
  };

  const TaskCard = ({ task }) => (
    <div className="bg-gray-800 rounded-lg shadow-md p-4 mb-4">
      <h2 className="text-xl font-semibold mb-2 flex items-center text-teal-400">
        <FaBookOpen className="mr-2" />
        {task.title}
      </h2>
      <div className="grid grid-cols-2 gap-2 mb-4">
        <div>
          <p className="text-gray-400">Budget:</p>
          <p className="text-purple-400">Ksh {task.price}</p>
        </div>
        <div>
          <p className="text-gray-400">Needed In:</p>
          <p className="text-teal-400 flex items-center">
            <FaClock className="mr-2" />
            {calculateTimeRemaining(task.deadline)}
          </p>
        </div>
        <div>
          <p className="text-gray-400">Subject:</p>
          <p className="text-teal-400">{task.category}</p>
        </div>
        <div>
          <p className="text-gray-400">Status:</p>
          <p className={`${getStatusColor(task.status)} font-semibold`}>
            {getDisplayStatus(task.status)}
          </p>
        </div>
      </div>
      <div className="flex space-x-2">
        {task.status !== 'in_progress' && task.status !== 'disputed' && (
          <Link 
            to={`/task/${task.id}`}
            className="flex-1 bg-teal-600 text-white py-2 px-4 rounded-full hover:bg-teal-700 transition duration-300 text-center"
          >
            View More
          </Link>
        )}
        {task.status === 'open' && (
          task.hasBid ? (
            <button className="flex-1 bg-gray-600 text-white py-2 px-4 rounded-full cursor-not-allowed" disabled>
              Bid Placed
            </button>
          ) : (
            <button
              onClick={() => {
                if (!hasCompletedLearning) {
                  setIsModalOpen(true);
                } else if (window.confirm(`Are you sure you want to place a bid of Ksh ${task.price} for this task?`)) {
                  placeBid(task.id, task.price);
                }
              }}
              className="flex-1 bg-purple-600 text-white py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300"
            >
              Place Bid
            </button>
          )
        )}
      </div>
    </div>
  );

  // Helper function to get the display status
  const getDisplayStatus = (status) => {
    switch (status) {
      case 'open':
        return 'Open';
      case 'in_progress':
        return 'In Progress';
      case 'disputed':
        return 'In Revision';
      default:
        return status;
    }
  };
  
  // Helper function to get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'open':
        return 'text-green-400';
      case 'in_progress':
        return 'text-yellow-400';
      case 'disputed':
        return 'text-red-400';
      default:
        return 'text-gray-400';
    }
  };

  const PaginationControls = () => (
    <div className="flex justify-center mt-4 space-x-2">
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-3 py-1 bg-gray-700 text-white rounded-md disabled:opacity-50"
      >
        <FaChevronLeft />
      </button>
      <span className="px-3 py-1 bg-gray-700 text-white rounded-md">
        Page {currentPage} of {Math.ceil(filteredTasks.length / itemsPerPage)}
      </span>
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === Math.ceil(filteredTasks.length / itemsPerPage)}
        className="px-3 py-1 bg-gray-700 text-white rounded-md disabled:opacity-50"
      >
        <FaChevronRight />
      </button>
    </div>
  );

  const FilterForm = () => (
    <div className="bg-gray-800 p-4 rounded-lg shadow-md mb-4">
      <h2 className="text-xl font-semibold mb-4 text-teal-400 flex items-center">
        <FaFilter className="mr-2" />
        Filter Tasks
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        <div>
          <label htmlFor="category" className="block text-gray-400 mb-1">Category</label>
          <input
            type="text"
            id="category"
            value={filters.category}
            onChange={(e) => setFilters(prev => ({ ...prev, category: e.target.value }))}
            className="w-full bg-gray-700 text-white rounded-md px-3 py-2"
            placeholder="Enter category"
          />
        </div>
        <div>
          <label htmlFor="minPrice" className="block text-gray-400 mb-1">Min Price (Ksh)</label>
          <input
            type="number"
            id="minPrice"
            value={filters.minPrice}
            onChange={(e) => setFilters(prev => ({ ...prev, minPrice: e.target.value }))}
            className="w-full bg-gray-700 text-white rounded-md px-3 py-2"
            placeholder="Enter min price"
          />
        </div>
        <div>
          <label htmlFor="maxPrice" className="block text-gray-400 mb-1">Max Price (Ksh)</label>
          <input
            type="number"
            id="maxPrice"
            value={filters.maxPrice}
            onChange={(e) => setFilters(prev => ({ ...prev, maxPrice: e.target.value }))}
            className="w-full bg-gray-700 text-white rounded-md px-3 py-2"
            placeholder="Enter max price"
          />
        </div>
        <div>
          <label htmlFor="deadlineWithin" className="block text-gray-400 mb-1">Deadline Within (days)</label>
          <input
            type="number"
            id="deadlineWithin"
            value={filters.deadlineWithin}
            onChange={(e) => setFilters(prev => ({ ...prev, deadlineWithin: e.target.value }))}
            className="w-full bg-gray-700 text-white rounded-md px-3 py-2"
            placeholder="Enter days"
          />
        </div>
        <div>
          <label htmlFor="status" className="block text-gray-400 mb-1">Status</label>
          <select
            id="status"
            value={filters.status}
            onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
            className="w-full bg-gray-700 text-white rounded-md px-3 py-2"
          >
            <option value="">All</option>
            <option value="open">Open</option>
            <option value="in_progress">In Progress</option>
            <option value="completed">Completed</option>
            <option value="disputed">Disputed</option>
          </select>
        </div>
      </div>
      <button
        onClick={() => setFilters({ category: '', minPrice: '', maxPrice: '', deadlineWithin: '', status: '' })}
        className="mt-4 bg-red-600 text-white py-2 px-4 rounded-full hover:bg-red-700 transition duration-300"
      >
        Clear Filters
      </button>
    </div>
  );

  return (
    <div className="bg-gray-900 min-h-screen py-10">
      <div className="max-w-6xl mx-auto px-4">
        <h1 className="text-3xl font-bold mb-8 text-teal-400 text-center">Available Tasks</h1>

        {!hasCompletedLearning && (
          <div className="bg-yellow-800 text-yellow-200 p-4 rounded-lg mb-4">
            <p>Complete Learning to access the full log</p>
          </div>
        )}
        
        <FilterForm />

        {loading ? (
          <div className="flex justify-center items-center min-h-screen">
            <ClipLoader color="#38bdf8" loading={loading} size={50} />
          </div>
        ) : error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : (
          <div>
            {/* Desktop view */}
            <div className="hidden md:block bg-gray-800 rounded-lg shadow-lg overflow-hidden">
              <table className="min-w-full bg-gray-800 text-gray-300">
                <thead>
                  <tr>
                    <th className="py-3 px-4 text-left">Title</th>
                    <th className="py-3 px-4 text-left">Budget</th>
                    <th className="py-3 px-4 text-left">Needed In</th>
                    <th className="py-3 px-4 text-left">Subject</th>
                    <th className="py-3 px-4 text-left">Status</th>
                    <th className="py-3 px-4 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((task) => (
                    <tr key={task.id} className="hover:bg-gray-700">
                      <td className="py-3 px-4">{task.title}</td>
                      <td className="py-3 px-4">Ksh {task.price}</td>
                      <td className="py-3 px-4">{calculateTimeRemaining(task.deadline)}</td>
                      <td className="py-3 px-4">{task.category}</td>
                      <td className={`py-3 px-4 ${getStatusColor(task.status)} font-semibold`}>
                        {getDisplayStatus(task.status)}
                      </td>
                      <td className="py-3 px-4">
                        <div className="flex space-x-2">
                          {task.status !== 'in_progress' && task.status !== 'disputed' && (
                            <Link
                              to={`/task/${task.id}`}
                              className="bg-teal-600 text-white py-1 px-3 rounded-full hover:bg-teal-700 transition duration-300 text-center"
                            >
                              View More
                            </Link>
                          )}
                          {task.status === 'open' && (
                            task.hasBid ? (
                              <button
                                className="bg-gray-600 text-white py-1 px-3 rounded-full cursor-not-allowed"
                                disabled
                              >
                                Bid Placed
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  if (!hasCompletedLearning) {
                                    setIsModalOpen(true);
                                  } else if (window.confirm(`Are you sure you want to place a bid of Ksh ${task.price} for this task?`)) {
                                    placeBid(task.id, task.price);
                                  }
                                }}
                                className="bg-purple-600 text-white py-1 px-3 rounded-full hover:bg-purple-700 transition duration-300"
                              >
                                Place Bid
                              </button>
                            )
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Mobile view */}
            <div className="block md:hidden">
              {currentItems.map((task) => (
                <TaskCard key={task.id} task={task} />
              ))}
            </div>

            {/* Pagination Controls */}
            <PaginationControls />
          </div>
        )}
      </div>

      <ModalLearning
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onOpenLearningPathModal={() => setIsLearningPathModalOpen(true)}
        message="You need to complete test from the learning program of the company to be able to place a bid."
      />
      <LearningPathModal
        show={isLearningPathModalOpen}
        onHide={() => setIsLearningPathModalOpen(false)} 
      />
    </div>
  );
};

export default TaskList;