// src/pages/BasicIO.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';


const BasicIO = () => {
    const fileReadingCode = `
import java.io.BufferedReader;
import java.io.FileReader;
import java.io.IOException;

public class FileReadingExample {
    public static void main(String[] args) {
        String filePath = "example.txt";
        try (BufferedReader br = new BufferedReader(new FileReader(filePath))) {
            String line;
            while ((line = br.readLine()) != null) {
                System.out.println(line);
            }
        } catch (IOException e) {
            System.out.println("An error occurred while reading the file: " + e.getMessage());
        }
    }
}
`;

    const fileWritingCode = `
import java.io.BufferedWriter;
import java.io.FileWriter;
import java.io.IOException;

public class FileWritingExample {
    public static void main(String[] args) {
        String filePath = "output.txt";
        try (BufferedWriter bw = new BufferedWriter(new FileWriter(filePath))) {
            bw.write("This is the first line.");
            bw.newLine(); // Adding a newline
            bw.write("This is the second line.");
        } catch (IOException e) {
            System.out.println("An error occurred while writing to the file: " + e.getMessage());
        }
    }
}
`;

    const userInputCode = `
import java.util.Scanner;

public class UserInputExample {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);

        System.out.print("Enter your name: ");
        String name = scanner.nextLine();

        System.out.print("Enter your age: ");
        int age = scanner.nextInt();

        System.out.println("Hello, " + name + ". You are " + age + " years old.");

        scanner.close(); // Closing the scanner
    }
}
`;

    const inputExceptionHandlingCode = `
import java.util.InputMismatchException;
import java.util.Scanner;

public class UserInputWithExceptionHandling {
    public static void main(String[] args) {
        Scanner scanner = new Scanner(System.in);
        try {
            System.out.print("Enter your age: ");
            int age = scanner.nextInt();
            System.out.println("You entered: " + age);
        } catch (InputMismatchException e) {
            System.out.println("Invalid input. Please enter a valid integer.");
        } finally {
            scanner.close();
        }
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Basic I/O Operations in Java: A Comprehensive Guide</h1>
                <p>
                    Input and Output (I/O) operations are fundamental in Java programming, allowing programs to interact with the outside world. Whether you're reading from or writing to files, or handling user input through the console, understanding these operations is crucial for building effective and user-friendly applications. This guide will delve into the essential concepts of basic I/O operations, focusing on reading from and writing to files, and handling user input, complete with code examples and real-world applications.
                </p>

                <hr className="section-divider" />

                <h2>1. Reading from and Writing to Files</h2>
                <h3>Reading from Files</h3>
                <p>
                    Reading data from a file is a common task, whether you're processing text files, configuration files, or any other form of stored data. Java provides several ways to read files, with the most common being FileReader and BufferedReader for reading text files.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={fileReadingCode} />

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>FileReader:</strong> Reads the file content as a stream of characters.</li>
                    <li><strong>BufferedReader:</strong> Buffers the characters for efficient reading, particularly when reading lines of text.</li>
                </ul>

                <p><strong>Output:</strong> (Assuming example.txt contains some text)</p>
                <pre>
                    This is the first line of the file.<br />
                    This is the second line.
                </pre>

                <hr className="section-divider" />

                <h3>Writing to Files</h3>
                <p>
                    Writing data to a file is equally important, especially for tasks like logging, saving user data, or generating reports. Java provides FileWriter and BufferedWriter classes to handle file writing operations.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={fileWritingCode} />

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>FileWriter:</strong> Writes the data as a stream of characters to a file.</li>
                    <li><strong>BufferedWriter:</strong> Buffers the output for efficient writing and allows writing strings and adding new lines.</li>
                </ul>

                <p><strong>Output:</strong> (Content written to output.txt)</p>
                <pre>
                    This is the first line.<br />
                    This is the second line.
                </pre>

                <hr className="section-divider" />

                <p><strong>Real-World Application:</strong> File I/O operations are widely used in applications such as text editors, configuration management systems, and data processing tools. For example, a configuration file reader might use these techniques to load settings for an application, while a logging system might write logs to a file for later analysis.</p>

                <hr className="section-divider" />

                <h2>2. Handling User Input</h2>
                <p>Handling user input is a fundamental part of interactive applications. Java provides several ways to handle user input, with the most common being through the Scanner class.</p>

                <h3>Using Scanner for Console Input</h3>
                <p>
                    The Scanner class is a versatile tool for reading different types of user input from the console. It can handle various data types, including strings, integers, and doubles.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={userInputCode} />

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>nextLine():</strong> Reads a line of text entered by the user.</li>
                    <li><strong>nextInt():</strong> Reads an integer value entered by the user.</li>
                </ul>

                <p><strong>Example Interaction:</strong></p>
                <pre>
                    Enter your name: John Doe<br />
                    Enter your age: 30<br />
                    Hello, John Doe. You are 30 years old.
                </pre>

                <hr className="section-divider" />

                <h3>Handling Input Exceptions</h3>
                <p>Handling exceptions related to user input is crucial for building robust applications. For example, if the user inputs a non-integer value when an integer is expected, an InputMismatchException will be thrown.</p>

                <h3>Example Code with Exception Handling:</h3>
                <CodeBlock code={inputExceptionHandlingCode} />

                <p><strong>Explanation:</strong></p>
                <ul>
                    <li><strong>InputMismatchException:</strong> Caught when the input doesn't match the expected data type.</li>
                </ul>

                <p><strong>Example Interaction:</strong></p>
                <pre>
                    Enter your age: twenty<br />
                    Invalid input. Please enter a valid integer.
                </pre>

                <hr className="section-divider" />

                <p><strong>Real-World Application:</strong> User input handling is critical in applications like command-line tools, where users provide inputs to perform certain tasks. For example, in a console-based banking system, users might input their account numbers and PINs, which are then processed by the application.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Basic I/O operations, such as reading from and writing to files and handling user input, are essential skills for any Java programmer. These operations form the backbone of many real-world applications, from simple console programs to complex systems that interact with the file system and respond to user commands.</p>

                <p>Understanding how to efficiently and effectively manage these I/O operations ensures that your applications can interact with external data sources and provide a seamless user experience. Whether you're developing a text editor, a configuration loader, or a command-line tool, mastering these I/O techniques will greatly enhance your ability to build robust and user-friendly Java applications.</p>

                <NextButton nextPage="/java-apis" />
            </div>
        </div>
    );
};

export default BasicIO;
