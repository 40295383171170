import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className={`sidebar-toggle-button ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
        ☰
      </div>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <img src="/fitfreelance-logo.jpeg" alt="Website Logo" className="sidebar-logo" />
        </div>
        <ul>
          <li><Link to="/Blogging">Introduction to Article Writing</Link></li>
          <li><Link to="/Blogging/ArticleWritingGuide">Article Writing Guide</Link></li>
          <li><Link to="/Blogging/Quiz">Quiz</Link></li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
