import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="sidebar-container">
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                {isOpen ? 'Close Menu' : 'Open Menu'}
            </button>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-content">
                    <div className="sidebar-header">
                        <img src="/fitfreelance-logo.jpeg" alt="Website Logo" className="sidebar-logo" />
                    </div>
                    <ul>
                        <li><Link to="/learning" onClick={toggleSidebar}>Mastering Art of Academic Writing</Link></li>
                        <li><Link to="/learning/research-process" onClick={toggleSidebar}>Research Process</Link></li>
                        <li><Link to="/learning/apa-style" onClick={toggleSidebar}>What is APA</Link></li>
                        <li><Link to="/learning/apa7" onClick={toggleSidebar}>APA Paper Example</Link></li>
                        <li><Link to="/learning/mla-sample-paper" onClick={toggleSidebar}>What is MLA</Link></li>
                        <li><Link to="/learning/MLAStyleEssay" onClick={toggleSidebar}>MLA Paper Example</Link></li>
                        <li><Link to="/learning/chicago-style" onClick={toggleSidebar}>Chicago Style</Link></li>
                        <li><Link to="/learning/harvard-style" onClick={toggleSidebar}>Harvard Standard Style</Link></li>
                        <li><Link to="/learning/plagiarism-checker" onClick={toggleSidebar}>Plagiarism Checker</Link></li>
                        <li><Link to="/learning/google-scholar" onClick={toggleSidebar}>Google Scholar</Link></li>
                        <li><Link to="/learning/tools-resources" onClick={toggleSidebar}>Tools and Resources</Link></li>
                        <li><Link to="/learning/Quiz" onClick={toggleSidebar}>Quiz</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;